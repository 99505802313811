@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.maxdiff-list-container {
  padding: 16px 20px 24px 10px;
  width: 100%;
  .ant-table-thead > tr > th {
    // 表头
    background: #fff !important;
    padding: 12px 12px !important;
    font-weight: bold !important;
    border-bottom: 1px solid #e3e2e5 !important;
    color: #302e2f !important;
    padding: 12px 2px !important;
  }
  .ant-table-tbody > tr > td {
    position: relative;
    padding: 16px 2px;
    overflow-wrap: break-word;
  }
  .ant-table-body {
    overflow-y: auto !important;
  }
  .ant-pagination-item:hover {
    border-color: #f57a9f !important;
    background: #f57a9f !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #ff5186;
  }
  .ant-table-pagination.ant-pagination {
    margin: 10px 0 0 !important;
    // width: max-content;
  }
  .ant-table-tbody > tr.ant-table-row > td:last-child span:nth-child(1) {
    color: #fff;
    cursor: pointer;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #f57a9f;
  }
}
