@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.consumer-container {
  // width: 100%;
  // margin-top: 55px;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;

  .consumer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .product-dropdown-container {
      width: 65%;
      display: flex;
      position: unset;
    }
    .select {
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        color: #322d2c;
        font-weight: 500 !important;
      }
      .ant-select-open {
        .ant-select-item {
          // color: red !important;
        }
        .ant-select-selector {
          box-shadow: none !important;
          border-color: transparent !important;
          border-bottom-color: #ff5186 !important;
        }
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-right-width: 1px !important;
        box-shadow: none !important;
        border-color: transparent !important;
        border-bottom-color: #ff5186 !important;
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        box-shadow: none !important;
        border-color: transparent !important;
        border-bottom-color: #d9d9d9 !important;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: fit-content !important;
      min-width: 120px;
      padding: 0 20px 0 11px !important;
    }
  }
  // 本期心愿活动
  .wish-activity-container {
    display: flex;
    margin: 11px 0 16px;
    min-width: 1070px;
    .swiper-card {
      width: 65%;
      // flex: 1;
      height: 288px;
      position: relative;
      .swiper-bg {
        img {
          width: 100%;
          height: 288px;
          object-fit: cover;
          display: inline-block;
        }
      }
      .swiper-content-wrapper {
        position: absolute;
        transform: translateY(-50%);
        top: 55%;
        left: 60px;
        .swiper-title {
          font-size: 31px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 44px;
          color: #ffffff;
        }
        .swiper-content {
          width: 460px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          color: #ffffff;
          margin: 20px 0;
        }
        .swiper-btn {
          width: 460px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    .chart-card {
      min-width: 35%;
      margin-left: 16px;
      background: #fff;
      border-radius: 5px;
      padding: 16px 23px 0px;
      .chart-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 22px;
        color: #302e2f;
      }
      .wish-chart-container {
        width: 100%;
        height: 250px;
        .ring-echart {
          width: 100%;
          height: 360px;
          // margin: 0 0 80px;
        }
      }
    }
  }
  // 问题展示
  .wish-question-row {
    min-width: 1070px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 16px 23px 16px;
    max-height: 263px;
    overflow-y: scroll;
    .wish-question-title-wrapper {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #302e2f;
      margin-bottom: 7px;
    }
    .wish-question-content-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .wish-question-card {
        width: 22%;
        margin-right: 4%;
        display: flex;
        margin-top: 16px;
        .wish-question-icon {
          img {
            width: 13px;
            height: 13px;
            display: inline-block;
            margin-top: -5px;
          }
        }
        // .wish-question {
        //   font-size: 16px;
        //   font-family: PingFang SC;
        //   font-weight: bold;
        //   line-height: 24px;
        //   color: #302e2f;
        //   padding-bottom: 20px;
        //   border-bottom: 1px dashed #e3e2e5;
        //   margin: 0 0 20px 14px;
        // }
        .wish-question-wrapper {
          margin-left: 14px;
          .wish-question {
            width: 100%;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #302e2f;
            line-height: 19px;
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-all;
            margin-bottom: 16px;
          }
          .wish-question-line {
            border-bottom: 1px dashed #e3e2e5;
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      &:after {
        content: '';
        flex: 1;
      }
    }
  }
  // 固定筛选图表
  .filter-chart-container {
    margin-top: 16px;
    background: #fff;
    min-width: 1070px;
    .filter-container-fixed {
      height: 98px;
      // border: 1px solid green;
      background: #fff;
      z-index: 90;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // min-width: fit-content;
    }
    .chart-container {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      flex-wrap: wrap;
      padding: 16px 24px 0;
      .batch-download-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 15px;
        .batch-download-btn {
          font-size: 16px;
          padding: 0 20px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          border: 1px solid #ff5186;
          cursor: pointer;
          min-width: 100px;
        }
      }
      &:after {
        content: '';
        width: 33%;
      }
    }
    .chart-container-vague {
      pointer-events: none;
      filter: blur(3px);
    }
    .rank-content-empty {
      padding-bottom: 20px;
    }
  }
}
@media screen and (min-width: 1440px) {
  .chart-container {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1440px) {
  .chart-container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;
    padding: 30px 30px 0;
    &:after {
      content: '';
      width: 33%;
    }
  }
}
.consumer-dropdown {
  width: 450px !important;
  // .ant-select-dropdown { //不ok
  //   width: 450px !important;
  // }
  .ant-select-item-option-content {
    flex: none !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85% !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #ff5186 !important;
    font-weight: 600 !important;
    // background-color: rgba(255, 81, 134, 0.04) !important;
    background: rgba(255, 81, 134, 0.04) url('../../images/cur_icon_choice.png')
      no-repeat 92% center !important;
    background-size: 12px 8px !important;
  }
}
