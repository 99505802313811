@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.preinstall-card {
  width: 48.5%;
  position: relative;
  .card-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 15px 14px 0;
    .title-left-wrapper {
      .title-left {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #322d2c;
        line-height: 22px;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-right: 10px;
        // word-break: keep-all;
      }
      .question-hover-card {
        min-width: 200px;
        border: 1px solid #e35d86;
        position: absolute;
        top: 0px;
        background: #fff;
        z-index: 90;
        padding: 10px;
        .question-hover-card-arrow {
          width: 10px;
          height: 10px;
          border-top: 1px solid #e35d86;
          border-right: 1px solid #e35d86;
          position: absolute;
          left: 50%;
          bottom: -6px;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          background: #fff;
          z-index: 20;
        }
        .question-hover-card-name {
          font-size: 12px;
        }
        .question-hover-card-img {
          width: 100%;
          height: auto;
          margin: 10px auto 0;
          padding: 0 20px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
      }
    }
    .title-right-pic {
      display: flex;
      width: 22px;
      // height: 22px;
      // object-fit: cover;
      cursor: pointer;
      &:hover {
        content: url("../../images/overall_data/pic_enlarge_active.png");
      }
    }
  }
  .chart-operaion-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 40px 0 20px;
    .chart-change-wrapper {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      .chart-change-sign {
        margin: 0 5px;
        color: #9797a1;
      }
      .chart-change-text {
        color: #595961;
        cursor: pointer;
        &:hover {
          color: #ff5186;
        }
      }
      .chart-change-text-selected {
        color: #ff5186;
      }
    }
  }
  .chart-wrapper {
    height: calc(100% - 135px);
    margin: 0;
    .chart-wrapper-2 {
      width: 100%;
      height: 100%;
      .preinstall-echart {
        width: 100%;
        height: 360px;
        margin: 0 0 80px;
      }
      .heatmap-select {
        margin: 5px 18px 12px;
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          width: 100px;
          height: 30px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 5px;
        }
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 20px !important;
          line-height: 30px !important;
          font-size: 14px !important;
        }
        .select
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector
          .ant-select-selection-search-input {
          height: 30px !important;
          line-height: 30px !important;
        }
      }
      .heatmap-image-container {
        width: 100%;
        // height: 100%;
        img {
          width: 100%;
          // height: 100%;
          display: block;
        }
        margin: 0 0 80px;
      }
    }
    .wordcloud-image-wrapper {
      width: fit-content;
      // width: 360px;
      height: 360px;
      margin: 0 auto;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .wordcloud-image-empty {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }
  .ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1440px) {
  .preinstall-card {
    width: 33%;
  }
}
