@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-template-container {
  padding: 30px 40px;
  box-sizing: border-box;
  .question-template-search {
    ::placeholder {
      color: #9797a1;
      font-size: 13px;
      line-height: 13px;
    }
    .ant-btn {
      transition: none;
      font-size: 13px;
    }
    .ant-input-affix-wrapper {
      border-radius: 6px;
      width: 100%;
      height: 38px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 13px;
      padding: 6px 16px;
      align-items: center;
      border: 1px solid #e3e2e5;
      &:hover {
        border-color: #ff5186;
      }
      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }
    .ant-input-prefix {
      //搜索
      margin-right: 10px;
    }
    .ant-input {
      color: #595961;
      font-size: 13px;
      line-height: 13px;
      border-radius: 0;
    }
    .ant-input-search-button {
      // 查询
      width: 70px;
      span {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 13px;
        color: #ffffff;
      }
    }

    .ant-input-search
      .ant-input-group
      .ant-input-affix-wrapper:not(:last-child) {
      border-radius: unset;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }
    .ant-input-group {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 14px;
    }
    .ant-input-group-addon {
      position: relative;
      //   padding: 0 11px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      background-color: #fafafa;
      //   border: 1px solid #d9d9d9;
      border-radius: 6px;
      transition: none;
    }
    .ant-input-search-button {
      height: 38px;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0 6px 6px 0;
    }
    .ant-btn-primary {
      color: #fff;
      border-color: #ff5186;
      background: #ff5186;
      text-shadow: none;
      box-shadow: none;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
      border-color: #ff729c;
      background: #ff729c;
    }
  }
  .question-template-tree-select {
    margin: 23px 0;
    position: relative;
    cursor: pointer;
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      color: #322d2c;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      color: #9b9ba4 !important;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #ff5186 !important;
      border-right-width: 1px !important;
      box-shadow: none;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }
    .ant-select-open {
      .ant-select-selector {
        border-color: #ff5186 !important;
        box-shadow: none !important;
      }
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      font-size: 13px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      font-size: 13px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 38px;
      padding: 0 11px 0 16px;
      border-radius: 6px;
      border: 1px solid #e3e2e5;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-search {
      position: absolute;
      top: 0;
      right: 11px;
      bottom: 0;
      left: 15px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      padding: 0;
      line-height: 36px;
      transition: all 0.3s;
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector::after {
      line-height: 36px;
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 36px;
    }
  }
}
.tree-select-popup {
  //操作树的选中样式
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: rgba(255, 81, 134, 0.04) !important;
    color: #ff5186 !important;
  }
  .ant-select-tree
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: rgba(255, 81, 134, 0.1) !important;
    color: #ff5186 !important;
  }
}
