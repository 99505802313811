@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.ai-copywritng-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .ai-copywritng-left-container {
    // border: 1px solid red;
    width: 40%;
    min-width: 423px;
    padding: 40px 30px 50px;
    height: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
    opacity: 1;
    border-radius: 5px;
    .ai-copywritng-scene-wrapper {
      width: 100%;
      background: rgba(247, 248, 250, 1);
      border-radius: 5px;
      padding: 27px 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ai-copywritng-scene-left {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          display: inline-block;
          margin-right: 13px;
        }
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
        color: #000000;
      }
      .ai-copywritng-scene-right {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 16px;
        color: #595961;
        opacity: 1;
        cursor: pointer;
      }
    }
    .ai-copywritng-condition-wrapper {
      margin: 46px 0 86px;
      .ai-copywritng-condition-title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 17px;
        color: #000000;
      }
      .ai-copywritng-condition-content {
        margin: 15px 0 10px;
        .ai-copywritng-condition-input-wrapper {
          margin: 0 0 18px;
          display: flex;
          align-items: center;
          .word-input {
            margin-right: 8px;
            border-radius: 6px;
            border: 1px solid #e3e2e5;
            width: 125px;
            height: 34px;
            font-size: 13px;
            font-weight: 400;
            // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: none;
            box-shadow: none;
            &:hover {
              border-color: #ff5186;
              box-shadow: none;
            }
            .ant-btn:hover,
            .ant-btn:focus {
              border-color: #ff5186;
              box-shadow: none;
            }
          }
          div {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 17px;
            &:nth-child(3) {
              font-weight: 400;
              color: #808386;
            }
          }
        }
        .edit-question-title-copywriting {
          border: 1px solid rgba(247, 248, 250, 1);
          border-radius: 5px;
          width: 100%;
          padding: 22px 25px;
          min-height: 109px;
          line-height: 18px;
          max-width: 100%;
          background: rgba(247, 248, 250, 1);
        }
        p {
          /* 去除点击p标签后的原生黄色边框 */
          outline: none;
        }
        p:empty::before {
          content: attr(placeholder);
          color: #999999;
          font-size: 13px;
          font-family: PingFang SC;
        }
      }
      .ai-copywritng-condition-content-not-allowed {
        cursor: not-allowed;
        .ai-copywritng-condition-input-wrapper {
          pointer-events: none;
        }
        .edit-question-title-copywriting {
          pointer-events: none;
        }
      }
    }
    .ai-copywritng-generation-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      .generate-btn {
        background: #f55186;
        border-color: #ff5186;
        width: 163px;
        height: 42px;
        color: #fff;
        font-size: 13px;
        line-height: 17px;
        // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: none;
        border-radius: 5px;
        &:hover {
          border-color: none;
          border-color: #ff729c;
          background: #ff729c;
        }
        .ant-btn:hover,
        .ant-btn:focus {
          color: #fff;
          border-color: #ff729c;
          background: #ff729c;
        }
      }
    }
  }
  .ai-copywritng-right-container {
    // border: 1px solid blue;
    padding: 20px 0;
    flex: 1;
    margin-left: 24px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
    opacity: 1;
    border-radius: 5px;
    .ai-copywritng-right-operaion {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 32px 20px;
      .ai-copywritng-right-operaion-list {
        display: flex;
        align-items: center;
        .list-search {
          ::placeholder {
            color: #9797a1;
            font-size: 13px;
            line-height: 13px;
          }
          .ant-btn {
            transition: none;
            font-size: 13px;
          }
          .ant-input-affix-wrapper {
            border-radius: 6px;
            width: 234px;
            height: 34px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 13px;
            padding: 6px 16px;
            align-items: center;
            border: 1px solid #e3e2e5;
            &:hover {
              border-color: #ff5186;
            }
            &:focus {
              border-color: #ff5186;
              box-shadow: none;
            }
          }
          .ant-input-prefix {
            //搜索
            margin-right: 10px;
          }
          .ant-input {
            color: #595961;
            font-size: 13px;
            line-height: 13px;
            border-radius: 0;
          }
          .ant-input-search-button {
            // 查询
            width: 70px;
            span {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 13px;
              color: #ffffff;
            }
          }

          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            border-radius: unset;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          .ant-input-affix-wrapper-focused {
            border-color: #ff5186;
            box-shadow: none;
          }
          .ant-input-group {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 14px;
          }
          .ant-input-group-addon {
            position: relative;
            //   padding: 0 11px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            background-color: #fafafa;
            //   border: 1px solid #d9d9d9;
            border-radius: 6px;
            transition: none;
          }
          .ant-input-search-button {
            height: 34px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 0 6px 6px 0;
          }
          .ant-btn-primary {
            color: #fff;
            border-color: #ff5186;
            background: #ff5186;
            text-shadow: none;
            box-shadow: none;
          }
          .ant-btn-primary:hover,
          .ant-btn-primary:focus {
            color: #fff;
            border-color: #ff729c;
            background: #ff729c;
          }
        }
        .list-delete {
          background: #fff1f6;
          border-radius: 6px;
          border: 1px solid #ff5186;
          width: 70px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #ff5186;
          font-size: 13px;
          margin: 0 16px 0 13px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: #fff8fb;
            border: 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec;
            border: 1px solid #ff5186;
          }
          img {
            height: 13px;
            display: inline-block;
            margin: 0 9px 0 0;
          }
        }
      }
      .ai-copywritng-right-operaion-view {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 13px;
          height: 13px;
          display: inline-block;
          margin-right: 7px;
        }
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 16px;
        color: #808386;
        &:hover {
          color: #f55186;
          img {
            content: url("../../../images/emotion_analysis/ai_copywriting/icon_history_active.png");
          }
        }
      }
    }
    // loading
    .ai-copywritng-right-loading {
      width: 120px;
      height: fit-content;
      margin: 0 auto;
      display: block;
      transform: translateY(-70%);
      top: 50%;
      position: relative;
    }
    // 全选
    .ai-copywritng-right-checkbox {
      display: flex;
      align-items: center;
      margin: 0px 32px 23px;
      .ai-copywritng-right-checkbox-img {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ai-copywritng-right-checkbox-name {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
        margin: 0 0 0 7px;
        width: 45px;
      }
    }
    // 文案
    .ai-copywritng-right-content {
      padding: 0 0 0 32px;
      .copywriting-card {
        display: flex;
        align-items: center;
        & + .copywriting-card {
          margin: 26px 0 0;
        }
        .copywriting-checkbox {
          display: flex;
          width: 16px;
          min-width: 16px;
          height: 16px;
          margin: 0 30px 0 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .copywriting-text-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          .copywriting-text {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: #000000;
            border: 1px solid #e3e2e5;
            border-radius: 5px;
            padding: 12px 23px;
            flex: 1;
          }
          .copywriting-text-active {
            border: 1px solid #ff5186;
          }
          .copywriting-copy {
            width: 13px;
            height: fit-content;
            margin: 0 10px 0 9px;
            display: flex;
            visibility: hidden;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
            &:hover {
              img {
                content: url("../../../images/emotion_analysis/ai_copywriting/icon_copy_active.png");
              }
            }
          }
          &:hover {
            .copywriting-copy {
              visibility: visible;
            }
          }
        }
      }
    }
    // 分页
    .ai-copywritng-right-pagination {
      margin: 32px 32px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .table-sum-wrapper {
        line-height: 32px;
        color: #9797a1;
        span {
          color: #ff5186;
        }
      }
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        color: #ff5186;
        border-color: #ff5186;
      }
      .ant-pagination-disabled .ant-pagination-item-link,
      .ant-pagination-disabled:hover .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        cursor: not-allowed;
      }
      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: #ff5186 !important;
      }
    }
  }
}
