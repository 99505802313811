@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.xm-editor-container {
  border: 1px solid #ccc;
  z-index: 100;
  .xm-editor-toolbar {
    border-bottom: 1px solid #ccc;
  }
  .xm-editor {
    height: 500px;
    overflow-y: hidden;
  }
}
