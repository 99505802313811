@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.like-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .like-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 22px;
    color: #302e2f;
  }
  .like-title-right-wrapper {
    display: flex;
    .like-top-select {
      margin: 0 0 0 8px;
      position: relative;
      cursor: pointer;
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 200px;
        border-radius: 6px;
        padding: 0 8px;
        border: 1px solid #e3e2e5;
        height: 30px !important;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        left: 8px;
      }
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item,
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 20px !important;
        line-height: 30px !important;
        font-size: 14px !important;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 30px !important;
        line-height: 30px !important;
      }
    }
  }
}
.like-content-wrapper {
  border: 1px dashed #e3e2e5;
  width: 100%;
  margin: 20px 0 0;
  .like-location-wrapper {
    width: 20%;
    min-width: 190px;
    min-height: 68px;
    display: flex;
    align-items: flex-end;
    border-left: 1px dashed #e3e2e5;
    &:first-child {
      border-left: none;
    }
    .like-location {
      width: 100%;
      // height: 260px;
      max-height: 260px;
      overflow-y: hidden;
      &:hover {
        overflow-y: scroll;
      }
      .norm-item {
        display: flex;
        align-items: center;
        padding: 13px 2px 14px 13px;
        .norm-sort {
          display: flex;
          align-items: center;
          min-width: 22px;
          justify-content: center;
          position: relative;
          img {
            height: 22px;
            display: inline-block;
          }
          .norm-sort-num {
            width: fit-content;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 12px;
          }
        }
        .norm-img {
          min-width: 42px;
          height: 42px;
          margin: 0 8px 0 14px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .norm-title-wrapper {
          width: calc(100% - 104px);
          .norm-name {
            font-size: 13px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #302e2f;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .norm-num-wrapper {
            margin-top: 5px;
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 11px;
            color: #9797a1;
            .norm-num {
              color: #f55186;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .like-up-container {
    display: flex;
  }
  .like-norm-container {
    display: flex;
    .like-norm-wrapper {
      width: 20%;
      min-width: 190px;
      height: 34px;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: bold;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .like-norm-sign {
        // width: 30px;
        width: 0;
        height: 0;
        border-left: 17px solid rgba(0, 175, 80, 1);
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
      }
      .like-norm-text {
        text-align: center;
        flex: 1;
      }
      &:nth-child(1) {
        justify-content: center;
        background: rgba(0, 175, 80, 1);
      }
      &:nth-child(2) {
        background: rgba(62, 205, 171, 1);
      }
      &:nth-child(3) {
        .like-norm-sign {
          border-left: 17px solid rgba(62, 205, 171, 1);
        }
        background: rgba(251, 193, 90, 1);
      }
      &:nth-child(4) {
        .like-norm-sign {
          border-left: 17px solid rgba(251, 193, 90, 1);
        }
        background: rgba(7, 114, 246, 1);
      }
      &:nth-child(5) {
        .like-norm-sign {
          border-left: 17px solid rgba(7, 114, 246, 1);
        }
        background: rgba(245, 81, 134, 1);
      }
    }
  }
  .like-down-container {
    display: flex;
    .like-location-wrapper {
      display: flex;
      align-items: flex-start;
      &:nth-child(1) {
        background: rgba(0, 175, 80, 0.05);
      }
      &:nth-child(2) {
        background: rgba(62, 205, 171, 0.05);
      }
      &:nth-child(3) {
        background: rgba(251, 193, 90, 0.05);
      }
      &:nth-child(4) {
        background: rgba(7, 114, 246, 0.05);
      }

      &:nth-child(5) {
        background: rgba(245, 81, 134, 0.05);
      }
    }
  }
}
