@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.picture-template-info {
  margin-top: 10px;
  min-width: 240px;
  .pic-info-tip {
    margin: 0 0 15px 0;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 17px;
    color: #302e2f;
  }
  .pic-info-wrapper {
    display: flex;
    .pic-info-left {
      border-radius: 7px;
      border: 1px dashed rgba(167, 167, 167, 0.9);
      width: 110px;
      height: 110px;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      display: flex;
      cursor: pointer;
      .show-upload-wrap {
        margin: 0 auto;
        .upload-pic-wrapper {
          border-radius: 7px;
          width: 100%;
          cursor: pointer;
          .upload-pic {
            width: 40px;
            height: 100%;
            display: block;
            margin: 20px auto 12px;
          }
          .upload-pic-text {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #6f7070;
          }
        }
      }

      .show-pic {
        border-radius: 7px;
        width: 108px;
        height: 108px;
      }
    }
    .pic-info-right {
      margin: 0 0 0 40px;
      .pic-info-btn {
        cursor: pointer;
        border-radius: 2px;
        max-width: 350px;
        min-width: 77px;
        min-height: 25px;
        font-weight: bold;
        font-family: PingFang SC;
        font-size: 13px;
        padding: 0px 14px 0;
        .edit-question-title {
          padding: 0;
          margin: 0px auto 0;
        }
      }
      .pic-info-btn-1 {
        background: linear-gradient(90deg, #fc8bb0 0%, #f55186 100%);
        border: 1px solid #fc8bb0;
        color: #ffffff;
        margin: 10px 0 40px 0;
      }
      .pic-info-btn-2 {
        background: rgba(255, 255, 255, 0.39);
        border: 1px solid #acacac;
        color: #302e2f;
      }
    }
  }
}
