@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.contrast_main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;

  .contrast_main_title {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
    color: #333;
    padding: 0 15px;
  }

  .contrast_main_content {
    width: 100%;
    height: calc(100% - 60px);
    padding: 35px 0;
    padding: 15px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ff5186 !important;
    border-right-width: 1px !important;
  }
}

.main_select_box {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  overflow: hidden;

  .select_s {
    position: relative;
    height: 60px;

    .select_item {
      min-width: 380px;
      height: 100%;
      padding-right: 7px;
      background-color: #fff;
      float: left;
      display: flex;
      align-items: center;

      .icon_box {
        width: 70px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }

      .select1 {
        width: calc(100% - 100px);
        margin: 0 5px;

        .ant-select {
          width: 100%;
        }
      }

      .select2 {
        width: calc(33% - 20px);
        margin: 0 5px;

        .ant-select {
          width: 100%;
        }
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 6px;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #ff5186 !important;
        box-shadow: 0 0 0 2px rgba(255, 81, 134, .2) !important;
      }
    }
  }
}

.main_container {
  width: 100%;
  height: calc(100% - 75px);
  overflow: scroll;
}

.main_table_box {
  width: 100%;
  padding: 0 15px 15px;
  position: relative;

  .theme-add-wrapper {
    width: 119px;
    font-size: 13px;
    padding: 11px 11px;
    height: 34px;
    line-height: 17px;
    text-align: center;
    background: #fff1f6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff5186;
    border: 1px solid #ff5186;
    border-radius: 6px;
    font-family: PingFang SC;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      background: #fff8fb;
      border: 1px solid rgba(255, 81, 134, 0.6);
    }

    &:active {
      background: #ffe1ec;
      border: 1px solid #ff5186;
    }

    img {
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }

  .table_box {
    .table_btn_box {
      display: flex;

      .search-wrapper {
        padding: 7px;
        margin-right: 5px;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .ant-table-tbody>tr>td {
      padding: 3px 15px;
    }

    .ant-table-pagination.ant-pagination {
      margin: 5px 0 0 !important;
    }

  }
}

.main_survey_box {
  .survey_item {
    min-width: 380px;
    min-height: 10px;
    float: left;
    background-color: #fff;
    padding-right: 7px;
    box-sizing: border-box;

    .item {
      padding: 0 30px 0 15px;

      .item-title {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        color: #000000;
        margin: 15px 0;
      }
    }
  }
}