@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.gpt-product-dropdown-container {
  width: 65%;
  display: flex;
  .select {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      color: #322d2c;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
    .ant-select-open {
      .ant-select-item {
        // color: red !important;
      }
      .ant-select-selector {
        box-shadow: none !important;
        border-color: transparent !important;
        border-bottom-color: #ff5186 !important;
      }
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 1px !important;
      box-shadow: none !important;
      border-color: transparent !important;
      border-bottom-color: #ff5186 !important;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none !important;
      border-color: transparent !important;
      border-bottom-color: #d9d9d9 !important;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content !important;
    min-width: 120px;
    padding: 0 20px 0 11px !important;
  }
}
// 设置下拉框
.product-dropdown {
  width: 450px !important;
  // .ant-select-dropdown { //不ok
  //   width: 450px !important;
  // }
  .ant-select-item-option-content {
    flex: none !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85% !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #ff5186 !important;
    font-weight: 600 !important;
    // background-color: rgba(255, 81, 134, 0.04) !important;
    background: rgba(255, 81, 134, 0.04)
      url("../../../images/cur_icon_choice.png") no-repeat 92% center !important;
    background-size: 12px 8px !important;
  }
  // .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  // .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  //   padding-right: 20px !important;
  //   line-height: 38px;
  //   font-size: 16px !important;
  //   // color: #322d2c !important;
  //   img {
  //     width: 30px !important;
  //     height: 30px !important;
  //   }
  // }
}
