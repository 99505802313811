@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-nps-info-container {
  margin: 0 0 0 21px;
  .matrix-nps-info-top {
    // border: 1px solid blue;

    .info-top-name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #322d2c;
      font-size: 14px;
      margin: 20px 0 0 150px; //150+15
      width: 345px; //400-15
      //   border: 1px solid blue;
      .info-top-name {
        max-width: 160px;
      }
    }
    .info-top-value-wrap {
      margin: 15px 0 0 160px; //150+15
      width: 320px; //400-15
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #595961;
      font-size: 14px;
      .draw-slip-index {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        width: 100%;
        color: #9797a1;
        font-size: 14px;
      }
    }
  }
  .draw-slip {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    &:hover {
      // border: 1px dashed #e3e2e5;
      .draw-slip-left-border {
        border: 1px dashed #e3e2e5;
      }
      .draw-slip-right {
        display: block;
      }
    }
    .draw-slip-left {
      align-items: center;
      display: flex;
      .draw-slip-title {
        align-items: center;
        display: flex;
        width: 150px;
        .edit-question-title-wrap {
          margin: 0 15px 0 0;
        }
      }
      .draw-slip-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .draw-slip-line {
          width: 320px;
          height: 10px;
          background: #f8f8f8;
          border-radius: 30px;
          position: relative;
          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #ff5186;
            border-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .draw-slip-num {
          font-size: 14px;
          color: #595961;
          margin-left: 24px;
        }
      }
    }
    .draw-slip-left-border {
      border: 1px solid transparent;
      padding: 4px 10px;
    }
    .draw-slip-right {
      display: none;
      margin: 0 0 0 10px;
      .delete-option-icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
      .option-active-wrapper {
        cursor: pointer;
        width: 30px;
        height: 12px;
        display: inline-block;
        margin-left: 6px;
        font-size: 12px;
        color: #595961;
        &:hover {
          color: #ff5186;
        }
      }
    }
  }
  .draw-slip-2 {
    .draw-slip-title {
      color: gainsboro;
    }
  }
  .draw-slip-setting {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;

    // border: 1px dashed #e3e2e5;
    .draw-slip-left-border {
      border: 1px dashed #e3e2e5;
    }
    .draw-slip-right {
      display: block;
    }
  }
  //比重foot
  .info-foot {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5186;
    line-height: 24px;
    margin: 5px 0 0 12px;
    width: fit-content;
    .add-option-icon {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 0;
    }
  }
}
