@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.ant-table-thead > tr > th {
  // 表头
  background: #fff !important;
  padding: 12px 12px !important;
  font-weight: bold !important;
  border-bottom: 1px solid #e3e2e5 !important;
  color: #302e2f !important;
  &:first-child {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  padding: 12px 16px !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  // 表头的间隔
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: #fff !important;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  // position: relative;
  // overflow-wrap: break-word;
}
.ant-table-tbody > tr > td {
  //   border-bottom: 1px dashed #e3e2e5 !important;
  //   padding: 20px 16px !important;
}
.ant-table-tbody {
  tr.ant-table-row-selected > td {
    // background: rgba(245, 81, 134) !important;
    // border-color: rgba(0, 0, 0, 0.03);
  }
  td.ant-table-cell {
    // 内容文字
    border-bottom: 1px dashed #e3e2e5;
    color: #595961;
    font-size: 14px;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff5186 !important;
  border-color: #ff5186 !important;
}
//   .ant-checkbox-inner {
//     background-color: #f8f8f8;
//   }
.ant-checkbox-checked::after {
  border: 1px solid #ff5186 !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50% !important;
  left: 50% !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #ff5186 !important;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff5186 !important;
}
.ant-table-tbody > tr.ant-table-row > td {
  //修改详情
  &:last-child {
    span {
      & + span {
        margin: 0 0 0 14px;
      }
    }
    span:nth-child(1) {
      color: #ff5186;
      cursor: pointer;
      a {
        color: #ff5186;
        &:hover {
          color: #ff729c;
        }
        &:active {
          color: #ef356d;
        }
      }
      &:hover {
        color: #ff729c;
      }
      &:active {
        color: #ef356d;
      }
    }
    span:nth-child(2) {
      a {
        color: #0772f6;
        cursor: pointer;
        &:hover {
          color: #368df7;
        }
        &:active {
          color: #0a66d6;
        }
      }
      color: #0772f6;
      cursor: pointer;
      &:hover {
        color: #368df7;
      }
      &:active {
        color: #0a66d6;
      }
    }
    span:nth-child(3) {
      a {
        color: #ffb700;
        cursor: pointer;
        &:hover {
          color: rgba(255, 183, 0, 0.8);
        }
        &:active {
          color: #ffb700;
        }
      }
      color: #ffb700;
      cursor: pointer;
      &:hover {
        color: rgba(255, 183, 0, 0.8);
      }
      &:active {
        color: #ffb700;
      }
    }
    span:nth-child(4) {
      a {
        color: rgba(0, 147, 114, 1);
        cursor: pointer;
        &:hover {
          color: rgba(0, 147, 114, 0.8);
        }
        &:active {
          color: rgba(0, 147, 114, 1);
        }
      }
      color: rgba(0, 147, 114, 1);
      cursor: pointer;
      &:hover {
        color: rgba(0, 147, 114, 0.8);
      }
      &:active {
        color: rgba(0, 147, 114, 1);
      }
    }
  }
}
// 分页
.ant-table-pagination.ant-pagination {
  margin: 50px 0 0 !important;
}

/* 滚动条的宽度 */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);
}
/* 滚动条的滑块轨迹 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background: transparent;
}
