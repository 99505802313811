@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.waterfall-container {
  //   border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .box {
    // width: -webkit-calc((100% - 20px * 4)/ 4);
    // width: calc((100% - 20px * 4) / 4);
    // border: 1px solid green;
    width: 25%;
    height: fit-content;
    & + .box {
      margin-left: 20px;
    }
    .card {
      border: 1px solid #e3e2e5;
      border-radius: 10px;
      margin-bottom: 20px;
      .card-top-wrapper {
        display: flex;
        justify-content: space-between;
        .card-top-left {
          width: 39px;
          height: 35px;
          background: rgba(48, 46, 47, 0.1);
          // background: rgba(48, 46, 47, 0.25);
          // background: rgba(235, 235, 235, 0.39);
          border-top-left-radius: 8px;
          border-bottom-right-radius: 20px;
          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-top: 10px;
            margin-left: 10px;
            border-radius: 2px;
            cursor: pointer;
          }
        }
        .card-top-left-disabled {
          img {
            cursor: not-allowed;
          }
        }
        .card-top-left-1 {
          background: rgba(245, 81, 134, 1);
        }
        .card-top-left-2 {
          background: rgba(48, 46, 47, 0.1);
        }
        .card-top-left-3 {
          background: rgba(245, 81, 134, 0.39);
        }
        .card-top-left-4 {
          background: rgba(235, 235, 235, 0.39);
        }
        .card-top-right {
          width: 52px;
          height: 23px;
          background: rgba(252, 139, 176, 0.15);
          color: #f55186;
          text-align: center;
          margin: 12px 13px 0 0;
          border-radius: 12px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 23px;
          cursor: pointer;
          &:hover {
            background: #f55186;
            color: #ffffff;
          }
        }
      }
      .card-title-wrap {
        display: flex;
        padding: 4px 6px 0px 16px;
        .card-title-left {
          position: relative;

          img {
            position: relative;
            transform: translateY(-50%);
            margin: 0 13px 0 0;
            top: 50%;
            width: 55px;
            height: 55px;
            border-radius: 50%;
          }
        }
        .card-title-right {
          .card-username {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #322d2c;
            line-height: 20px;
            margin: 5px 0 8px;
          }
          .card-intro {
            display: flex;
            flex-wrap: wrap;
            .card-intro-item {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9797a1;
              line-height: 18px;
              position: relative;
              // margin: 0 10px 0 0;
              & + .card-intro-item {
                margin: 0 0 0 20px;
                &::before {
                  content: '';
                  position: absolute;
                  left: -10px;
                  width: 1px;
                  height: 18px;
                  top: 0;
                  background: #eeeeee;
                }
              }
            }
          }
        }
      }
      .card-translation-wrap {
        margin: 16px 16px 0;
        display: flex;
        align-items: center;
        height: 34px;
        .translation-select {
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            width: 130px;
            border-radius: 5px;
            padding: 0 16px;
            border: 1px solid #d9d9d9;
            height: 34px;
          }
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-item,
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            padding-right: 20px;
            line-height: 34px;
            font-size: 14px;
          }
          .select
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input {
            height: 34px;
            line-height: 34px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-search {
            position: absolute;
            top: 0;
            right: 11px;
            bottom: 0;
            left: 16px;
          }
        }
      }
      .card-content-wrap {
        margin: 16px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595961;
        line-height: 18px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        min-width: 128px;
        word-break: break-word;
      }
      .card-bottom-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 16px 20px;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #f95a90;
        line-height: 17px;
        .card-bottom-left {
          display: flex;
          .card-time-wrap {
            color: #9797a1;
          }
          .card-detail-wrap {
            cursor: pointer;
            // justify-content: flex-end;
            margin-left: 8px;
            color: #f95a90;
          }
        }
        .card-bottom-right {
          color: #9797a1;
        }
      }
    }
  }
  &:after {
    content: '';
    flex: auto;
  }
}
@media screen and (min-width: 1440px) {
  .waterfall-container {
    .box {
      // width: -webkit-calc((100% - 32px * 4)/ 4);
      // width: calc((100% - 32px * 4) / 4);
    }
  }
}
