@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.word-cloud-container {
  margin: 130px 0 0;
  padding: 0 60px 0;
  width: 100%;
  // height: -webkit-fill-available;
  .word-cloud-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #322d2c;
  }
  .wordcloud-echart {
    margin: 0 auto;
    // height: -webkit-fill-available;
  }
}
