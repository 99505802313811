@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.logistics-container-bg {
  overflow-x: scroll;
  .logistics-info-container {
    padding: 16px 23px 20px;
    min-width: 1100px;
    background: #fff;
    border-radius: 5px;
    .logistics-info-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #000000;
      margin-bottom: 13px;
    }
    .step-container {
      display: flex;
      margin-left: 120px;
      align-items: center;
      .step-card-wrapper {
        .step-card {
          .step-card-number {
            border-radius: 50%;
            width: 23px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            color: #302e2f;
            background: #efefef;
            font-size: 11px;
            margin: 0 auto 5px;
          }
          .step-card-text {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 17px;
            color: #302e2f;
          }
        }
        .step-card-active {
          .step-card-number {
            background: #ff5186;
            color: #fff;
          }
          .step-card-text {
            color: #f55186;
          }
        }
      }
      .step-line {
        margin: 0 36px;
        width: 110px;
        height: 9px;
        background: #f8f8f8;
        border-radius: 6px;
      }
    }
    .logistics-info {
      padding: 40px 120px 0px;
      box-sizing: border-box;
      min-width: 900px;
      .logistics-info-top {
        width: 730px;
        background: #edf5ff;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        margin: 0 0 40px;
        img {
          width: 20px;
          height: 20px;
        }
        .msg {
          margin-left: 12px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #302e2f;
        }
      }
      .logistics-info-item {
        display: flex;
        justify-content: left;
        padding-bottom: 24px;
        // align-items: baseline;
        .logistics-info-left {
          width: 173px;
          height: 39px;
          line-height: 39px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #595961;
          span {
            color: #ff5186;
            margin-right: 5px;
            display: inline-block;
            width: 15px;
          }
        }
        .logistics-info-right {
          position: relative;
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            width: 480px;
            border-radius: 6px;
            padding: 0 16px;
            border: 1px solid #e3e2e5;
          }
          .logistics-info-input {
            width: 480px;
            height: 39px !important;
            /*width: 100%;*/
            box-sizing: border-box;
            padding: 0 0 0 16px;
            font-size: 14px !important;
          }
          .logistics-info-input-disabled {
            cursor: not-allowed;
          }
          .logistics-info-img {
            width: 16px;
            height: 16px;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
      .operaion-row {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        div:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #fff1f6;
          border-radius: 4px;
          border: 1px solid #ff5186;
          width: 94px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #ff5186;
          font-size: 13px;
          margin: 0 23px 0 0;
          &:hover {
            background: #fff8fb;
            border: 1px solid 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec;
            border: 1px solid #ff5186;
          }
        }
        div:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #fff1f6;
          border-radius: 4px;
          border: 1px solid #ff5186;
          width: 94px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #ff5186;
          font-size: 13px;
          margin: 0 23px 0 0;
          &:hover {
            background: #fff8fb;
            border: 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec;
            border: 1px solid #ff5186;
          }
        }
        div:nth-child(3) {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #f8f8f8;
          border-radius: 4px;
          border: 1px solid #e3e2e5;
          width: 94px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #9797a1;
          font-size: 13px;
          &:hover {
            background: #fff8fb;
            color: #ff5186;
            border: 1px solid #ff5186;
          }
          &:active {
            background: #ffe1ec;
            color: #ff5186;
            border: 1px solid #ff5186;
          }
        }
      }
      .operaion-row-2 {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #f8f8f8;
          border-radius: 4px;
          border: 1px solid #e3e2e5;
          width: 94px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #9797a1;
          font-size: 13px;
          &:hover {
            background: #fff8fb;
            color: #ff5186;
            border: 1px solid #ff5186;
          }
          &:active {
            background: #ffe1ec;
            color: #ff5186;
            border: 1px solid #ff5186;
          }
        }
        .next-active {
          background: #ff5186 !important;
          color: #fff !important;
          &:hover {
            background: #ff729c !important;
            color: #fff;
          }
          &:active {
            background: #ef356d !important;
            color: #fff;
          }
        }
      }
    }
  }
  .logistics-list-container {
    margin-top: 23px;
    padding: 16px 23px 20px;
    min-width: 1100px;
    background: #fff;
    border-radius: 5px;
    .logistics-list-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #000000;
      margin-bottom: 13px;
    }
    //操作按钮
    .logistics-operaion {
      display: flex;
      align-items: center;
      margin: 20px 0 8px;
      .dispatch-operaion-right {
        display: flex;
        margin: 0 0 0 16px;
        .dispatch-operaion-button {
          background: #fff1f6;
          border-radius: 6px;
          border: 1px solid #ff5186;
          width: 94px;
          height: 34px;
          font-weight: 400;
          font-family: PingFang SC;
          color: #ff5186;
          font-size: 13px;
          margin: 0 16px 0 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: #fff8fb;
            border: 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec;
            border: 1px solid #ff5186;
          }
          img {
            height: 13px;
            display: inline-block;
            margin: 0 9px 0 0;
          }
        }
      }
    }
    .logistics-list-table {
    }
  }
}
