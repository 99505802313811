@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.sankey{
  width: 100%;
  height: 100%;
}
.sankey_dialog.dialog_flag .dialog_main {
  width: 1000px;
}

.dialog_flag.question_dialog .dialog_main {
  width: 600px;
}
.dialog_flag.question_dialog .dialog_main .dialog_main_content {
  height: 500px;
  overflow-y: auto;
}
.question_dialog .dialog_main .dialog_main_content .question {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}
.question_dialog .dialog_main .dialog_main_content .answer {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}