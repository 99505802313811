@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.proportion-template-info {
  margin-top: 10px;
  min-width: 240px;
  .proportion-info-top {
    margin: 14px 0 0 12px;
  }

  // 比重nps
  .detail-nps-answer {
    .draw-slip-title {
      align-items: center;
      display: flex;
      .edit-question-title-wrap {
        margin: 0 15px 0 0;
      }
      .upload-question-pic {
        display: flex;
        img {
          width: 24px;
          height: 21px;
          display: inline-block;
        }
      }
    }
    .draw-slip {
      margin: 12px 0 0 0;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      .draw-slip-left {
        .draw-slip-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .draw-slip-line {
            width: 350px;
            height: 10px;
            background: #f8f8f8;
            border-radius: 30px;
            position: relative;
            span {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #ff5186;
              border-radius: 30px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .draw-slip-num {
            font-size: 14px;
            color: #595961;
            margin-left: 24px;
          }
        }
        .draw-slip-index {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          width: 350px;
          color: #9797a1;
          font-size: 14px;
        }
        .draw-slip-foot {
          color: #595961;
          display: flex;
          width: 350px;
          justify-content: space-between;
          font-size: 14px;
          margin: 10px 0 0;
        }
      }
      .draw-slip-left-border {
        border: 1px solid transparent;
        padding: 4px 10px;
      }
      .draw-slip-right {
        display: none;
        margin: 0 0 0 16px;
        .delete-option-icon {
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
        .option-active-wrapper {
          cursor: pointer;
          width: 30px;
          height: 12px;
          display: inline-block;
          margin-left: 6px;
          font-size: 12px;
          color: #595961;
          &:hover {
            color: #ff5186;
          }
        }
      }
    }
  }
}
