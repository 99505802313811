@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.brand-survey-container {
  // width: 100%;
  // margin-top: 55px;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;
  overflow-x: auto;
  /* 进度条区 */
  .progress-box {
    padding: 20px 20%;
    width: 100%;
    min-width: 1200px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .theme-row {
    background: #fff;
    padding: 23px;
    min-width: 1200px;
    // width: fit-content;
    .theme-top-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .theme-title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        margin-right: 16px;
        min-width: 64px;
      }
      .theme-select {
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          width: 314px;
          height: 30px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 5px;
        }
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 20px !important;
          line-height: 30px !important;
          font-size: 14px !important;
        }
        .select
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector
          .ant-select-selection-search-input {
          height: 30px !important;
          line-height: 30px !important;
        }
      }
      .theme-add-wrapper {
        min-width: 119px;
        font-size: 13px;
        padding: 11px 11px;
        height: 34px;
        line-height: 17px;
        text-align: center;
        background: #fff1f6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff5186;
        border: 1px solid #ff5186;
        border-radius: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-left: 63px;
        cursor: pointer;
        &:hover {
          background: #fff8fb;
          border: 1px solid rgba(255, 81, 134, 0.6);
        }
        &:active {
          background: #ffe1ec;
          border: 1px solid #ff5186;
        }
        img {
          margin-right: 12px;
          width: 16px;
          height: 16px;
        }
      }
    }
    .theme-card-row {
      display: flex;
      .card {
        flex: 1;
        border: 1px solid #e3e2e5;
        border-radius: 4px;
        padding: 16px 16px 10px;
        height: 137px;
        overflow-y: scroll;
        & + .card {
          margin-left: 20px;
        }
        .card-title-wrapper {
          display: flex;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 22px;
          color: #000000;
          margin-bottom: 14px;
          div:nth-child(1) {
            min-width: 100px;
          }
        }
        .card-content-wrapper {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 25px;
          color: #595961;
          .date-wrapper {
            display: flex;
          }
        }
      }
    }
  }
  //题目数量+ 年龄地域参与
  .other-tip-tow {
    display: flex;
    margin: 16px 0;
    min-width: 1200px;
    .tip-card {
      width: 33.2%;
      background: #fff;
      border-radius: 5px;
      padding: 0 23px 4px;
      & + .tip-card {
        margin-left: 16px;
      }
      .tip-card-list {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        padding: 23px 0 24px;
        width: 100%;
        border-bottom: 1px dashed #e3e2e5;
        &:first-child {
          padding: 23px 0 24px;
        }
        div {
          margin: 9px 0 0 16px;
        }
        img {
          width: 63px;
          height: 63px;
        }
        span {
          &:nth-child(1) {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #9797a1;
            line-height: 16px;
            margin: 0 0 8px 0;
            display: block;
          }
          &:nth-child(2) {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #302e2f;
            line-height: 22px;
            display: block;
          }
        }
        & + .list {
          margin-top: 20px;
        }
      }
    }
    .join-age-card {
      padding: 0;
      .card-title-wrapper {
        padding: 23px 23px 0px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 22px;
        color: #302e2f;
      }
      .dispatch-single-echart {
        padding: 0 30px 0;
        width: 100%;
        height: 300px;
      }
    }
    .join-region-card {
      padding: 0;
      .card-title-wrapper {
        padding: 20px 30px 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
        color: #302e2f;
      }
      .dispatch-single-echart {
        padding: 0 30px 0;
        width: 100%;
        height: 340px;
      }
    }
    .panel-card-dispatch-single {
      margin: 0;
    }
  }
  // 固定筛选图表
  .filter-chart-container {
    margin-top: 16px;
    background: #fff;
    min-width: 1200px;
    .filter-container-fixed {
      height: 98px;
      background: #fff;
      z-index: 90;
      padding: 30px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //   min-width: fit-content;
      .download-wrapper {
        min-width: 40px;
        font-size: 16px;
        padding: 11px 5px;
        height: 44px;
        line-height: 22px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-left: 8px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .chart-container {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      flex-wrap: wrap;
      padding: 0px 24px 0;
      .batch-download-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 15px;
        .batch-download-btn {
          font-size: 16px;
          padding: 0 20px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          border: 1px solid #ff5186;
          cursor: pointer;
          min-width: 100px;
        }
      }
      &:after {
        content: '';
        width: 33%;
      }
      .rank-content-empty {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .chart-container {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1440px) {
  .chart-container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;
    padding: 30px 30px 0;
    &:after {
      content: '';
      width: 33%;
    }
  }
}
