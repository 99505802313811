@function vw($args) {
  @return $args/1440 * 100 + vw;
}

// .card-title-wrapper {
//   padding: 20px 24px 0;
// }
.date-echart {
  width: 100%;
  // height: 400px;
  // padding: 0 0 10px;
}
.range-picker {
  border: 1px solid #e3e2e5;
  border-radius: 5px;
  width: 400px;
  height: 38px;
}
.ant-picker:hover {
  border-color: #e3e2e5 !important;
}
.ant-picker-focused {
  border-color: #ff5186 !important;
  box-shadow: none !important;
  border: 1px solid #ff5186;
}
