@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.chart-list-container {
  margin: 20px 10px 24px;
  width: 100%;

  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
}