@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-ai-list {
  padding: 20px 0 0;
  .question-ai-operaion {
    align-items: center;
    margin: 0 20px 30px;
    .question-ai-search {
      flex: 1;
      ::placeholder {
        color: #9797a1;
        font-size: 13px;
        line-height: 13px;
      }
      .ant-btn {
        transition: none;
        font-size: 13px;
      }
      .ant-input-affix-wrapper {
        border-radius: 6px;
        width: 100%;
        height: 38px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 13px;
        padding: 6px 16px;
        align-items: center;
        border: 1px solid #e3e2e5;
        &:hover {
          border-color: #ff5186;
        }
        &:focus {
          border-color: #ff5186;
          box-shadow: none;
        }
      }
      .ant-input-prefix {
        //搜索
        margin-right: 10px;
      }
      .ant-input {
        color: #595961;
        font-size: 13px;
        line-height: 13px;
        border-radius: 0;
      }
      .ant-input-search-button {
        // 查询
        width: 70px;
        span {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 13px;
          color: #ffffff;
        }
      }

      .ant-input-search
        .ant-input-group
        .ant-input-affix-wrapper:not(:last-child) {
        border-radius: unset;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      .ant-input-affix-wrapper-focused {
        border-color: #ff5186;
        box-shadow: none;
      }
      .ant-input-group {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 14px;
      }
      .ant-input-group-addon {
        position: relative;
        //   padding: 0 11px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        background-color: #fafafa;
        //   border: 1px solid #d9d9d9;
        border-radius: 6px;
        transition: none;
      }
      .ant-input-search-button {
        height: 38px;
      }
      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0 6px 6px 0;
      }
      .ant-btn-primary {
        color: #fff;
        border-color: #ff5186;
        background: #ff5186;
        text-shadow: none;
        box-shadow: none;
      }
      .ant-btn-primary:hover,
      .ant-btn-primary:focus {
        color: #fff;
        border-color: #ff729c;
        background: #ff729c;
      }
    }
    .question-ai-refresh {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 10px;
      font-size: 13px;
      img {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 4px;
      }
      &:hover {
        color: #ff5186;
        img {
          content: url('../../../../../images/project_entry/icon_refresh_active.png');
        }
      }
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-header {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    div {
      display: contents;
    }
  }
  .ai-collapse-panel-card {
    padding: 20px 0 10px;
    .prompt-title-wrapper {
      padding: 0px 20px 10px;
      span {
        &:first-child {
          font-weight: bold;
        }
      }
    }
    .ai-template-panel {
      padding: 0 40px 20px;
    }
  }
  .ai-collapse-empty {
    margin-top: 80px;
  }
}
