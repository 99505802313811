@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.open-blank-container {
  margin: 0px 20px 24px 10px;
  // height: 360px;
  // margin: 0 0 80px;
  .search-wrapper {
    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #e3e2e5;
      &:hover {
        border-color: #ff5186;
      }
      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }
  }
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  font-size: 14px !important;
}
// 表情切换
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff5186;
}
.ant-tabs-tab:hover {
  color: #ff729c;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  &:hover {
    color: #ff729c;
  }
}
.ant-tabs-ink-bar {
  background: #ff5186;
}
