@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.word-cloud-panel {
  // border: 1px solid red;
  margin: 10px 0 0;
  .word-cloud-operaion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .word-cloud-level {
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
      line-height: 16px;
      &-name {
        margin: 0 80px 0 0;
      }
      &-option {
        display: flex;
      }
      .level-option-box {
        display: flex;
        cursor: pointer;
        .level-option-box-image {
          display: flex;
          img {
            display: inline-block;
            width: 16px;
            height: 16px;
          }
        }
        .level-option-box-title {
          margin: 0 0 0 8px;
        }
        & + .level-option-box {
          margin: 0 0 0 80px;
        }
      }
    }
    .download-wrapper {
      min-width: 95px;
      font-size: 16px;
      padding: 11px 14px;
      height: 44px;
      line-height: 22px;
      text-align: center;
      background: #ff5186;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 400;
      margin-left: 20px;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .word-cloud-contain {
    margin: 10px 0 0;
    padding: 0 60px 0;
    width: 100%;
    .word-cloud-img {
      width: 40%;
      margin: 0 auto;
      display: block;
    }
    .word-cloud-img-vague {
      filter: blur(9px);
    }
  }
  .empty-contain {
    margin-top: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595961;
    line-height: 16px;
  }
}
