@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.table-list-container {
  margin: 20px 10px 24px;
  width: 100%;

  .ant-table-body {
    overflow-y: auto !important;
  }

  .appendix {
    margin-top: 20px;
    width: 90%;
    margin-left: 4%;
    font-size: 14px;
    color: #838181;
  }
}