@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.panel-card-emotion-single {
  background-color: #fff;
  border-radius: 16px;
  margin: 0;
  width: 33%;
  padding: 10px 10px 0;
  box-sizing: border-box;
  .emotion-card-title-wrapper {
    // background-color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px;
    .line {
      display: inline-block;
      height: 16px;
      width: 4px;
      background: #ff5186;
      border-radius: 2px;
      margin: 1px 0 0 0;
    }
    .text {
      font-size: 16px;
      font-weight: bold;
      margin-left: 8px;
      color: #322d2c;
      line-height: 26px;
    }
  }
}
.emotion-single-echart {
  width: 100%;
  height: 350px;
}
@media screen and (max-width: 1440px) {
  .panel-card-emotion-single {
    width: 48.5%;
    &:last-child {
      width: 100%;
    }
  }
}
