@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.logic_contain {
  /*background: red;*/
  border-radius: 7px;
  height: 100%;
  .operaion-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 4px;
      border: 1px solid #e3e2e5;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 13px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
.logic_top {
  padding: 20px 24px;
  background: #fff;
  border-radius: 7px 7px 0 0;
  box-sizing: border-box;
  align-items: center;
  display: flex;
}
.logic_top img {
  width: 8px;
  height: 14px;
  cursor: pointer;
}
.logic_top span {
  font-size: 18px;
  font-weight: bold;
  margin-left: 14px;
}
.logic_contain_main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100% - 80px);
}
.logic_left {
  flex: 1;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  .logic-left-margin-left {
    margin-left: 24px;
    border-top: 1px solid #e8e8eb;
    padding-bottom: 20px;
    .logic-item-2 {
      pointer-events: none;
      .logic-item-top {
        color: gainsboro;
      }
    }
    .logic-item {
      margin: 20px 0 0;
      border: 1px solid #e3e2e5;
      border-radius: 7px;
      width: calc(100% - 24px);
      padding: 20px 24px;
      box-sizing: border-box;
      pointer-events: unset;
      //top 题目名称
      .logic-item-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 16px;
          //逻辑设置页面题目取消加粗
          //font-weight: bold;
        }
        .is-question-active {
          font-size: 12px;
        }
        .logic-item-top-icon {
          img {
            width: 16px;
            height: 16px;
            cursor: pointer;
            &:nth-child(1) {
              margin: 0 24px 0 0;
            }
          }
        }
      }
      .logic-item-top-num {
        margin-right: 8px;
      }
      .logic_item_main {
        display: flex;
        justify-content: flex-start;
        margin-top: 16px;
        //如果左边
        .logic_item_main_left {
          margin: 0 16px 0 0;
          span:nth-child(1) {
            font-size: 14px;
            color: #595961;
            width: 30px;
            display: block;
          }
          .logic_item_line {
            border-left: 1px solid #e8e8eb;
            display: flex;
            align-items: center;
            margin-left: 14px;
            .cur_icon_and_pink {
              width: 18px;
              height: 18px;
              margin-left: -8px;
              cursor: pointer;
            }
          }
        }
        //如果右边
        .logic_item_main_right {
          .logic_item_rule {
            padding-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .logic_rule_select {
              // width: 162px;
              position: relative;
              cursor: pointer;
              margin-right: 6px;
              // 设置下拉框placeholder
              .ant-select-selection-placeholder {
                color: #9797a1 !important;
              }
              .logic_rule_right {
                width: 100%;
                height: 38px !important;
                box-sizing: border-box;
                padding: 0 0 0 14px;
                font-size: 14px;
              }
              .cur_icon_select {
                width: 10px;
                height: 6px;
                position: absolute;
                top: 14px;
                right: 14px;
              }
            }
            // 如果input框
            .condition-input {
              background: #ffffff;
              border-radius: 3px;
              border: 1px solid #d9d9d9;
              height: 38px;
              width: 100px;
              padding: 2px 10px;
              font-size: 14px;
              &:focus {
                border: 1px solid #ff5186;
              }
              &:hover {
                border: 1px solid #ff5186;
              }
            }
            // 如果 字
            .blank-word {
              font-size: 14px;
              color: #595961;
            }
            .condition_select_first {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 162px;
              }
            }
            // 如果第二框
            .condition_select_second {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 100px;
              }
              .condition-option {
                .ant-select-single:not(.ant-select-customize-input)
                  .ant-select-selector {
                  width: 130px;
                }
              }
            }
            // 如果第三框
            .condition_select_third {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 162px;
              }
              .matrix-select {
                .ant-select-single:not(.ant-select-customize-input)
                  .ant-select-selector {
                  width: 100px !important;
                }
              }
            }

            .condition_select_fouth {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 100px;
              }
            }
            // 结果第一框
            .result_select_first {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 100px;
              }
            }
            .result_select_second {
              .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 162px;
              }
              .result-input {
                background: #ffffff;
                border-radius: 3px;
                border: 1px solid #d9d9d9;
                height: 38px;
                width: 162px;
                padding: 2px 10px;
                font-size: 14px;
                &:focus {
                  border: 1px solid #ff5186;
                }
                &:hover {
                  border: 1px solid #ff5186;
                }
              }
            }
            .cur_icon_add,
            .cur_icon_delete {
              width: 12px;
              height: 12px;
              cursor: pointer;
            }
            .cur_icon_delete {
              margin-right: 16px !important;
            }
            .logic_rule_text {
              font-size: 14px;
              margin-right: 16px;
            }
          }
        }
      }
      .dashed_line {
        border-bottom: 1px dashed #e8e8eb;
      }
    }
  }
}

.logic_right {
  background: #f6f6f6;
  width: 300px;
  padding: 20px 24px;
  box-sizing: border-box;
  .logic-right-tip {
    margin-top: 20px;
    padding: 20px;
  }
}
.full_screen img {
  width: 30px;
  height: 30px;
}
.logic_right_main {
  margin-top: 72px;
}
.logic_browse_item {
  text-align: center;
}
.logic_browse_item .logic_browse_sub {
  border: 1px solid #e3e2e5;
  border-radius: 4px;
  width: 270px;
  height: 38px;
  background: #fff;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
}
.logic_browse_item .logic_browse_line {
  text-align: center;
}
.logic_browse_item .logic_browse_line span {
  display: inline-block;
  width: 1px;
  height: 40px;
  border-left: 1px dashed #aaaaaa;
}
.logic_browse_icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.logic_browse_item .logic_browse_icon img {
  width: 10px;
  height: 10px;
  margin-top: -28px;
}
