html {
  /* background: url('./images/background.png') no-repeat center fixed;
  background-size: cover; */
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  --audit-color: #009372;
  /* Deep Teal for 审核 (Audit/Review) */
  --modify-color: #0772f6;
  /* Bright Blue for 修改 (Modify/Revise) */
  --detail-color: #ffb700;
  /* Vivid Yellow for 详情 (Details/Information) */
  --import-color: #ff5186;
  /* Shade of Pink for 导入 (Import/Introduce) */
  --remove-color: #c2185b;
  /* Darker Magenta for 下架 (Remove/Discontinue) */
  --publish-color: #89CFF0;
  /* Light Pastel Blue for 发布 (Release/Publish) */
}

.xh-layout {
  position: relative;
}

.container {
  width: 100%;
  padding: 16px 10px 16px 204px;
  background: #f5f7fc;
  box-sizing: border-box;
  overflow-y: scroll;
  position: absolute;
  height: calc(100vh - 55px);
  margin-top: 55px;
}

/* ul,
li {
  list-style: none;
} */

a {
  text-decoration: none !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);

}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #EDEDED;
}



/* fonts */

.title-font {
  font-size: 32px;
}

.subtitle-font {
  font-size: 22px;
}

.text-font {
  font-size: 16px;
}

.isHide {
  display: none !important;
}

.contain {
  padding: 20px 24px;
  overflow: auto;
  background: #f8f8f8;
  box-sizing: border-box;
}

.contain_title {
  font-size: 29px;
  margin-top: 15px;
}

.contain_modules {
  width: 100%;
  padding: 20px 24px;
  background: #fff;
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: 7px;
}

.noneTop {
  margin-top: 0 !important;
}

.nonePaddingBottom {
  padding-bottom: 0 !important;
}

.marginTop {
  margin-top: 16px;
}

.contain_modules_title {
  display: flex;
  justify-content: space-between;
}

.contain_modules_title .name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contain_modules_title .name .line {
  display: inline-block;
  height: 20px;
  width: 4px;
  background: #FF5186;
}

.contain_modules_title .name .text {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
}

.contain_modules_title .name .text .sub {
  font-size: 14px;
  margin-left: 8px;
  color: #595961;
}

.red {
  color: red;
}

.clearfix::after {
  content: '';
  display: block;
  height: 0;
  clear: both
}