@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.translation-image {
  width: 21px;
  min-width: 21px;
  height: 21px;
  margin-right: 12px;
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
.translation-image-not-allowed {
  img {
    cursor: not-allowed;
  }
}
.translation-select {
  // .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  //   width: 130px;
  //   border-radius: 5px;
  //   padding: 0 16px;
  //   border: 1px solid #d9d9d9;
  //   height: 34px;
  // }
  // .select .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  // .select
  //   .ant-select-single.ant-select-show-arrow
  //   .ant-select-selection-placeholder {
  //   padding-right: 20px;
  //   line-height: 34px;
  //   font-size: 14px;
  // }
  // .select
  //   .ant-select-single:not(.ant-select-customize-input)
  //   .ant-select-selector
  //   .ant-select-selection-search-input {
  //   height: 34px;
  //   line-height: 34px;
  // }
  // .ant-select-single .ant-select-selector .ant-select-selection-search {
  //   position: absolute;
  //   top: 0;
  //   right: 11px;
  //   bottom: 0;
  //   left: 16px;
  // }
}
.translation-tip {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 17px;
  color: #f55186;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
