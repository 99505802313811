@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.maxdiff-info-container {
  margin: 0 0 0 21px;
  //   font-size: 14px;
  //   font-family: PingFangSC-Regular, PingFang SC;
  //   font-weight: 400;
  //   color: #322d2c;
  //   line-height: 18px;
  .maxdiff-table {
    .maxdiff-table-header {
      display: flex;
      width: 570px;
      background: #f7f8fa;
      font-size: 12px;
      color: #303133;
      line-height: 18px;
      text-align: center;
      .maxdiff-table-header-title {
        width: 190px;
        padding: 9px;
        border: 1px solid #eff1f7;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        &:nth-child(2) {
          border-left: none;
          border-right: none;
        }
      }
    }
    .maxdiff-table-body {
      background: #fff;
      .maxdiff-table-body-item {
        width: 651px;
        // padding-left: 29px;
        // padding-right: 0;
        margin-top: 0;
        display: flex;
        .main-content {
          display: flex;
          width: 570px;
          &:hover {
            .table-content {
              //F4f8ff
              background: rgba(248, 248, 248, 0.8);
            }
            .table-content-option {
              .edit-question-title-wrap {
                .edit-question-title {
                  border: 1px dashed #e3e2e5;
                  box-shadow: none;
                }
              }
            }
          }
          &:focus {
            .table-content {
              background: rgba(248, 248, 248, 0.8);
            }
          }
          .table-content {
            width: 190px;
            border-bottom: 1px solid #eff1f7;
            border-left: 1px solid #eff1f7;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 40px;
            &:last-child {
              border-right: 1px solid #eff1f7;
            }
            &:hover {
              background: rgba(248, 248, 248, 0.8);
            }
            .item {
              display: inline-block;
              width: 14px;
              height: 14px;
              border: 1px solid #e1e4eb;
              border-radius: 50% 50%;
            }
          }

          .table-content-option {
            padding: 4px 10px;
            color: #322d2c;
            display: flex;
            .edit-question-title-wrap {
              line-height: 28px;
              margin: 0 15px 0 0;
              .edit-question-title {
                width: unset;
                word-break: break-all;
              }
            }
            // .upload-question-pic {
            //   .show-pic-wrap {
            //     margin: 0 auto;
            //     padding-left: 13px; // 防止删除按钮
            //   }
            //   img {
            //     width: 24px;
            //     height: 20px;
            //     vertical-align: baseline;
            //   }
            // }
          }
        }
        .main-content-operate {
          display: none;
          //   display: flex;
          align-items: center;
          cursor: pointer;
          margin-left: 8px;
          img {
            width: 12px;
            height: 12px;
          }
        }
        &:hover {
          .main-content-operate {
            display: flex;
          }
        }
      }
    }
  }
  //foot
  .info-foot {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5186;
    line-height: 24px;
    margin: 8px 0 0 12px;
    width: fit-content;
    .add-option-icon {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 0;
    }
  }
}
