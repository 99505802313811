@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-survey-container {
  padding: 40px 120px 0px;

  box-sizing: border-box;
  // overflow-y: auto;
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 14px;
  }
  .survey-item {
    display: flex;
    justify-content: left;
    padding-bottom: 24px;
    align-items: baseline;
    .survey-left {
      width: 110px;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #595961;
      span {
        color: #ff5186;
        margin-right: 5px;
        display: inline-block;
        width: 15px;
      }
    }

    .survey-right {
      position: relative;
      //   cursor: pointer;
      //富文本编辑
      .xm-editor-container {
        width: 600px;
        border: 1px solid #e3e2e5;
        border-radius: 6px;
        .xm-editor-toolbar {
          border-bottom: 1px solid #e3e2e5;
        }
        .xm-editor {
          height: 220px;
          overflow-y: hidden;
        }
        .w-e-toolbar {
          border-radius: 6px 6px 0 0;
        }
        .w-e-text-container {
          border-radius: 0 0 6px 6px;
          padding: 0 6px;
          p {
            font-size: 14px !important;
            line-height: 24px;
            margin: 10px 0;
          }
          // 默认placeholder
          //鼠标游标
          .w-e-text-placeholder {
            left: 16px;
            right: 16px;
            top: 11px;
            color: #9797a1;
            font-size: 14px !important;
            line-height: 24px;
            font-style: unset;
          }
        }
      }
      //input
      .survey_input {
        cursor: pointer;
        width: 334px;
        min-width: 130px;
        height: 38px !important;
        box-sizing: border-box;
        padding: 0 0 0 16px;
        border-radius: 6px;
        font-size: 14px !important;
      }
      //日期
      .date-picker {
        border: 1px solid #e3e2e5;
        border-radius: 6px;
        width: 334px;
        min-width: 130px;
        height: 38px;
        padding: 0 16px;
        .ant-picker-input {
          input {
            font-size: 14px !important;
          }
        }
        ::placeholder {
          color: #9797a1;
          font-size: 14px;
        }
        .ant-picker-input > input:hover {
          border-color: unset;
        }
        .ant-picker-input > input-focused,
        .ant-picker-input > input:focus {
          border-color: unset;
          box-shadow: none;
        }
      }
      .date-picker-wrong {
        border: 1px solid #ff5186;
      }
      //图片
      .survey-image-wrapper {
        margin: 0 0 24px;
        .survey-image {
          width: 190px;
          height: 99px;
          object-fit: contain;
        }
      }
      //图片上传
      .upload-wrapper {
        display: flex;
        align-items: center;
        .upload-btn {
          background: #ff5186;
          color: #fff;
          border-radius: 6px;
          width: 90px;
          min-width: 70px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          font-size: 14px;
          font-weight: 400;
          font-family: PingFang SC;
          cursor: pointer;
          &:hover {
            background: #ff729c;
            color: #fff;
          }
          &:active {
            background: #ef356d;
            color: #fff;
          }
        }
        .upload-text {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #595961;
          margin: 0 0 0 10px;
        }
      }
    }
    .survey-editor {
      .xm-editor-container {
        .xm-editor {
          height: 220px;
        }
      }
    }
    .survey-editor {
      .xm-editor-container {
        .xm-editor {
          height: 220px;
        }
      }
    }
    .description-editor {
      .xm-editor-container {
        .xm-editor {
          height: 96px;
        }
      }
    }
  }
  .survey-item-image {
    align-items: flex-start;
  }
  //   下一步
  .next-active {
    background: #ff5186 !important;
    color: #fff !important;
    &:hover {
      background: #ff729c !important;
      color: #fff;
    }
    &:active {
      background: #ef356d !important;
      color: #fff;
    }
  }
  .operaion-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 4px;
      border: 1px solid #e3e2e5;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 13px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
// .w-e-text-container {
//   height: 100px !important;
//   border: 1px solid red !important;
// }
