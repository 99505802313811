@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.emotion-analysis-contain {
  overflow-y: auto;
  // 表情切换
  .ant-tabs-tab {
    padding: 23px 0 13px;
    & + .ant-tabs-tab {
      margin: 0 0 0 48px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ff5186;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595961;
    line-height: 20px;
    &:hover {
      color: #ff5186;
    }
  }
  .ant-tabs-ink-bar {
    background: #ff5186;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 16px 0;
  }
  .ant-tabs {
    overflow: unset;
  }
}

// 分析卡
.emotion-analysis-card {
  background: #fff;
  margin: 16px 0 0;
  padding: 0 24px 15px;
  border-radius: 16px;
  min-height: calc(100% - 55px); //55+16
  min-width: 1120px;
  width: 100%;
  .emotion-analysis-card-chart-panel {
    margin: 40px 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .wordcloud-panel {
    // justify-content: space-between;
    // align-items: center;
    // flex-wrap: wrap;
  }
  // .word-cloud-container {
  //   width: 50%;
  // }
}
.evaluation-card {
  background: #fff;
  margin: 20px 0;
  padding: 20px 24px 30px;
  border-radius: 16px;
}
.isHide {
  display: none;
}
