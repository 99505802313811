@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-nps-template-info {
  margin-top: 10px;
  min-width: 240px;
  .matrix-nps-info-top {
    // border: 1px solid blue;

    .info-top-name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #322d2c;
      font-size: 14px;
      margin: 20px 0 0 160px; //150+15
      width: 255px; //400-15
      //   border: 1px solid blue;
      .info-top-name {
        max-width: 160px;
      }
    }
    .info-top-value-wrap {
      margin: 15px 0 0 170px; //150+15
      width: 230px; //400-15
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #595961;
      font-size: 14px;
      .draw-slip-index {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        width: 100%;
        color: #9797a1;
        font-size: 14px;
      }
    }
  }
  .draw-slip {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    .draw-slip-left {
      align-items: center;
      display: flex;
      .draw-slip-title {
        align-items: center;
        display: flex;
        width: 150px;
        .edit-question-title-wrap {
          margin: 0 15px 0 0;
          word-break: break-word;
          .edit-question-title {
            &:hover {
              border: 1px solid transparent;
            }
          }
        }
        .upload-question-pic {
          display: flex;
          img {
            width: 24px;
            height: 20px;
            display: inline-block;
          }
        }
      }
      .draw-slip-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        .draw-slip-line {
          width: 230px;
          height: 10px;
          background: #f8f8f8;
          border-radius: 30px;
          position: relative;
          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #ff5186;
            border-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .draw-slip-num {
          font-size: 14px;
          color: #595961;
          margin-left: 24px;
        }
      }
    }
    .draw-slip-left-border {
      border: 1px solid transparent;
      padding: 4px 10px;
    }
  }
}
