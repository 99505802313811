@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.contain-modules {
  width: 100%;
  padding: 20px 24px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 7px;
  margin-top: 0;
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 14px;
  }
  .contain-modules-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 29px;
    border-bottom: none;
    .name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .line {
        display: inline-block;
        height: 20px;
        width: 4px;
        background: #ff5186;
      }
      .text {
        font-size: 18px;
        font-weight: bold;
        margin-left: 8px;
      }
    }
  }
  .contain-modules-subtitle {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595961;
    line-height: 38px;
    margin: 0 0 20px;
  }
  // 注意固定三项不可点击
  .setting-fixed-item {
    pointer-events: none;
  }
  .setting-right-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    line-height: 38px;
    margin: 0 0 20px;
    color: #595961;
    .setting-status {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
      line-height: 38px;
      span {
        font-size: 12px;
        color: #9790a1;
      }
    }
    .setting-select-option {
      width: 180px;
      .ant-select {
        width: 100%;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e3e2e5;
        padding: 2px 10px;
        font-size: 14px;
        min-height: 38px;
        height: 100%;
        // line-height: 38px;
      }
      .ant-select-selection-placeholder {
        color: #9797a1;
        font-size: 14px;
      }
      .ant-select-multiple .ant-select-selection-search {
        -webkit-margin-start: 0px;
        margin-inline-start: 0px;
      }
    }
    .item-is-active {
      font-size: 12px;
      margin-right: 4px;
    }
    .is-min-time {
      margin-left: 4px;
    }
    .setting-right-icon {
      width: 32px;
      height: 16px;
      cursor: pointer;
    }
    .text {
      color: #0772f6;
      font-size: 14px;
    }
    .setting-input {
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e3e2e5;
      height: 38px;
      width: 140px;
      padding: 2px 10px;
      font-size: 14px;
      &:focus {
        border: 1px solid #ff5186;
      }
      &:hover {
        border: 1px solid #ff5186;
      }
    }
    .setting-input-option {
      width: 180px;
    }
    input:disabled {
      border: 1px solid #e3e2e5;
      background-color: #f5f5f5;
      color: #aca899;
      cursor: not-allowed;
      &:hover {
        border: 1px solid #e3e2e5;
      }
    }
  }
  .setting-right-tip {
    align-items: center;
    height: 38px;
    line-height: 38px;
    margin: 0 0 10px;
    color: #595961;
    .setting-right-tip-title {
      font-size: 14px;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
    }
    .setting-right-tip-content {
      font-size: 13px;
      line-height: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
      div {
        span {
          color: #ff5186;
        }
      }
    }
  }
  .setting-right-items-option {
    height: 100%;
  }
}
