@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.single-template-info {
  margin-top: 10px;
  //   min-width: 240px;
  .single-item-wrapper {
    display: flex;
    align-items: center;
    .single-item-left {
      display: flex;
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
    .single-item-right {
      //   display: flex;
      .single-item-title-wrapper {
        display: flex;
        align-items: center;
        .single-item-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #322d2c;
          line-height: 38px;
          margin: 0 15px 0 20px;
        }
        img {
          width: 24px;
          height: 20px;
          display: inline-block;
        }
      }

      .single-item-other-input {
        width: 200px;
        height: 20px;
        border: 1px solid #e3e2e5;
        margin-left: 20px;
      }
    }
  }
}
