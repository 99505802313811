@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.user-container {
  padding: 16px 10px 16px 16px;
  .user-info {
    // width: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
    border-radius: 5px;
    margin: 0 20px;
    padding: 18px 35px 20px;
    .user-info-top {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 17px;
      color: #000000;
    }
    .user-avatar-wrapper {
      margin-top: 9px;
      .user-avatar-img {
        width: 82px;
        height: 82px;
        background: rgba(0, 0, 0, 0);
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 4px #e7e9ef;
        border-radius: 50%;
        margin: 0 auto;
        img {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          object-fit: cover;
        }
        .ant-image-mask {
          border-radius: 50%;
        }
      }
      .user-avatar-text {
        text-align: center;
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 16px;
        color: #3271f3;
        cursor: pointer;
      }
      .ant-upload.ant-upload-select.ant-upload-select-text {
        display: flex;
        justify-content: center;
      }
    }
    .user-detail-wrapper {
      .user-detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 19px;
        border-top: 1px solid #e3e5f9;
        padding: 22px 15px;
        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-bottom: 1px solid #e3e5f9;
        }
        .user-detail-left {
          display: flex;
          .user-detail-title {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #808386;
            width: 110px;
          }
          .user-detail-fill {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 17px;
            color: #000000;
          }
        }
        .user-detail-right {
          display: flex;
          align-items: center;
          .user-revise-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 17px;
            color: #3271f3;
            cursor: pointer;
            &:hover {
              color: rgba(50, 113, 243, 0.7);
            }
          }
          .user-revise-img {
            cursor: pointer;
            img {
              width: 15px;
              height: 15px;
              display: inline-block;
              margin-left: 10px;
            }
            .edit-icon {
              display: inline-block;
            }
            .edit-icon-active {
              display: none;
            }
            &:hover {
              .edit-icon {
                display: none;
              }
              .edit-icon-active {
                display: inline-block;
              }
            }
          }
          .user-revise-text-2 {
            color: #f55186;
            line-height: 16px;
            border-bottom: 1px solid #f55186;
            margin-left: 10px;
            &:hover {
              color: #ff729c;
              border-bottom: 1px solid #ff729c;
            }
          }
        }
      }
      .user-quota-card {
        // 人数额度
        margin: 0 46px 32px;
        padding: 24px 24px 24px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
        .quota-operation-wrapper {
          display: flex;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 17px;
          color: #656c77;
          .quota-operation {
            display: flex;
            .quota-operation-item {
              padding: 0 28px;
              border-right: 1px solid #b1b1b1;
              cursor: pointer;
              &:last-child {
                border-right: none;
              }
              &:first-child {
                padding: 0 28px 0 11px;
              }
              &:hover {
                color: #ff729c;
              }
            }
            .quota-operation-item-active {
              color: #f55186;
            }
          }
        }
        .quota-operation-table {
          margin-top: 10px;
          position: relative;
          .table-sum-wrapper {
            position: absolute;
            bottom: 0;
            line-height: 32px;
            color: #9797a1;
            span {
              color: #ff5186;
            }
          }
          // ant-pagination
          .ant-pagination-item:hover {
            border-color: #f57a9f !important;
            background: #f57a9f !important;
          }
          .ant-pagination-jump-prev
            .ant-pagination-item-container
            .ant-pagination-item-link-icon,
          .ant-pagination-jump-next
            .ant-pagination-item-container
            .ant-pagination-item-link-icon {
            color: #ff5186;
          }
          .ant-pagination-prev:hover {
            color: #f57a9f !important;
          }
          .ant-pagination-next:hover {
            color: #f57a9f !important;
          }
          .ant-pagination-next:hover .ant-pagination-item-link {
            color: #ff5186;
          }
          .ant-pagination-prev:hover .ant-pagination-item-link {
            color: #ff5186;
          }
          .ant-pagination-prev:hover .ant-pagination-item-link,
          .ant-pagination-next:hover .ant-pagination-item-link {
            color: #ff5186;
            border-color: #ff5186;
          }
          .ant-pagination-disabled .ant-pagination-item-link,
          .ant-pagination-disabled:hover .ant-pagination-item-link {
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            cursor: not-allowed;
          }
        }
      }
    }
    .user-detial-btn {
      width: 214px;
      font-size: 13px;
      padding: 13px 11px;
      height: 44px;
      line-height: 13px;
      text-align: center;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: rgba(48, 46, 47, 0.5);
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 500;
      margin: 26px auto 0;
      border: 1px solid #d6d6d6;
      background: rgba(247, 247, 247, 0.39);
      cursor: pointer;
      .icon {
        display: inline-block;
      }
      .icon-active {
        display: none;
      }
      &:hover {
        background: #ff5186;
        border: 1px solid #ff5186;
        color: #fff;
        .icon {
          display: none;
        }
        .icon-active {
          display: inline-block;
        }
      }
      img {
        margin-left: 6px;
        width: 13px;
        height: 13px;
      }
    }
  }
}
