@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.region-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .tag-btn {
    margin: 20px 0 20px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
.region-popup-bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.region-popup-main {
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  width: 720px;
  height: 80%;
  .region-popup-top {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #e3e2e5;
    .popup-top-title {
      display: flex;
      margin: 0 auto;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 28px;
    }
    .popup-delete-icon {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: flex-end;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.region-popup-content {
  display: flex;
  justify-content: center;
  height: calc(100% - 160px);
}
.region-popup-content-left {
  width: 140px;
  box-sizing: border-box;
  border-right: 1px solid #e8e8eb;
  overflow-y: auto;
  height: 100%;
  .content-left-btn {
    &:hover {
      background: #fff8fb;
      color: #ff5186;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    box-sizing: border-box;
    cursor: pointer;
    span {
      display: block;
      font-size: 14px;
      height: 48px;
      line-height: 48px;
    }
    img {
      width: 6px;
      height: 10px;
    }
  }
  .content-left-select {
    background: #fff1f6;
    span {
      color: #ff5186;
    }
  }
}

.region-popup-content_right {
  padding: 20px;
  height: 100%;
  overflow: scroll;
  flex: 1;
  .region-level {
    flex: 1;
    border-bottom: 1px dashed #e3e2e5;
    margin-bottom: 20px;
    span {
      display: inline-block;
      padding: 7px 16px;
      border: 1px solid #e3e2e5;
      border-radius: 5px;
      background: #f8f8f8;
      color: #595961;
      font-size: 14px;
      margin: 0 0 20px 20px;
      cursor: pointer;
      width: 110px;
      text-align: center;
    }
    .region-right-select-item {
      background: #ff5186 !important;
      color: #ffffff !important;
    }
  }
}
