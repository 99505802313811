.home_top{
    display: flex;
    justify-content: space-between;
}
.home_top span:nth-child(1){
    font-size: 1.388vw;
    font-weight: bold;
}
.home_top span:nth-child(2){
    color: #595961;
    font-size: 0.833vw;
}

.home_main{
    display: flex;
    justify-content: space-between;
    margin-top: 1.388vw;
}
.home_main .home_main_left{
    /*width: 58.333vw;*/
    width: 74.55%;
    /*flex: 0.455;*/
}
.home_main_left .home_tag{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.home_main_left .home_tag .list{
    /*width: 21.666vw;*/
    width: 31.666%;
    height: 9.027vw;
    background: #fff;
    border-radius: 1.11vw;
    padding: 2.083vw 1.666vw;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}
.home_tag .list img{
    width: 4.861vw;
    height: 4.861vw;
}
.home_main_left .home_tag .list div{
    margin-left: 1.111vw;
}
.home_main_left .home_tag div span:nth-child(1){
    display: block;
    color: #9797A1;
    font-size: 1.111vw;
}
.home_main_left .home_tag div span:nth-child(2){
    display: block;
    font-weight: bold;
    font-size: 2.083vw;
    margin-top: 0.625vw;
}
.home_main .home_main_right{
    /*width: 21.666vw;*/
    width: 23.64%;
    /*flex:2.364;*/
}

.home_title{
    width: 100%;
}
.home_title .date{

}
.date span{
    font-size: 0.833vw;
    color: #9797A1;
    padding: 0.347vw 0.833vw ;
    border: 1px solid #E3E2E5;
    display: inline-block;
    margin-right: 1.111vw;
    border-radius: 5px;
    cursor: pointer;
}

.home_title .text_echart{
    width: 100%;
    height: 22vw;
}

.home_product{
    width: 100%;
}
.home_product .more{
    font-size: 0.972vw;
    color: #9797A1;
    cursor: pointer;
}
.home_product .home_product_list .list_title{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 0.694vw;
}
.home_product_list .list_title span{
    font-size: 0.833vw;
    color: #9797A1;
    width: 13.333%;
    text-align: center;
    line-height: 2.638vw;
    height: 2.638vw;
}
.home_product_list .list_title span:nth-child(1){
    width: 5%;
}
.home_product_list .list_title span:nth-child(2){
    width: 20%;
}

.home_product .home_product_list .list_name{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.home_product .home_product_list .list_name:nth-child(2n-1){
   background: #F8F8F8;
    border-radius: 5px;
}
.home_product_list .list_name span{
    font-size: 0.833vw;
    width: 13.333%;
    text-align: center;
    line-height: 2.638vw;
    height: 2.638vw;
}
.home_product_list .list_name span:nth-child(1){
    width: 5%;
}
.home_product_list .list_name span:nth-child(2){
    width: 20%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.home_product_list .list_name span:nth-child(7){
    color: #FF5186;
    cursor: pointer;
}

.home_news_list{}
.home_news_item{
    display: flex;
    justify-content: space-between;
    margin-top: 1.111vw;
}
.home_news_item span{
    font-size: 0.972vw;
}
.home_news_item span:nth-child(1){
    color: #595961;
    width: 73%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.home_news_item span:nth-child(2){
    color: #9797A1;
}

/*.home_entry{*/
/*    position: relative;*/
/*    z-index: 11;*/
/*}*/
.home_entry_icon_list{}
.home_entry_icon_list .home_entry_icon{
    margin-top: 1.111vw;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.home_entry_icon_items img{
    width: 3.333vw;
    height: 3.333vw;
}
.home_entry_icon_items span{
    font-size: 0.972vw;
    display: block;
    margin-top: 0.555vw;
}
.home_entry_top{
    margin-top: 2.361vw !important;
}

.vchart{
    margin-top: 0.833vw;
    text-align: center;
}
.vchart img{
    width: 9.444vw;
    height: 9.444vw;
}
.vchart .text{
    display: block;
    color: #9797A1;
    font-size: 0.833vw;
    margin-top: 0.833vw;
}

.home_padding_bottom{
    padding-bottom: 0.8vw;
}

.home_guide_popup{
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 10;
}
.home_guide_popup_none{
    display: none;
}
.home_guide_popup_position1{
    left: 55vw;
    top:4.6vw;
    z-index: 11;
}
.home_guide_popup_position2{
    left: 55vw;
    top:22.6vw;
    z-index: 11;
}
.home_guide_popup_position3{
    left: 55vw;
    top:42.7vw;
    z-index: 11;
}
.home_guide_contain{
    background:#FF5186;
    width: 19.93vw;
    border-radius: 1vw;
    color: #fff;
    padding: 1.666vw;
    box-sizing: border-box;
    position: relative;
}
.home_guide_title{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.home_guide_title span{
    font-size: 0.972vw;
}
.home_guide_title img{
    width: 0.694vw;
    height: 0.694vw;
    cursor: pointer;

}
.home_guide_sub{
    margin-top: 0.694vw;
    font-size: 1.388vw;
}
.home_guide_text{
    margin-top: 0.555vw;
    font-size: 0.972vw;
}
.home_guide_btn{
    margin-top: 1.111vw;
    display: flex;
    justify-content: flex-end;
}
.home_guide_btn span{
    padding: 0.525vw 1.666vw;
    border-radius: 0.5vw;
    font-size: 0.972vw;
    cursor: pointer;
}
.home_guide_btn span:nth-child(1){
    border: 1px solid #fff;
}
.home_guide_btn span:nth-child(2){
    border: 1px solid #FF5186;
    background: #fff;
    color: #FF5186;
    margin-left: 1.111vw;
}
.home_icon_guide{
    width: 0.555vw;
    height: 1.111vw;
    position: absolute;
    top:6vw;
    left: 19.9vw;
}
.position_relative{
    position: relative;
    z-index: 11;
}