@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.max-list-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  .max-chart-popup-container {
    background: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    width: 80%;
    height: 80%;

    .max-chart-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;

      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }

      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .popup-content-top {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px 0;
      align-items: center;

      .top-title-wrapper {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #302e2f;
      }

      .top-btn-wrapper {
        font-size: 16px;
        padding: 0 30px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        border: 1px solid #ff5186;
        cursor: pointer;
        min-width: 122px;

        img {
          margin-right: 10px;
          width: 16px;
          height: 14px;
        }

        &:hover {
          background: #ff729c !important;
          border: 1px solid #ff729c;
        }

        &:active {
          background: #ef356d !important;
          border: 1px solid #ef356d;
        }
      }
    }

    .max-chart-change-wrapper {
      display: flex;
      font-size: 14px;
      padding: 10px 40px;

      .chart-change-sign {
        margin: 0 5px;
        color: #9797a1;
      }

      .chart-change-text {
        color: #595961;
        cursor: pointer;

        &:hover {
          color: #ff5186;
        }
      }

      .chart-change-text-selected {
        color: #ff5186;
      }
    }

    .max-chart-wrapper {
      height: calc(100% - 200px);
      margin: 0 20px;
      width: calc(100% - 40px);

      .max-chart-wrapper-2 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .max-preinstall-echart {
          // ===============
          width: 100%;
          height: 100%;
          margin: 0 0 80px;
        }

        .max-chart-right-container {
          width: 55%;
          height: 100%;
          margin: 16px 0 0;

          .max-chart-right-operator {
            width: 20px;
            color: #595961;
            cursor: pointer;

            &:hover {
              color: #ff5186;
            }
          }

          .max-chart-detail-container {
            background: #f8f8f8;
            padding: 16px;

            .option-title {
              color: #595961;
              font-size: 14px;
              line-height: 14px;
              padding-bottom: 10px;
            }

            .search-wrapper {
              .ant-input-affix-wrapper {
                border-radius: 6px;
                border: 1px solid #e3e2e5;

                &:hover {
                  border-color: #ff5186;
                }

                &:focus {
                  border-color: #ff5186;
                  box-shadow: none;
                }
              }

              .ant-input-affix-wrapper-focused {
                border-color: #ff5186;
                box-shadow: none;
              }
            }
          }
        }
      }

      .max-wordcloud-image-wrapper {
        width: fit-content;
        height: calc(100% - 0px);
        margin: 0 auto;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }

        .wordcloud-image-empty {
          position: relative;
          transform: translateY(-50%);
          top: 40%;
        }
      }

      .max-custom-chart-foot {
        // border: 1px solid green;
        // width: calc(100% - 60px);
        height: 80px;
        display: flex;
        position: absolute;

        // left: 50px;
        .max-foot-name {
          font-size: 14px;

          .max-line {
            border: 2px solid orange;
            border-top-color: transparent;
            border-radius: 2px;
            height: 8px;
            margin: 0 25px;
          }

          .max-sign {
            width: 2px;
            height: 4px;
            background-color: orange;
            margin: 0 auto;
          }

          .max-content {
            color: #595961;
            margin: 3px 0 0;
          }
        }

        .max-foot-name-0 {
          position: absolute;
          //   width: 60%;
          text-align: center;
        }

        .max-foot-name-1 {
          position: absolute;
          //   width: 20%;
          //   right: 20%;
          text-align: center;
        }

        .max-foot-name-2 {
          position: absolute;
          //   width: 20%;
          //   right: 0;
          text-align: center;
        }
      }
    }
  }
}