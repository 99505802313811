@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.gpt-analysis-container {
  overflow-x: auto;
  .gpt-analysis-main {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    width: 100%;
    // height: 100%;
    min-width: 1200px;
    background: #fff;
    padding: 30px 20px 30px 20px;
    margin: 11px 0 0;
  }
  .gpt-analysis-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gpt-analysis-main-top {
    .gpt-analysis-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #000000;
    }
  }
  .gpt-analysis-selection-card {
    width: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
    opacity: 1;
    border-radius: 5px;
    margin: 10px 0 35px;
    padding: 17px 10px 11px;
    .gpt-analysis-content-not-allowed {
      cursor: not-allowed;
      .gpt-analysis-checkbox-card {
        pointer-events: none;
      }
      .gpt-analysis-filter-card {
        pointer-events: none;
      }
    }
    .gpt-analysis-checkbox-card {
      width: 100%;
      background: rgba(245, 247, 252, 1);
      box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
      // opacity: 0.59;
      border-radius: 6px;
      padding: 16px 0;
      .gpt-analysis-checkbox-top {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        .gpt-analysis-checkbox-img {
          width: 16px;
          height: 16px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .gpt-analysis-checkbox-name {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 17px;
          color: #000000;
          margin: 0 20px 16px 6px;
          width: 45px;
        }
      }
      .gpt-analysis-checkbox-body {
        display: grid;
        row-gap: 25px;
        column-gap: 20px;
        grid-template-columns: 1fr 2fr 3fr;
        grid-template-columns: repeat(4, 1fr);
        padding: 0 20px;
        max-height: 240px;
        overflow-y: hidden;
        &:hover {
          overflow-y: scroll;
          padding-right: 14px;
        }
        .question-wrapper {
          display: flex;
          align-items: center;
          .question-checkbox {
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
            display: flex;
            margin: 0 10px 0 0;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .question-checkbox-not-allowed {
            cursor: not-allowed;
          }
          .question-name-wrapper {
            width: 100%;
            background: rgba(255, 255, 255, 1);
            border: 1px solid #e3e2e5;
            border-radius: 5px;

            padding: 13px 0px 13px 0px;
            min-height: 62px;
            max-height: 96px;
            display: flex;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 17px;
            color: #302e2f;
            overflow-y: hidden;

            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 4;
            // -webkit-box-orient: vertical;
            // word-break: break-all;

            // width: 100%;
            // font-size: 13px;
            // font-family: PingFang SC;
            // font-weight: 700;
            // color: #302e2f;
            // line-height: 19px;
            // height: auto;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 4;
            // -webkit-box-orient: vertical;
            // word-break: break-all;
            // margin-bottom: 16px;

            // &:hover {
            //   overflow: auto;
            //   padding-right: 13px;
            // }
            // margin-bottom: 16px;
            .question-name {
              padding: 0 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
            &:hover {
              .question-name {
                overflow-y: scroll;
                padding-right: 24px;
                max-height: 51px; // 79-13-13
                text-overflow: unset;
                display: unset;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
              }
            }
          }
        }
      }
    }
    .gpt-analysis-filter-card {
      width: 100%;
      margin: 16px 0 0;
      background: rgba(245, 247, 252, 1);
      border-radius: 6px;
      min-width: 1072px;
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .gpt-analysis-operation {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #ff5186;
        border-radius: 5px;
        border: 1px solid #ff5186;
        width: 120px;
        height: 44px;
        font-weight: 400;
        font-family: PingFang SC;
        color: #fff;
        font-size: 16px;
        margin: 0 5px 0 0;
        &:hover {
          background: #ff729c;
          border: 1px solid #ff729c;
        }
        &:active {
          background: #ef356d;
          border: 1px solid #ef356d;
        }
      }
    }
    .gpt-analysis-operation-not-allowed {
      cursor: not-allowed;
    }
  }
  .gpt-analysis-chart-card {
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
    opacity: 1;
    border-radius: 5px;
    padding: 26px 25px 26px;
    margin-top: 23px;
    // max-height: calc(100vh - 600px);
    max-height: calc(100vh - 65px);
    overflow-y: hidden;
    &:hover {
      overflow-y: scroll;
      padding-right: 19px;
    }
    .gpt-analysis-session-wrapper {
      & + .gpt-analysis-session-wrapper {
        margin: 15px 0 0;
      }
      .gpt-analysis-session {
        display: flex;
        align-items: center;
        .gpt-analysis-session-text-wrapper {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 17px;
          color: #000000;
          opacity: 1;
          display: flex;
          align-items: center;
          width: 100%;
          .gpt-analysis-session-text {
            max-width: 50%;
            display: flex;
            .session-text {
              padding: 16px 13px;
            }
            img {
              padding: 2px 0px 0 0;
              width: 43px;
              height: fit-content;
            }
          }
          .gpt-analysis-session-delete {
            display: none;
            width: 11px;
            height: fit-content;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
          &:hover {
            .gpt-analysis-session-delete {
              display: block;
              &:hover {
                img {
                  content: url("../../images/gpt_analysis/cur_icon_bin_active.png");
                }
              }
            }
          }
        }
        .gpt-analysis-session-avatar {
          width: 33px;
          height: 33px;
          background: rgba(0, 0, 0, 0);
          border-radius: 50%;
          opacity: 1;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
      }
      .gpt-analysis-session-right {
        justify-content: flex-end;
        .gpt-analysis-session-text-wrapper {
          justify-content: flex-end;
          .gpt-analysis-session-text {
            margin-right: 15px;
            background: rgba(245, 247, 252, 1);
            box-shadow: 0px 2px 13px rgba(172, 172, 172, 0.24);
            margin-left: 10px;
          }
        }
      }
      .gpt-analysis-session-left {
        justify-content: flex-start;
        .gpt-analysis-session-text-wrapper {
          justify-content: flex-start;
          .gpt-analysis-session-text {
            margin-left: 15px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 13px rgba(172, 172, 172, 0.24);
            margin-right: 10px;
          }
        }
      }
    }
  }
  // 数据预览
  .gpt-analysis-preview-card {
    .gpt-analysis-preview-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #000000;
    }
    .gpt-analysis-preview-table {
      margin: 16px 0;
      padding: 10px 10px 30px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
      border-radius: 5px;
      position: relative;
      .table-sum-wrapper {
        position: absolute;
        bottom: 30px;
        line-height: 32px;
        color: #9797a1;
        span {
          color: #ff5186;
        }
      }
      .ant-table-thead > tr > th {
        padding: 20px 16px !important;
        line-height: 25px;
        color: #000000;
        background: #fff;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 600;
      }
      .ant-table-tbody > tr:nth-child(odd) {
        background: #fff;
      }
      .ant-table-tbody > tr:nth-child(even) {
        background: #f8f8f8;
      }
      .ant-pagination-options-quick-jumper input:hover {
        border-color: #ff5186;
      }
      .ant-pagination-options-quick-jumper input:focus,
      .ant-pagination-options-quick-jumper input-focused {
        border-color: #ff5186;
        box-shadow: none;
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: #ff5186 !important;
        // font-size: 12px;
        // letter-spacing: -1px;
        // opacity: 0;
        // transition: all 0.2s;
      }
    }
  }
  .gpt-analysis-ask-card {
    margin-top: 20px;
    .edit-question-title-ask {
      border: 1px solid rgba(247, 248, 250, 1);
      border-radius: 5px;
      width: 100%;
      padding: 22px 25px;
      min-height: 76px;
      line-height: 18px;
      max-width: 100%;
      background: rgba(247, 248, 250, 1);
    }
    p {
      /* 去除点击p标签后的原生黄色边框 */
      outline: none;
    }
    p:empty::before {
      content: attr(placeholder);
      color: #999999;
      font-size: 13px;
      font-family: PingFang SC;
    }
    .gpt-analysis-ask-operation {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #fff1f6;
        border-radius: 5px;
        border: 1px solid #ff5186;
        width: 140px;
        height: 44px;
        font-weight: 400;
        font-family: PingFang SC;
        color: #ff5186;
        font-size: 16px;
        margin: 0 15px 0 0;
        &:hover {
          background: #fff8fb;
          border: 1px solid rgba(255, 81, 134, 0.6);
        }
        &:active {
          background: #ffe1ec;
          border: 1px solid #ff5186;
        }
      }
      div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #ff5186;
        border-radius: 5px;
        border: 1px solid #ff5186;
        width: 120px;
        height: 44px;
        font-weight: 400;
        font-family: PingFang SC;
        color: #fff;
        font-size: 16px;
        margin: 0 15px 0 0;
        &:hover {
          background: #ff729c;
          border: 1px solid #ff729c;
        }
        &:active {
          background: #ef356d;
          border: 1px solid #ef356d;
        }
      }
    }
  }
}
