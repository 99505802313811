@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-copy-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .question-copy-popup-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .question-copy-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 720px;
    height: 40%;
    .question-copy-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .question-copy-popup-content {
    height: calc(100% - 160px);
    padding: 30px 30px 0;
    overflow: auto;
    .card-item-wrapper {
      .card-item-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #302e2f;
        width: fit-content;
        position: relative;
        margin: 0 0 0 26px;
        &:before {
          //用这两个伪类实现样式渲染
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: -26px;
          transform: translateY(-50%);
          width: 11px;
          height: 11px;
          background: rgba(245, 81, 134, 1);
          border-radius: 50%;
        }
      }
      .card-item {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        margin: 20px 0px;
        .ant-select {
          width: 100%;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          width: 100%;
          border-radius: 6px;
          padding: 0 16px;
          border: 1px solid #e3e2e5;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
          .ant-select-selector
          input {
          cursor: auto;
          margin-left: 4px;
        }
      }
    }
  }

  .tag-btn {
    margin: 20px 0 20px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
