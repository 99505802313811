@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.color-picker-container {
  display: inline-flex;
  align-items: center;
  width: 334px;
  //   max-width: 150px;
  min-width: 130px;
  padding: 4px 16px;
  border: 1px solid #e3e2e5;
  border-radius: 6px;
  height: 38px !important;
  input[type='text'] {
    border: none;
    width: 100%;
    font-size: 14px !important;
  }
  input[type='color'] {
    margin-right: 8px;
    border: none;
    background: none;
    height: auto;
    width: auto;
    &::-webkit-color-swatch-wrapper {
      height: 14px;
      width: 14px;
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 6px;
    }
  }
}
