.upload .upload_item {
    width: 74px;
    height: 74px;
    display: inline-block;
    position: relative;
    margin-right: 24px;
    margin-top: 16px;
}

.upload_item:nth-child(4n) {
    margin-right: 0;
}

.upload_bg {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: 1px solid #E3E2E5;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    cursor: pointer;
}

.upload .upload_popup {
    display: none;
}

.upload .isHover:hover .upload_popup {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 1px;
    background: #000;
    opacity: 0.7;
    border-radius: 7px;
}

.upload_popup_img {
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    top: -3px;
    right: -9px;
    cursor: pointer;
}

.upload .isHover:hover .upload_popup_img {
    display: block;
}

.domImg {
    display: none;
    /*width: 1px;*/
    /*height: 1px;*/
}