@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.not-clicked {
  pointer-events: none;
}
.not-allowed {
  cursor: not-allowed;
}
.tag_dispatch {
  padding: 0 120px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100% - 100px);
  overflow-x: hidden;
  //   下一步
  .next-active {
    background: #ff5186 !important;
    color: #fff !important;
    &:hover {
      background: #ff729c !important;
      color: #fff;
    }
    &:active {
      background: #ef356d !important;
      color: #fff;
    }
  }
  .dispatch-min-unit {
    font-size: 16px;
    font-family: PingFang SC;
    line-height: 16px;
    color: #302e2f;
    display: flex;
    font-weight: bold;
    margin-top: 70px;
  }
  .operaion-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 4px;
      border: 1px solid #e3e2e5;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 13px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
.tag-dispatch-top {
  width: 730px;
  background: #edf5ff;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 40px;
  img {
    width: 46px;
    height: 46px;
  }
  .msg {
    margin-left: 16px;
    div {
      color: #002ca0;
      font-size: 14px;
      &:nth-child(1) {
        font-weight: bold;
      }
      &:nth-child(2) {
        margin-top: 8px;
      }
    }
  }
}
.dispatch-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  color: #595961;
  font-size: 14px;
  .dispatch-radio-item {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 36px;
    }
    &:nth-child(3) {
      margin-left: 80px;
    }
    span {
      margin-left: 8px;
    }
    .item-border {
      width: 16px;
      height: 16px;
      // margin-top: -14px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.setting_main {
  margin-top: 24px;
  min-width: 730px;
}
.throng-setting {
  margin-top: 24px;
  padding: 30px 24px;
  border: 1px solid #e8e8eb;
  border-radius: 6px;
  max-width: 730px;
}
.throng-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: 1px solid #fff;
  }
  div {
    display: flex;
    align-items: center;
    span {
      color: #ffffff;
      background: #ff5186;
      border-radius: 6px;
      width: 96px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 14px;
      cursor: pointer;
    }
  }
  .throng-top-left {
    border: 1px solid #e3e2e5;
    width: 376px;
    padding: 14px;
    border-radius: 6px;
    .left-title {
      width: 300px;
      input {
        width: 100%;
      }
    }
    .left-percent {
      width: 32px;
      height: 24px;
      input {
        width: 100%;
        height: 24px;
        border: 1px solid transparent;
        color: #ff5186;
        font-size: 12px !important;
        text-align: center;
      }
      border: 1px solid #ff5186;
      border-radius: 3px;
    }
    .left-percent-sign {
      color: #ff5186;
      font-size: 12px;
      margin: 0 0 0 5px;
    }
  }
}

.tag-setting {
  margin-top: 0px;
  padding-left: 9px;
  box-sizing: border-box;
  border-left: 1px solid #e8e8eb;
  display: flex;
  align-items: center;
  .tag-setting-icon {
    margin-left: -19px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tag-setting-items {
    margin-left: 26px;
    .tag-setting-list {
      border: 1px solid #e3e2e5;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 6px;
      width: 610px;
      margin-top: 16px;
      // &:first-child {
      //   margin-top: 0 !important;
      // }
      .tag-setting-list-contain {
        display: flex;
        justify-content: space-between;
        img {
          width: 12px;
          height: 12px;
          margin-right: 6px;
          cursor: pointer;
        }
        span {
          font-size: 12px;
        }
        .tag-setting-list-title-wrapper {
          display: flex;
          align-items: center;
          font-size: 14px;
          .tag-title-icon {
            margin-right: 10px;
          }
          .tag-title {
            font-size: 14px;
          }

          .tag-title-must {
            color: #ff5186;
            margin-right: 10px;
            position: relative;
            top: 2px;
          }
        }
        .tag-setting-list-add-wrapper {
          cursor: pointer;
          color: #f55186;
        }
      }
      .tag-setting-padding-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;
        .line {
          width: 21px;
          border: 1px solid #e8e8eb;
          border-right: none;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-left: -33px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .tag-dispatch-main {
          margin-left: 14px;
          .tag-dispatch-item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
            .tag-rule-icon {
              margin-right: 8px;
              cursor: pointer;
              img {
                width: 18px;
                height: 18px;
              }
            }
            .tag-dispatch-select {
              position: relative;
              cursor: pointer;
              .tag-dispatch-right {
                width: 280px;
                height: 34px !important;
                /*width: 100%;*/
                box-sizing: border-box;
                padding: 0 24px 0 14px;
                font-size: 12px;
                /* overflow: hidden; */
                /* white-space: nowrap; */
                text-overflow: ellipsis;
              }
              img {
                width: 10px;
                height: 6px;
                position: absolute;
                top: 14px;
                right: 14px;
              }
              .left-text {
                display: inline-block;
                height: 34px !important;
                line-height: 34px;
              }
              .center-text {
                display: inline-block;
                height: 34px !important;
                line-height: 34px;
                margin-left: 14px;
              }
              .right-text {
                display: inline-block;
                height: 34px !important;
                line-height: 34px;
                margin-left: 14px;
              }

              ul {
                // width: 280px;
                width: max-content;
                min-width: 280px;
                position: absolute;
                left: 0;
                top: 34px;
                background: #ffffff;
                box-shadow: 0px 3px 10px 0px rgba(238, 238, 238, 0.5);
                border-radius: 4px;
                z-index: 12;
                overflow: auto;
                max-height: 400px;
                li {
                  height: 38px;
                  line-height: 38px;
                  font-size: 14px;
                  padding: 0 16px;
                  box-sizing: border-box;
                  cursor: pointer;
                  // overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  &:hover {
                    // background: rgba(255, 81, 134, 0.04)
                    //   url('../../../images/cur_icon_choice.png') no-repeat 95% center;
                    background: rgba(255, 81, 134, 0.04);
                    // background-size: 4%;
                    color: #ff5186;
                  }
                  .noDisplay {
                    background: #eee !important;
                    &:hover {
                      cursor: not-allowed;
                      background: rgba(0, 0, 0, 0.04);
                    }
                  }
                }
              }
              // 下拉框
              .select
                .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector {
                width: 280px;
                height: 34px;
              }
              .select
                .ant-select-single.ant-select-show-arrow
                .ant-select-selection-item,
              .select
                .ant-select-single.ant-select-show-arrow
                .ant-select-selection-placeholder {
                padding-right: 20px !important;
                line-height: 34px;
                font-size: 12px !important;
              }
              .select
                .ant-select-single:not(.ant-select-customize-input)
                .ant-select-selector
                .ant-select-selection-search-input {
                height: 34px;
                line-height: 34px;
              }
              .ant-select:not(.ant-select-customize-input)
                .ant-select-selector {
                position: relative;
                background-color: #fff;
                border: 1px solid #e3e2e5;
                border-radius: 4px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              }
              .select
                .ant-select-single.ant-select-show-arrow
                .ant-select-selection-placeholder {
                color: #9797a1 !important;
              }
              // age 下拉框
              .ant-select-selection-placeholder {
                color: #9797a1;
              }
            }
            .tag-dispatch-text {
              border: 1px solid #e3e2e5;
              border-radius: 6px;
              padding: 0 14px;
              box-sizing: border-box;
              color: #595961;
              font-size: 12px;
              display: flex;
            }
            .region-text {
              border: 1px solid #e3e2e5;
              border-radius: 6px;
              padding: 4px 16px;
              box-sizing: border-box;
              font-size: 12px;
              color: #595961;
            }
            .special-text {
              height: 100px;
              .special-textarea {
                height: 100%;
                font-size: 12px;
                line-height: 20px;
                padding: 10px;
                width: 280px;
              }
            }
            .cur-icon-delete {
              width: 12px;
              height: 12px;
              margin-left: 12px;
            }
            .tag-dispatch-item-percent {
              display: flex;
              align-items: center;
              margin: 0 0 0 15px;
              .tag-dispatch-item-percent-icon {
                cursor: pointer;
                color: #595961;
                img {
                  width: 18px;
                  height: 18px;
                  vertical-align: text-bottom;
                }
              }
              .tag-dispatch-item-percent-name {
                width: 32px;
                height: 24px;
                margin-left: 4px;
                input {
                  width: 100%;
                  height: 24px;
                  border: 1px solid transparent;
                  color: #ff5186;
                  vertical-align: super;
                  padding: 0 0 1px 0;
                  font-size: 12px !important;
                  text-align: center;
                }
                border: 1px solid #ff5186;
                border-radius: 3px;
              }
              .tag-dispatch-item-percent-name-sign {
                color: #ff5186;
                font-size: 12px;
                margin: 0 0 0 5px;
              }
            }
            .tag-dispatch-age {
              .ant-select:not(.ant-select-customize-input)
                .ant-select-selector {
                border: 1px solid #e3e2e5;
                border-radius: 4px;
                transition: none;
                min-width: 280px !important;
                height: 34px !important;
                min-height: 32px;
              }
              .ant-select-multiple .ant-select-selection-placeholder {
                font-size: 12px;
              }
              .ant-select-multiple .ant-select-selection-item-content {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.tag-setting-must {
  padding-left: 9px;
  border-left: 1px solid transparent;
  margin-top: 0px;
}
.add-tag-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 38px 0 0;
  span {
    color: #ffffff;
    background: #ff5186;
    border-radius: 6px;
    width: 96px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 14px;
    cursor: pointer;
  }
}
.add-tag {
  color: #ffffff;
  background: #ff5186;
  border-radius: 5px;
  width: 96px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
}

.tag-dispatch-reslute {
  margin-top: 23px;
  color: #595961;
  display: flex;
  .reslute-left {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #302e2f;
  }
  .reslute-right {
    font-size: 16px;
    font-family: PingFang SC;
    line-height: 22px;
    color: #595961;
  }
}
