@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 38px;
    line-height: 38px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 120px;
    height: 38px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 20px !important;
    line-height: 38px;
    font-size: 14px !important;
    // color: #322d2c !important;
    img {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: #322d2c;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #9b9ba4 !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ff5186 !important;
    border-right-width: 1px !important;
    box-shadow: none;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  .ant-select-open {
    .ant-select-item {
      // color: red !important;
    }
    .ant-select-selector {
      border-color: #ff5186 !important;
      box-shadow: none !important;
    }
  }
}
//设置下拉框的
.ant-select-item {
  background-color: #fff !important;
  color: #322d2c !important;
  font-size: 12px !important;
  transition: none !important;
  &:hover {
    background: rgba(255, 81, 134, 0.04) !important;
    background-color: rgba(255, 81, 134, 0.04) !important;
    color: #ff5186 !important;
  }
  &:active {
    background-color: rgba(255, 81, 134, 0.04) !important;
    background: rgba(255, 81, 134, 0.04) !important;
    color: #ff5186 !important;
  }
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 81, 134, 0.04) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ff5186 !important;
  font-weight: 600 !important;
  background-color: rgba(255, 81, 134, 0.04) !important;
}
// 不可选择 置灰
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
  &:hover {
    background: unset !important;
    background-color: unset !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
//设置滚动条
.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  width: 2px !important;
}
.rc-virtual-list-scrollbar-thumb {
  background: #e3e2e5 !important;
}
// 搜索
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf !important;
}

// 设置下拉框
// .ant-select-dropdown {
//   width: auto !important;
// }
// .ant-select-item-option-content {
//   flex: none !important;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   width: 85% !important;
// }
// .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   color: #ff5186 !important;
//   font-weight: 600 !important;
//   // background-color: rgba(255, 81, 134, 0.04) !important;
//   background: rgba(255, 81, 134, 0.04) url('../../images/cur_icon_choice.png')
//     no-repeat 92% center !important;
//   background-size: 12px 8px !important;
// }

// 多选时候的箭头
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #ff5186 !important;
}
