@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.project-entry-bg {
  overflow-x: scroll;
  .project-entry-container {
    background: #fff;
    padding: 16px 23px 20px;
    min-width: 1100px;
    min-height: -webkit-fill-available;
    height: unset;
  }
}

.project-entry-bg-2 {
  overflow-y: unset !important;
  overflow-x: scroll;
  .project-entry-container-2 {
    background: #fff;
    padding: 20px 30px 20px;
    min-width: 1100px;
    min-height: unset !important;
    height: calc(100% - 0px) !important;
  }
}

// 其他的
.project-entry-top-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 22px;
  color: #000000;
  margin-bottom: 13px;
}
.step-container {
  display: flex;
  margin-left: 120px;
  align-items: center;
  .step-card-wrapper {
    .step-card {
      .step-card-number {
        border-radius: 50%;
        width: 23px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        color: #302e2f;
        background: #efefef;
        font-size: 11px;
        margin: 0 auto 5px;
      }
      .step-card-text {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 17px;
        color: #302e2f;
      }
    }
    .step-card-active {
      .step-card-number {
        background: #ff5186;
        color: #fff;
      }
      .step-card-text {
        color: #f55186;
      }
    }
  }
  .step-line {
    margin: 0 36px;
    width: 165px;
    height: 9px;
    background: #f8f8f8;
    border-radius: 6px;
  }
  .step-line-2 {
    margin: 0 36px;
    width: 110px;
    height: 9px;
    background: #f8f8f8;
    border-radius: 6px;
  }
}
