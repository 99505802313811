@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-chart-container {
  //   border: 1px solid red;
  width: 100%;
  .chart-operaion-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px 0 0px;
    .chart-operaion-text {
      color: #595961;
      cursor: pointer;
      &:hover {
        color: #ff5186;
      }
    }
  }
  .chart-wrapper {
    height: calc(100% - 135px);
    margin: 0;
    .chart-wrapper-2 {
      width: 100%;
      height: 100%;
      .preinstall-echart {
        width: 100%;
        height: 360px;
        // margin: 0 0 80px;
      }
    }
    .wordcloud-image-wrapper {
      width: fit-content;
      width: 360px;
      height: 360px;
      margin: 0 auto;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
