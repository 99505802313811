@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.single-info-container {
  margin: 0 0 0 21px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #322d2c;
  line-height: 18px;
  .single-item-wrapper {
    // border: 1px solid blue;
    display: flex;
    width: fit-content;
    align-items: center;
    &:hover {
      .single-left-wrapper {
        border: 1px dashed #e3e2e5;
      }
      .delete-option-wrapper {
        display: block;
      }
    }
    // 左+中
    .single-left-wrapper {
      display: flex;
      display: flex;
      width: fit-content;
      align-items: center;
      border: 1px solid transparent;
    }
    // 左边
    & + .single-item-wrapper {
      margin: 6px 0 0 0;
    }
    .radio-icon-wrapper {
      margin: 0 8px 0;
      .setting-radio-icon {
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    //中间
    .single-content-wrapper {
      //   border: 1px solid orange;
      //   border: 1px solid transparent;
      padding: 4px 10px;
      color: #322d2c;
      // 中上
      .single-title-wrapper {
        display: flex;
        .edit-question-title-wrap {
          line-height: 28px;
          margin: 0 15px 0 0;
          position: relative;
          &:hover {
            .edit-question-btn {
              display: block;
            }
          }
          .edit-question-btn {
            display: none;
            cursor: pointer;
            font-size: 12px;
            color: #595961;
            line-height: 20px;
            position: absolute;
            top: -16px;
            width: 100%;
            text-align: center;
            min-width: 68px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: normal;
            &:hover {
              color: #ff5186;
            }
          }
        }
      }
      .other-input-container {
        margin: 10px 0 0;
        .other-input {
          width: 200px;
        }
      }
    }
    //右边
    .delete-option-wrapper {
      display: none;
      margin: 0 0 0 16px;
      .delete-option-icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
      .option-active-wrapper {
        cursor: pointer;
        width: 30px;
        height: 12px;
        display: inline-block;
        margin-left: 6px;
        font-size: 12px;
        color: #595961;
        &:hover {
          color: #ff5186;
        }
      }
    }
  }
  //下架颜色置灰
  .single-item-wrapper-2 {
    .single-content-wrapper {
      color: gainsboro;
    }
  }
  .single-item-wrapper-setting {
    // border: 1px solid red;
    .single-left-wrapper {
      border: 1px dashed #e3e2e5;
    }
    .delete-option-wrapper {
      display: block;
    }
  }
  //foot
  .info-foot {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5186;
    line-height: 24px;
    margin: 8px 0 0 12px;
    width: fit-content;
    .add-option-icon {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 0;
    }
  }
}
