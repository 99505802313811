@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.upload-question-pic {
  // border: 1px solid red;
  .upload-pic {
    width: 24px;
    height: 21px;
    cursor: pointer;
  }
  .show-pic {
    width: 45px;
    height: 31px;
    object-fit: cover;
  }
  .show-pic-wrap {
    display: flex;
    width: 70px;
    // border: 1px solid red;
    &:hover {
      // margin: 10px !important;
      .upload-close-img {
        display: block;
      }
    }
  }
  //删除icon
  .upload-close-img {
    // position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    margin: 8px 0 0 6px;
    z-index: 5;
    cursor: pointer;
  }
}
