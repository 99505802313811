@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-single-info-container {
  margin: 0 0 0 21px;
  .matrix-score-column-answer {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    color: #322d2c;
    font-size: 14px;
    margin: 20px 0 0 0px; //150+15
    width: 100%;
    .matrix-score-column-answer-left {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 254px);
      margin-left: 172px;
      .draw-slip-row {
        flex: 1;
        text-align: center;
        align-items: center;
        .edit-question-title-wrap {
          position: relative;
          .edit-question-title {
            width: unset;
          }
          &:hover {
            .delete-up-option-btn-wrapper {
              display: block;
            }
          }
          .delete-up-option-btn-wrapper {
            display: none;
            // display: block;
            cursor: pointer;
            font-size: 12px;
            color: #595961;
            line-height: 20px;
            position: absolute;
            top: -15px;
            width: 100%;
            text-align: center;
            min-width: 68px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: normal;
            .delete-up-option-btn {
              font-size: 12px;
              display: flex;
              width: 40px;
              margin: 0 auto;
              line-height: 14px;
              .delete-up-option-text {
                margin-right: 4px;
                &:hover {
                  color: #ff5186;
                }
              }
            }
            .delete-option-icon {
              width: 12px;
              height: 12px;
            }
          }
        }
        .edit-question-title-wrap-2 {
          .edit-question-title {
            color: gainsboro;
          }
        }
        .edit-question-title-wrap-setting {
          .edit-question-title {
            // width: unset;
            border: 1px dashed #e3e2e5;
          }
        }
        .upload-question-pic {
          .show-pic-wrap {
            margin: 0 auto;
            padding-left: 13px; // 防止删除按钮
          }
        }
      }
    }
    .matrix-score-column-answer-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff5186;
      line-height: 24px;
      margin: 0 0 0 12px;
      width: fit-content;
      .add-option-icon {
        width: 12px;
        height: 12px;
        margin: 0 6px 0 0;
      }
    }
  }
  .matrix-score-row-answer {
    .draw-slip {
      margin: 20px 0 0 0;
      display: flex;
      border: 1px solid transparent;
      align-items: center;
      width: 100%;
      &:hover {
        // border: 1px dashed #e3e2e5;
        .draw-slip-left-border {
          border: 1px dashed #e3e2e5;
        }
        .draw-slip-right {
          display: block;
        }
      }
      .draw-slip-left {
        align-items: center;
        display: flex;
        width: calc(100% - 70px);
        .draw-slip-title {
          align-items: center;
          display: flex;
          width: 150px;
          .edit-question-title-wrap {
            margin: 0 5px 0 0;
          }
        }
        .draw-slip-index {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 160px);
          margin: 0 0 0 10px;
          color: #9797a1;
          font-size: 14px;
          .grade-item {
            display: flex;
            // justify-content: flex-start;
            // align-items: center;
            width: 100%;
            img {
              width: 16px;
              height: 16px;
              display: block;
              margin: 0 auto;
            }
            span {
              color: #595961;
              font-size: 14px;
            }
          }
          .grade-item-2 {
            display: flex;
            width: 100%;
            height: 28px;
            background: rgba(255, 255, 255, 0.39);
            border: 1px solid #dbdbdb;
            margin: 0 10px;
          }
        }
      }
      .draw-slip-left-border {
        border: 1px solid transparent;
        padding: 4px 10px;
      }
      .draw-slip-right {
        display: none;
        margin: 0 0 0 10px;
        .delete-option-icon {
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
        .option-active-wrapper {
          cursor: pointer;
          width: 30px;
          height: 12px;
          display: inline-block;
          margin-left: 6px;
          font-size: 12px;
          color: #595961;
          &:hover {
            color: #ff5186;
          }
        }
      }
    }
    .draw-slip-2 {
      .draw-slip-title {
        color: gainsboro;
      }
    }
    .draw-slip-setting {
      margin: 20px 0 0 0;
      display: flex;
      border: 1px solid transparent;
      align-items: center;
      width: 100%;
      // border: 1px dashed #e3e2e5;
      .draw-slip-left-border {
        border: 1px dashed #e3e2e5;
      }
      .draw-slip-right {
        display: block;
      }
    }
  }

  //比重foot
  .info-foot {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5186;
    line-height: 24px;
    margin: 5px 0 0 12px;
    width: fit-content;
    .add-option-icon {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 0;
    }
  }
}
