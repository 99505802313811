@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-score-info-container {
  margin: 0 0 0 21px;
  .matrix-score-info-top {
    // border: 1px solid blue;

    .info-top-name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #322d2c;
      font-size: 14px;
      margin: 20px 0 0 150px; //150+15
      width: 370px; //400-15
      //   border: 1px solid blue;
      .info-top-name {
        max-width: 160px;
      }
    }
    .info-top-value-wrap {
      margin: 15px 0 0 165px; //150+15
      width: 340px; //400-15
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #595961;
      font-size: 14px;
    }
  }
  .draw-slip {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    &:hover {
      // border: 1px dashed #e3e2e5;
      .draw-slip-left-border {
        border: 1px dashed #e3e2e5;
      }
      .draw-slip-right {
        display: block;
      }
    }
    .draw-slip-left {
      align-items: center;
      display: flex;
      .draw-slip-title {
        align-items: center;
        display: flex;
        width: 150px;
        .edit-question-title-wrap {
          margin: 0 15px 0 0;
        }
      }
      .draw-slip-index {
        display: flex;
        justify-content: space-between;
        width: 355px;
        margin: 0;
        color: #9797a1;
        font-size: 14px;
        .grade-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin: 0 8px 0 0;
          }
          span {
            color: #595961;
            font-size: 14px;
          }
        }
      }
    }
    .draw-slip-left-border {
      border: 1px solid transparent;
      padding: 4px 10px;
    }
    .draw-slip-right {
      display: none;
      margin: 0 0 0 10px;
      .delete-option-icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
      .option-active-wrapper {
        cursor: pointer;
        width: 30px;
        height: 12px;
        display: inline-block;
        margin-left: 6px;
        font-size: 12px;
        color: #595961;
        &:hover {
          color: #ff5186;
        }
      }
    }
  }
  .draw-slip-2 {
    .draw-slip-title {
      color: gainsboro;
    }
  }
  .draw-slip-setting {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    // border: 1px dashed #e3e2e5;
    .draw-slip-left-border {
      border: 1px dashed #e3e2e5;
    }
    .draw-slip-right {
      display: block;
    }
  }
  //比重foot
  .info-foot {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5186;
    line-height: 24px;
    margin: 5px 0 0 12px;
    width: fit-content;
    .add-option-icon {
      width: 12px;
      height: 12px;
      margin: 0 6px 0 0;
    }
  }
}
