@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.blank-template-info {
  margin-top: 10px;
  min-width: 240px;
  .blank-template-input {
    min-width: 100px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid #e3e2e5;
  }
}
