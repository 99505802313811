@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-score-template-info {
  margin-top: 10px;
  min-width: 240px;
  .matrix-score-info-top {
    // border: 1px solid blue;

    .info-top-name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #322d2c;
      font-size: 14px;
      margin: 20px 0 0 160px; //150+15
      // width: 280px; //400-15
      width: calc(100% - 180px);
      //   border: 1px solid blue;
      .info-top-name {
        max-width: 160px;
      }
    }
    .info-top-value-wrap {
      margin: 15px 0 0 175px; //150+15
      // width: 250px; //400-15
      width: calc(100% - 195px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #595961;
      font-size: 14px;
    }
  }
  .draw-slip {
    margin: 20px 0 0 0;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    .draw-slip-left {
      align-items: center;
      display: flex;
      .draw-slip-title {
        align-items: center;
        display: flex;
        width: 150px;
        .edit-question-title-wrap {
          margin: 0 15px 0 0;
          word-break: break-word;
          &:hover {
            border: 1px solid transparent;
          }
        }
        .upload-question-pic {
          display: flex;
          img {
            width: 24px;
            height: 21px;
            display: inline-block;
          }
        }
      }
      .draw-slip-index {
        display: flex;
        justify-content: space-between;
        width: 265px;
        margin: 0;
        color: #9797a1;
        font-size: 14px;
        margin-left: 10px;
        .grade-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin: 0 8px 0 0;
          }
          span {
            color: #595961;
            font-size: 14px;
          }
        }
      }
    }
    .draw-slip-left-border {
      border: 1px solid transparent;
      padding: 4px 10px;
    }
  }
}
