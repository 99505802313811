@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.base-info {
  padding: 40px 120px 0px;
  box-sizing: border-box;
  min-width: 900px;
  // overflow-y: auto;
  input {
    background: none;
    outline: none;
    border: 1px solid #e3e2e5;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  input:focus {
    border: 1px solid #ff5186;
    border-radius: 6px;
  }
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 14px;
  }
  //   下一步
  .next-active {
    background: #ff5186 !important;
    color: #fff !important;
    &:hover {
      background: #ff729c !important;
      color: #fff;
    }
    &:active {
      background: #ef356d !important;
      color: #fff;
    }
  }
  .base-info-item {
    display: flex;
    justify-content: left;
    padding-bottom: 24px;
    align-items: baseline;
    .base-info-left {
      width: 173px;
      height: 39px;
      line-height: 39px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #595961;
      span {
        color: #ff5186;
        margin-right: 5px;
        display: inline-block;
        width: 15px;
      }
    }
    .base-info-select {
      position: relative;
      cursor: pointer;
      .cur_icon_select {
        width: 10px;
        height: 6px;
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 480px;
        border-radius: 6px;
        padding: 0 16px;
        border: 1px solid #e3e2e5;
      }
      .base-info_right {
        width: 480px;
        height: 39px !important;
        /*width: 100%;*/
        box-sizing: border-box;
        padding: 0 0 0 16px;
        font-size: 14px !important;
      }
      .date_picker {
        .ant-picker-input > input {
          border-color: transparent;
          // border-right-width: none !important;
        }
        .ant-picker-input > input:focus,
        .ant-picker-input > input-focused {
          border-color: transparent;
          box-shadow: none;
          border-radius: none;
          // border-right-width: none !important;
        }
        .ant-picker-input > input:hover {
          border-color: transparent;
          // border-right-width: none !important;
        }
      }
      .base-info-image-wrapper {
        display: flex;
        .base-info-image {
          margin-right: 8px;
          margin-top: 16px;
          border-radius: 7px;
          border: 1px solid #e3e2e5;
          width: 74px;
          height: 74px;
          // padding: 2px;
          background-repeat: no-repeat !important;
          background-size: 100% 100% !important;
          display: flex;
          cursor: pointer;

          .upload-pic {
            border-radius: 7px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          .show-pic {
            border-radius: 7px;
            width: 100%;
            height: 100%;
          }
          .show-pic-wrap {
            position: relative;
            &:hover {
              .upload-close-img {
                display: block;
                position: absolute;
                top: -7px;
                right: -7px;
              }
            }
          }
          //删除icon
          .upload-close-img {
            display: none;
            width: 16px;
            height: 16px;
            z-index: 5;
            cursor: pointer;
          }
        }
        .base-info-image-text {
          margin-top: 70px;
          font-size: 12px;
          line-height: 12px;
          color: #595961;
        }
      }
      .ant-select-show-search.ant-select:not(.ant-select-customize-input)
        .ant-select-selector
        input {
        cursor: auto;
        margin-left: 4px;
      }
    }
  }
  .operaion-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 4px;
      border: 1px solid #e3e2e5;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 13px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
/*input{*/
/*    background:none;*/
/*    outline:none;*/
/*    border:1px solid #E3E2E5;*/
/*}*/
/*input:focus{*/
/*    border:1px solid #FF5186;*/
/*}*/

/*::placeholder{*/
/*    color: #9797A1;*/
/*}*/

.date_picker {
  border: 1px solid #e3e2e5 !important;
  border-radius: 5px !important;
  width: 480px;
  height: 39px;
  padding: 0 16px !important;
  .ant-picker-input {
    input {
      font-size: 14px !important;
    }
  }
  ::placeholder {
    color: #9797a1 !important;
    font-size: 14px !important;
  }
}

.ant-picker:hover {
  border-color: #e3e2e5 !important;
}

.ant-picker-focused {
  border-color: #ff5186 !important;
  box-shadow: none !important;
  border: 1px solid #ff5186;
}
