@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.calculation-list-contain {
  border-radius: 16px;
  overflow-y: auto;
  background: #fff;
  /* 顶部 */
  .calculation-list-top {
    padding: 20px 24px;
    background: #fff;
    border-radius: 7px 7px 0 0;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    img {
      width: 8px;
      height: 15px;
      cursor: pointer;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      margin-left: 14px;
    }
  }
  /* 中部 */
  .calculation-list-contain-main {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    background: #fff;
    padding: 20px 24px;
    //操作按钮
    .project-operaion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      .project-operaion-left {
        display: flex;
        .project-delete {
          background: #fff1f6;
          border-radius: 10px;
          border: 1px solid #ff5186;
          width: 90px;
          height: 38px;
          text-align: center;
          line-height: 36px;
          font-weight: 500;
          font-family: PingFang SC;
          color: #ff5186;
          font-size: 14px;
          margin: 0 16px 0 0;
          cursor: pointer;
          &:hover {
            background: #fff8fb;
            border: 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec;
            border: 1px solid #ff5186;
          }
        }
        .project-add {
          font-size: 14px;
          padding: 12px 17px;
          height: 38px;
          line-height: 40px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
          img {
            margin-right: 10px;
            width: 14px;
            height: 14px;
          }
          &:hover {
            background: #ff729c;
          }
          &:active {
            background: #ef356d;
          }
        }
      }
      .project-operaion-right {
        .ant-btn {
          transition: none;
          font-size: 14px;
        }
        .ant-input-affix-wrapper {
          border-radius: 6px;
          height: 38px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;

          &:hover {
            border-color: #ff5186;
          }
          &:focus {
            border-color: #ff5186;
            box-shadow: none;
          }
        }
        .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          border-radius: unset;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        .ant-input-affix-wrapper-focused {
          border-color: #ff5186;
          box-shadow: none;
        }
        .ant-input-group {
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          //   line-height: 1.5715;
          line-height: 14px;
        }
        .ant-input-group-addon {
          position: relative;
          //   padding: 0 11px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: normal;
          font-size: 14px;
          text-align: center;
          background-color: #fafafa;
          //   border: 1px solid #d9d9d9;
          border-radius: 6px;
          transition: none;
        }
        .ant-input-search-button {
          height: 38px;
        }
        .ant-input-search
          > .ant-input-group
          > .ant-input-group-addon:last-child
          .ant-input-search-button {
          padding-top: 0;
          padding-bottom: 0;
          border-radius: 0 6px 6px 0;
        }
        .ant-btn-primary {
          color: #fff;
          border-color: #ff5186;
          background: #ff5186;
          text-shadow: none;
          box-shadow: none;
        }
        .ant-btn-primary:hover,
        .ant-btn-primary:focus {
          color: #fff;
          border-color: #ff729c;
          background: #ff729c;
        }
      }
    }
    //table
    .calculation-list-main {
      background: #ffffff;
      border-radius: 16px;
      padding: 40px 0;
      .calculation-list-table {
        position: relative;
        .evaluation-table-sum {
          position: absolute;
          bottom: 0;
          line-height: 32px;
          span {
            color: #ff5186;
          }
        }
        .ant-table-thead > tr > th {
          background: #fff;
          padding: 12px 12px;
          font-weight: bold;
          &:first-child {
            padding-right: 8px;
            padding-left: 8px;
          }
          // text-align: center;
          padding: 12px 16px;
        }

        .ant-table-thead
          > tr
          > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 1.6em;
          background-color: #fff;
          transform: translateY(-50%);
          transition: background-color 0.3s;
          content: '';
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td,
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
          // position: relative;
          // padding: 12px 12px;
          // overflow-wrap: break-word;
        }
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ff5186;
        border-color: #ff5186;
      }
      //   .ant-checkbox-inner {
      //     background-color: #f8f8f8;
      //     border: 1px solid #595961;
      //     // width: 16px;
      //     // height: 16px;
      //   }
      .ant-checkbox-checked::after {
        border: 1px solid #ff5186;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: #ff5186;
        border: 0;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #ff5186;
      }
      // 表格最后的操作
      .ant-table-tbody > tr.ant-table-row > td {
        &:last-child,
        &:nth-last-child(2) {
          span {
            color: #ff5186;
            cursor: pointer;
            & + span {
              margin: 0 0 0 10px;
              // cursor: not-allowed;
              // &:hover {
              //   color: #ff5186 !important;
              // }
              // &:active {
              //   color: #ff5186 !important;
              // }
            }
            &:hover {
              color: #ff729c !important;
            }
            &:active {
              color: #ef356d !important;
            }
          }
        }
      }
    }
  }
  /* 底部 */
  .calculation-list-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 24px;
    box-sizing: border-box;
    width: calc(100% - 312px);
    .calculation-list-bottom-content {
      padding: 13px 24px;
      box-sizing: border-box;
      background: #ffff;
      box-shadow: 0px -4px 10px 0px rgba(230, 230, 230, 0.5);
      border-radius: 16px 16px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .calculation-list-bottom-content div:nth-child(1) {
      background: #f8f8f8;
      border-radius: 7px;
      border: 1px solid #e3e2e5;
      width: 114px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      color: #9797a1;
      font-size: 14px;
      cursor: pointer;
    }

    .calculation-list-bottom-content div:nth-child(2) {
      background: #f8f8f8;
      border-radius: 7px;
      border: 1px solid #e3e2e5;
      width: 114px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      color: #9797a1;
      font-size: 14px;
      cursor: pointer;
      margin-left: 16px;
    }
    .calculation-list-bottom-content div:nth-child(1):hover {
      background: #fff1f6;
      color: #ff5186;
      border: 1px solid #ff5186;
    }
    .calculation-list-bottom-content div:nth-child(2):hover {
      background: #ff5186;
      color: #fff;
    }
  }
}
.ant-tooltip-inner {
  min-width: 30px;
  max-width: 400px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  font-size: 16px;
}
