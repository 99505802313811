@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.incentive-list-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .tag-btn {
    margin: 16px 0 20px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
  .tag-btn-disabled {
    margin: 16px 0 20px;
    display: flex;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:first-child {
        margin: 0 30px 0 0;
      }
    }
  }
}
.incentive-list-popup-contain {
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  width: 580px;
  //   max-height: 60%;
  height: 55%;
}
.incentive-list-popup-top {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e3e2e5;
  .popup-top-title {
    display: flex;
    margin: 0 auto;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 28px;
  }
  .popup-delete-icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
.incentive-list-popup-content {
  padding: 20px 40px 0;
}
.incentive-list-popup-content {
  height: calc(100% - 150px);
  overflow-y: scroll;
  //   &:hover {
  //     overflow-y: scroll;
  //   }
  width: 100%;
  .xhs-card-wrapper {
    & + .xhs-card-wrapper {
      margin-top: 16px;
      border-top: 1px dashed #e3e2e5;
      padding-top: 16px;
    }
    .card-title-wrapper {
      display: flex;
      justify-content: space-between;
      .card-title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 18px;
        margin-bottom: 8px;
        color: #302e2f;
      }
      .card-subtitle {
        color: #595961;
      }
    }
    .card-item-wrapper {
      .card-item-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #302e2f;
        width: fit-content;
        position: relative;
        margin: 0 0 0 26px;
        &:before {
          //用这两个伪类实现样式渲染
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          left: -26px;
          transform: translateY(-50%);
          width: 11px;
          height: 11px;
          background: rgba(245, 81, 134, 1);
          border-radius: 50%;
        }
      }
      .card-item {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        textarea {
          margin: 12px 0 0;
          background: rgba(245, 245, 245, 1);
          outline: none;
          border: 1px solid rgba(245, 245, 245, 1);
          border-radius: 6px;
          box-sizing: border-box;
          font-size: 13px;
          padding: 11px 20px;
          width: 100%;
          min-height: 45px;
        }

        textarea:focus {
          border: 1px solid #ff5186;
          border-radius: 4px;
        }
      }
      .card-item-images {
        margin: 9px 0 17px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 140px;
        overflow-y: hidden;
        &:hover {
          overflow-y: scroll;
        }
        .ant-image {
          margin-top: 7px;
          margin-right: 14px;
        }
      }
      .card-item-images-text {
        margin-left: 20px;
      }
      .card-item-link-wrapper {
        margin: 13px 0 23px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0px 3px 20px rgba(188, 188, 188, 0.16);
        opacity: 1;
        border-radius: 6px;
        padding: 12px 20px;
        .card-item-link {
          display: flex;
          justify-content: space-between;
          .copy-icon {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            cursor: pointer;
          }
        }
      }
      .card-item-textarea-disabled {
        cursor: not-allowed;
      }
    }
  }
}
