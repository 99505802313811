@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.detail-option-list-container {
  margin: 20px 10px 20px;
  .ant-table-body {
    overflow-y: auto !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0 !important;
  }
  // ant-pagination
  .ant-pagination-item:hover {
    border-color: #f57a9f !important;
    background: #f57a9f !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #ff5186;
  }
}
