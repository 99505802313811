@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.product-container {
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 13px;
  }
  input {
    border-radius: 4px;
  }
  .product-entry {
    width: 100%;
    min-height: 100%;
    min-width: fit-content;
    background: #fff;
    padding: 16px 23px;
    box-sizing: border-box;
  }
  .product-entry-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #302e2f;
    }
    .top-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .top-right-save-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #fff1f6;
        border-radius: 4px;
        border: 1px solid #ff5186;
        width: 94px;
        height: 34px;
        font-weight: 400;
        font-family: PingFang SC;
        color: #ff5186;
        font-size: 13px;
        margin: 0 23px 0 0;
        &:hover {
          background: #fff8fb;
          border: 1px solid rgba(255, 81, 134, 0.6);
        }
        &:active {
          background: #ffe1ec;
          border: 1px solid #ff5186;
        }
      }
      .top-right-reslute-button {
        font-size: 13px;
        padding: 0 9px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 4px;
        border: 1px solid #ff5186;
        cursor: pointer;
        img {
          margin-right: 8px;
          width: 13px;
          height: 13px;
        }
        &:hover {
          background: #ff729c !important;
          border: 1px solid #ff729c;
        }
        &:active {
          background: #ef356d !important;
          border: 1px solid #ef356d;
        }
      }
    }
  }
  .product-entry-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    min-width: 1020px;
    height: calc(100% - 64px);
    .product-entry-left {
      width: calc(100% - 494px);
      min-width: 600px !important;
      box-sizing: border-box;
      .product-entry-left-content {
        border-radius: 6px;
        width: 80%;
        min-width: 560px;
        background: #efefef;
        margin: 0 auto;
        padding: 40px 0;
      }
    }
    .product-entry-right {
      width: 494px;
      margin: 0 2px 0 0;
      border: 1px solid #e3e2e5;
      border-radius: 6px;
      padding: 20px 20px;
      .contain-module {
        width: 100%;
        box-sizing: border-box;
        .contain-module-title {
          display: flex;
          justify-content: space-between;
          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .line {
              display: inline-block;
              height: 16px;
              width: 3px;
              background: #ff5186;
            }
            .text {
              font-size: 16px;
              line-height: 20px;
              font-weight: bold;
              margin-left: 8px;
              color: #302e2f;
              .sub {
                font-size: 11px;
                color: #9797a1;
              }
              .red {
                font-size: 11px;
                color: #f55186;
              }
            }
          }
        }
      }
      .product-info-item {
        display: flex;
        justify-content: left;
        padding-bottom: 17px;
        .product-info-left {
          width: 98px;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          color: #595961;
          span {
            display: inline-block;
            width: 15px;
          }
        }
        .product-info-right {
          width: 334px;
          height: 30px !important;
          /*width: 100%;*/
          box-sizing: border-box;
          padding: 0 0 0 16px;
        }
        .product-info-select {
          position: relative;
          cursor: pointer;
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            color: #322d2c;
          }
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            color: #9b9ba4 !important;
          }
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: c !important;
            border-right-width: 1px !important;
            box-shadow: none;
          }
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(
              .ant-select-customize-input
            )
            .ant-select-selector {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
          }
          .ant-select-open {
            .ant-select-selector {
              border-color: #ff5186 !important;
              box-shadow: none !important;
            }
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            font-size: 13px;
          }
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            font-size: 13px;
          }
          //操作除了树标签品类
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            width: 334px;
            border-radius: 5px;
            padding: 0 16px;
            border: 1px solid #e3e2e5;
            height: 30px !important;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-search {
            position: absolute;
            top: 0;
            right: 11px;
            bottom: 0;
            left: 16px;
          }
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-item,
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            padding-right: 20px !important;
            line-height: 30px !important;
            font-size: 13px !important;
          }
          .select
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 30px !important;
          }
          .select
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input {
            height: 30px !important;
            line-height: 30px !important;
          }
          // 多选
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid #e3e2e5;
            border-radius: 5px;
            transition: none;
            width: 334px;
            height: 30px !important;
          }
          .ant-select-multiple .ant-select-selection-placeholder {
            font-size: 13px;
            color: #9797a1;
          }
          .ant-select-multiple .ant-select-selection-item-content {
            font-size: 13px;
          }
          .ant-select-multiple .ant-select-selection-item {
            margin-top: 0px;
          }
        }
        .product-info-left-textarea {
          line-height: normal !important;
        }
        textarea.product-info-right {
          padding: 10px 16px;
          height: 68px !important;
          border-radius: 5px;
        }
        textarea {
          background: none;
          outline: none;
          border: 1px solid #e3e2e5;
          // border-radius: 4px;
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          font-size: 13px;
        }

        textarea:focus {
          border: 1px solid #ff5186;
          border-radius: 4px;
        }
      }
      .upload-container {
      }
      .product-info-container {
        .contain-module {
          .contain-module-title {
            margin: 20px 0 16px;
          }
        }
      }
      .product-detail-container {
        .contain-module {
          .contain-module-title {
            margin-top: 23px;
          }
        }
        .product-editor {
          margin: 16px 13px 23px 12px;
          .xm-editor-container {
            // width: 600px;
            border: 1px solid #e3e2e5;
            border-radius: 6px;
            .xm-editor-toolbar {
              border-bottom: 1px solid #e3e2e5;
            }
            .xm-editor {
              height: 300px;
              overflow-y: hidden;
            }
            .w-e-toolbar {
              border-radius: 6px 6px 0 0;
            }
            .w-e-text-container {
              border-radius: 0 0 6px 6px;
              padding: 0 6px;
              p {
                font-size: 14px !important;
                line-height: 24px;
                margin: 10px 0;
              }
              // 默认placeholder
              //鼠标游标
              .w-e-text-placeholder {
                left: 16px;
                right: 16px;
                top: 11px;
                color: #9797a1;
                font-size: 14px !important;
                line-height: 24px;
                font-style: unset;
              }
            }
          }
        }
      }
      .brand-gift-container {
        & + .brand-gift-container {
          margin-top: 5px;
        }
        .contain-module {
          .contain-module-title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.product-entry-dropdown {
  //下拉框
  .ant-select-item-option-content {
    flex: none !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85% !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #ff5186 !important;
    font-weight: 600 !important;
    // background-color: rgba(255, 81, 134, 0.04) !important;
    background: rgba(255, 81, 134, 0.04) url("../../images/cur_icon_choice.png")
      no-repeat 92% center !important;
    background-size: 12px 8px !important;
  }
}
.tree-select-popup {
  //操作树的选中样式
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: rgba(255, 81, 134, 0.04) !important;
    color: #ff5186 !important;
  }
  .ant-select-tree
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: rgba(255, 81, 134, 0.1) !important;
    color: #ff5186 !important;
  }
}
