@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.download-brief-container {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  background: #f5f7fc;
  overflow-y: scroll;
  .download-brief-content {
    background: #fff;
    padding: 20px 30px 30px;
    min-height: 800px;
    .download-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
        color: #302e2f;
      }
      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
          & + .btn {
            margin-left: 15px;
          }
          font-size: 16px;
          padding: 0 30px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          border: 1px solid #ff5186;
          cursor: pointer;
          img {
            margin-right: 10px;
            width: 16px;
            height: 14px;
          }
          &:hover {
            background: #ff729c !important;
            border: 1px solid #ff729c;
          }
          &:active {
            background: #ef356d !important;
            border: 1px solid #ef356d;
          }
        }
      }
    }
  }
  .download-brief-wrapper {
    margin-top: 30px;
    .name {
      font-size: 20px;
      line-height: 32px;
    }
    .time {
      color: #999;
      display: inline-block;
      margin-bottom: 20px;
    }
    .description {
      border-top: 1px solid #f4f4f4;
      color: #333;
      font-size: 14px;
      line-height: 24px;
      padding-top: 24px;
    }
  }
}
