.Upload_Popup_contain{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    z-index: 10002;
}
.Upload_Popup_bg{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Upload_Popup_main{
    padding: 20px 24px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 7px;
    width: 400px;
}
.Upload_Popup_title{
    display: flex;
    justify-content: space-between;
}
.Upload_Popup_title span{
    font-size: 16px;
    font-weight: bold;
}
.Upload_Popup_title img{
    width: 10px;
    height: 10px;
    cursor: pointer;
}
.Upload_Popup_main .content{
    margin-top: 18px;
    color: #595961;
    font-size: 14px;
}
.Upload_Popup_btn{
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
}
.Upload_Popup_btn span{
    cursor: pointer;
    border-radius: 7px;
    width: 90px;
    height: 38px;
    line-height: 38px;
}
.Upload_Popup_btn span:nth-child(1){
    border: 1px solid #E3E2E5;
    color: #595961;
    text-align: center;
}
.Upload_Popup_btn span:nth-child(2){
    border: 1px solid #FF5186;
    background: #FF5186;
    color: #fff;
    margin-left: 16px;
    text-align: center;
}