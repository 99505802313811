@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.dispatch-list-container-bg {
  .dispatch-list-container {
    background: #fff;
    border-radius: 5px;
    padding: 16px 23px;
    min-height: -webkit-fill-available;
  }
}

.dispatch-entry-top {
  display: flex;

  .top-title {
    font-size: 16px;
    color: #302e2f;
    font-weight: bold;
    font-family: PingFang SC;
    line-height: 22px;
  }
}

//操作按钮
.dispatch-operaion {
  display: flex;
  align-items: center;
  margin: 20px 0 4px;

  .dispatch-operaion-left {
    ::placeholder {
      color: #9797a1;
      font-size: 13px;
      line-height: 13px;
    }

    .ant-btn {
      transition: none;
      font-size: 13px;
    }

    .ant-input-affix-wrapper {
      border-radius: 6px;
      width: 300px;
      height: 34px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 13px;
      padding: 6px 16px;
      align-items: center;
      border: 1px solid #e3e2e5;

      &:hover {
        border-color: #ff5186;
      }

      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }

    .ant-input-prefix {
      //搜索
      margin-right: 10px;
    }

    .ant-input {
      color: #595961;
      font-size: 13px;
      line-height: 13px;
      border-radius: 0;
    }

    .ant-input-search-button {
      // 查询
      width: 70px;

      span {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 13px;
        color: #ffffff;
      }
    }

    .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: unset;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }

    .ant-input-group {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 14px;
    }

    .ant-input-group-addon {
      position: relative;
      //   padding: 0 11px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      background-color: #fafafa;
      //   border: 1px solid #d9d9d9;
      border-radius: 6px;
      transition: none;
    }

    .ant-input-search-button {
      height: 34px;
    }

    .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0 6px 6px 0;
    }

    .ant-btn-primary {
      color: #fff;
      border-color: #ff5186;
      background: #ff5186;
      text-shadow: none;
      box-shadow: none;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
      border-color: #ff729c;
      background: #ff729c;
    }
  }

  .dispatch-operaion-right {
    display: flex;
    margin: 0 0 0 16px;

    .dispatch-operaion-button {
      background: #fff1f6;
      border-radius: 6px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 16px 0 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }

      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }

      img {
        height: 13px;
        display: inline-block;
        margin: 0 9px 0 0;
      }
    }
  }
}

//table
.dispatch-list-main {
  padding: 17px 0 0;

  .dispatch-list-table {
    position: relative;

    .table-sum-wrapper {
      position: absolute;
      bottom: 0;
      line-height: 32px;
      color: #9797a1;

      span {
        color: #ff5186;
      }
    }

    .logistics-info {
      a {
        color: var(--modify-color);
        cursor: pointer;

        &:hover {
          color: var(--modify-color);
        }

        &:active {
          color: var(--modify-color);
        }
      }

      color: var(--modify-color);
      cursor: pointer;

      &:hover {
        color: var(--modify-color);
      }

      &:active {
        color: var(--modify-color);
      }
    }
  }
}