@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.edit-formula-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.45);
  .edit-formula-container {
    position: relative;
    top: calc(50% - 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 24px 24px;
    background: #fff;
    // 顶部
    .edit-formula-top {
      display: flex;
      justify-content: space-between;
      // border: 1px solid red;
      align-items: center;
      &-title {
        color: #322d2c;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
      }
      &-close {
        cursor: pointer;
        width: 14px;
        height: 14px;
      }
    }
    // 内容
    .edit-formula-content {
      width: 100%;
      //text
      .edit-formula-text {
        margin: 10px 0 0;
        min-height: 50px;
        font-size: 30px;
        line-height: 50px;
        text-align: right;
        padding: 0 5px;
        background-color: #e8e8eb;
      }
      //按钮区
      .edit-formula-main {
        display: flex;
        height: 450px;
        .edit-formula-box {
          border: 1px solid #cccccc;
          border-radius: 4px;
          width: 70px;
          height: 70px;
          float: left;
          margin-left: 20px;
          margin-top: 20px;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 25px;
          line-height: 70px;
          text-align: center;
          &:hover {
            color: #ffffff;
            background-color: #409eff;
          }
          &:nth-child(4n-3) {
            margin-left: 0;
          }
        }
        .edit-formula-left {
          width: 340px;
        }
        .edit-formula-right {
          width: 180px;
          margin-left: 20px;
          align-items: flex-start;
          overflow: auto;
          .edit-formula-box {
            margin-left: 0;
            &:nth-child(2n) {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .edit-formula-bottom {
      display: flex;
      justify-content: flex-end;
      div:nth-child(1) {
        width: 90px;
        height: 38px;
        background: #ffffff;
        border-radius: 7px;
        border: 1px solid #e3e2e5;
        color: #595961;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
        cursor: pointer;
      }
      div:nth-child(2) {
        margin-left: 16px;
        width: 90px;
        height: 38px;
        background: #ff5186;
        border-radius: 7px;
        border: 1px solid #ff5186;
        color: #fff;
        text-align: center;
        line-height: 38px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
