@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-info-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  .freecross-info-popup-container {
    background: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    .max-chart-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .popup-content {
      width: 100%;
      padding: 30px 20px 0;
      .table-name {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        position: relative;
        padding-left: 10px;
        z-index: 0;
        &:before {
          //用这两个伪类实现样式渲染
          content: '';
          display: inline-block;
          position: absolute;
          bottom: 3px;
          left: 0px;
          width: 5px;
          height: 14px;
          background: rgba(245, 81, 134, 1);
          opacity: 1;
          border-radius: 2px;
          z-index: -1;
        }
      }
    }
  }
}
