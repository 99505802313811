@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.collaborate-container {
  width: 100%;
  box-sizing: border-box;
  background: #f5f7fc;
  .collaborate-content {
    background: #fff;
    padding: 20px 30px 30px;
  }
  .collaborate-card {
    .collaborate-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
        color: #302e2f;
      }
      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
          font-size: 16px;
          padding: 0 30px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          border: 1px solid #ff5186;
          cursor: pointer;
          img {
            margin-right: 10px;
            width: 16px;
            height: 14px;
          }
          &:hover {
            background: #ff729c !important;
            border: 1px solid #ff729c;
          }
          &:active {
            background: #ef356d !important;
            border: 1px solid #ef356d;
          }
        }
      }
    }
    .user-row {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .user {
        display: flex;
        align-items: center;
        .user-icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .user-icon-1 {
          background: #f55186;
        }
        .user-icon-2 {
          background: #fac255;
        }
        .user-icon-3 {
          background: #01b0f1;
        }
        .user-name {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 20px;
          color: #595961;
          margin-left: 5px;
        }
        & + .user {
          margin-left: 40px;
        }
      }
    }
    .collaborate-img {
      width: 90%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .collaborate-line {
    margin: 35px 0;
    border: 1px dashed #e3e2e5;
  }
}
