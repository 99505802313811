@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-type-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .question-type-popup-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .question-type-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 720px;
    height: 40%;
    .question-type-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .question-type-popup-content {
    height: calc(100% - 160px);
    padding: 30px 30px 0;
    overflow: auto;

    .question-item-container {
      //   display: flex;
      //   flex-wrap: wrap;
      //   .question-item {
      //     border: 1px solid #e3e2e5;
      //     width: 110px;
      //     height: 40px;
      //     text-align: center;
      //   }
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 2fr 3fr;
      grid-template-columns: repeat(4, 1fr);

      //   flex-wrap: wrap;
      .question-item {
        border: 1px solid #e3e2e5;
        width: 100%;
        height: 40px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e3e2e5;
        border-radius: 5px;
        background: #f8f8f8;
        color: #595961;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          border: 1px solid #ff5186;
          color: #ff5186;
          background: #fff1f6;
        }
        img {
          display: block;
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
        .question-item-text {
          width: 75px;
        }
      }
      .question-item-selected {
        background: #ff5186 !important;
        border: 1px solid #ff5186;
        color: #fff !important;
      }
    }
  }

  .tag-btn {
    margin: 20px 0 20px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
