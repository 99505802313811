@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.agreement-container {
  padding: 20px 16px;
  color: #666;
  font-size: 14px;
  line-height: 1.8;
  background-color: #fff;
  overflow-y: scroll;
  a {
    color: #000;
  }
}
