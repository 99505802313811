@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.user-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .user-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 330px;
    position: absolute;
    right: 50px;
    top: 55px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 hsl(0deg 0% 88% / 50%);
    .user-popup-top {
      background: linear-gradient(91deg, #fc8bb0 0%, #f55186 100%);
      display: flex;
      padding: 24px 16px 12px;
      .user-popup-top-avatar {
        background: rgba(0, 0, 0, 0);
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 4px #e7e9ef;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .user-popup-top-detail {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 16px;
        color: #ffffff;
        div:first-child {
          padding: 10px 0 4px;
        }
      }
    }
    .user-popup-content {
      .user-popup-item-wrapper {
        border-bottom: 1px solid #e3e2e5;
        padding: 15px 16px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-popup-item-left {
          display: flex;
          align-items: center;
          img {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 5px;
          }
          div {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 16px;
            color: #808386;
            &:last-child {
              font-weight: bold;
              color: #000000;
              margin-left: 7px;
            }
          }
        }
        .user-popup-item-right {
          display: flex;
          align-items: center;
          div:first-child {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 16px;
            color: #f55186;
            border-bottom: 1px solid #f55186;
            cursor: not-allowed;
          }
          img {
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-left: 3px;
          }
        }
      }
    }
    .user-popup-operation {
      display: flex;
      margin: 22px auto;
      justify-content: center;
      .user-operation-btn {
        width: 89px;
        font-size: 12px;
        padding: 6px 0;
        height: 30px;
        line-height: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(48, 46, 47, 0.5);
        border-radius: 3px;
        font-family: PingFang SC;
        font-weight: 500;
        border: 1px solid #d6d6d6;
        background: rgba(247, 247, 247, 0.39);
        cursor: pointer;
        .icon {
          display: inline-block;
        }
        .icon-active {
          display: none;
        }
        img {
          margin-left: 6px;
          width: 11px;
          height: 11px;
        }
        &:hover {
          background: #ff5186;
          border: 1px solid #ff5186;
          color: #fff;
          .icon {
            display: none;
          }
          .icon-active {
            display: inline-block;
          }
        }
      }
      .user-edit-btn {
        background: rgba(255, 239, 244, 0.39);
        border: 1px solid #e35d86;
        color: #f55186;
        margin-right: 22px;
      }
    }
  }
}
