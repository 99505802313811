@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-ai-container {
  // padding: 30px 40px;
  padding: 20px 0 0px;
  box-sizing: border-box;
  .test-input {
    width: 100%;
  }
  .question-ai-tab-panel {
    display: flex;
    justify-content: center;
    .question-ai-tab {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 19px;
      color: #595961;
      cursor: pointer;
      & + .question-ai-tab {
        margin-left: 20px;
      }
    }
    .question-ai-tab-active {
      color: #f55186;
      border-bottom: 1px solid #f55186;
    }
  }
}
