.Toast{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0px;
    left: 0px;
}
.Toast_contain{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.Toast_content{
    width: 100%;
    text-align: center;
}
.Toast_msg{
    text-align: center;
    border-radius: 7px;
    background: rgba(0,0,0,0.7);
    font-size: 20px;
    margin: 0 auto;
    display: inline-block;
    border: 1px solid #666;
    padding: 28px;
    color: #fff;
}