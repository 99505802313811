@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.evaluation-detail-container {
  .evaluation-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .line {
        display: inline-block;
        height: 18px;
        width: 4px;
        background: #ff5186;
        border-radius: 2px;
        margin: 1px 0 0 0;
      }
      .text {
        font-size: 18px;
        font-weight: bold;
        margin-left: 8px;
        color: #322d2c;
        line-height: 40px;
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .back-btn {
        font-size: 16px;
        padding: 0 30px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        border: 1px solid #ff5186;
        cursor: pointer;
        img {
          margin-right: 10px;
          width: 16px;
          height: 14px;
        }
        &:hover {
          background: #ff729c !important;
          border: 1px solid #ff729c;
        }
        &:active {
          background: #ef356d !important;
          border: 1px solid #ef356d;
        }
      }
    }
  }
  .evaluation-detail-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 0;
    .panel-left {
      width: 65%;
      .detail-content {
        margin: 0 15px 0 0;
        .detail-card {
          .detail-question-wrapper {
            display: flex;
            .detail-question-sign {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #f5222d;
              line-height: 16px;
              margin: 14px 5px 0 0;
            }
            .detail-question-name {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #322d2c;
              line-height: 40px;
            }
          }
          .detail-answer-wrapper {
            margin: 0 0 0 21px;
            // 单选题
            .detail-answer {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #322d2c;
              line-height: 40px;
            }
            // nps
            .detail-nps-answer {
              .draw_slip {
                margin: 20px 0 0 32px;
                .draw_slip_top {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .draw_slip_line {
                    width: 446px;
                    height: 10px;
                    background: #f8f8f8;
                    border-radius: 30px;
                    position: relative;
                    span {
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      background: #ff5186;
                      border-radius: 30px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                  .draw_slip_num {
                    font-size: 14px;
                    color: #595961;
                    margin-left: 24px;
                  }
                }
                .draw_slip_index {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 8px;
                  width: 446px;
                  color: #9797a1;
                  font-size: 14px;
                }
              }
            }
            // 多选题
            .detail-multiple-answer {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #322d2c;
              line-height: 40px;
              span {
                display: inline-block;
                & + span {
                  margin: 0 0 0 28px;
                }
              }
            }
            // 排序题
            .detail-sort-answer {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #322d2c;
              line-height: 40px;
              span {
                display: inline-block;
                & + span {
                  margin: 0 28px 0 0;
                }
              }
            }
          }
        }
      }
    }

    .panel-right {
      color: #322d2c;
      padding: 24px;
      width: 35%;
      background: #f6f6f6;
      margin-top: 10px;
      .commentator-title-wrapper {
        display: flex;
        position: relative;
        .product-image {
          img {
            width: 116px;
            height: 116px;
          }
        }
        .commentator-title {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: 136px;
          .commentator-username {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 40px;
          }
          .commentator-product {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 28px;
          }
        }
      }
      .commentator-content {
        margin: 22px 0 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        word-break: break-all;
      }
      .commentator-intro {
        display: flex;
        justify-content: end;
        margin: 30px 0 0;
        align-items: center;
        flex-wrap: wrap;
        .commentator-intro-line {
          margin: 0 5px 0 0;
          //   height: 1px;
          //   border: 1px solid #130f0f;
        }
        .commentator-intro-option {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 32px;
          & + .commentator-intro-option {
            margin: 0 0 0 10px;
          }
        }
      }
      .commentator-image {
        margin: 20px auto;
        width: calc(100% - 10px);

        img {
          // width: fit-content;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
