@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.blank-info-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .blank-info-input {
    width: 446px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #e3e2e5;
    margin: 0 0 0 35px;
  }
}
