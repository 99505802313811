@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.loading-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: fixed;
  top: 38px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
  .loading-message-notice {
    padding: 8px;
    text-align: center;
    .loading-message-notice-content {
      display: inline-block;
      padding: 10px 16px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      pointer-events: all;
      .message-loading {
        display: flex;
        align-items: center;
        .message-loading-text {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  .lds-dual-ring {
    display: inline-block;
    // width: 42px;
    // height: 42px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 18px;
    height: 18px;
    margin: 0px;
    border-radius: 50%;
    border: 2px solid #ff5186;
    border-color: #ff5186 transparent #ff5186 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
