@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.detail-chart-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  .detail-chart-popup-container {
    background: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    width: 80%;
    height: 80%;
    .detail-chart-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .popup-content-top {
      display: flex;
      justify-content: space-between;
      padding: 30px 40px 0;
      align-items: center;
      .top-title-wrapper {
        font-family: PingFang SC;
        .main-title {
          font-size: 16px;
          color: #302e2f;
          font-weight: bold;
        }
        .sub-title {
          font-size: 16px;
          color: #302e2f;
          //   font-weight: bold;
        }
      }
      .search-wrapper {
        .ant-input-affix-wrapper {
          border-radius: 6px;
          border: 1px solid #e3e2e5;
          &:hover {
            border-color: #ff5186;
          }
          &:focus {
            border-color: #ff5186;
            box-shadow: none;
          }
        }
        .ant-input-affix-wrapper-focused {
          border-color: #ff5186;
          box-shadow: none;
        }
      }
    }
    .detail-chart-wrapper {
      height: calc(100% - 250px);
      margin: 0 20px;
      .detail-chart-wrapper-2 {
        width: 100%;
        height: 100%;
        .ant-tabs {
          // 切换里面
          width: 100%;
          height: 100%;
          .ant-tabs-nav {
            margin: 0 20px 16px;
          }
          .ant-tabs-content {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
