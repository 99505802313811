@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-config-container {
  width: 494px;
  margin-left: 40px;
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 13px;
  }
  .freecross-card-wrapper {
    min-height: 145px;
    & + .freecross-card-wrapper {
      margin-top: 26px;
    }
    &:last-child {
    }
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 30px rgba(190, 190, 190, 0.16);
    opacity: 1;
    border-radius: 7px;
    padding: 20px 34px;
    .freecross-card-title {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 24px;
      color: #000000;
      width: fit-content;
      position: relative;
      z-index: 0;
      margin: 0 0 25px 0;
      &:before {
        //用这两个伪类实现样式渲染
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 8px;
        background: linear-gradient(
          90deg,
          rgba(255, 103, 153, 0.48) 0%,
          #ffffff 100%
        );
        z-index: -1;
      }
    }
    .freecross-card-select {
      margin: 0 7px 0 13px;
      & + .freecross-card-select {
        margin-top: 18px;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        color: #322d2c;
      }
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        color: #9b9ba4 !important;
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #ff5186 !important;
        border-right-width: 1px !important;
        box-shadow: none;
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
      }
      .ant-select-open {
        .ant-select-selector {
          border-color: #ff5186 !important;
          box-shadow: none !important;
        }
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        font-size: 13px;
      }
      .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        font-size: 13px;
      }
      //age
      .ant-select.ant-select-multiple.ant-select-allow-clear.ant-select-show-search {
        width: 100%;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 9px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        transition: none;
        width: 100%;
        height: 38px !important;
        min-height: 32px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 13px;
        color: #9797a1;
      }
      .ant-select-multiple .ant-select-selection-item-content {
        font-size: 13px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        left: 16px;
      }
      //操作除了树标签品类
      .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
        width: 100%;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        border-radius: 5px;
        padding: 0 16px;
        border: 1px solid #d9d9d9;
        height: 38px !important;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        left: 16px;
      }
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item,
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 20px !important;
        line-height: 38px !important;
        font-size: 13px !important;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 38px !important;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 38px !important;
        line-height: 38px !important;
      }
    }
    .freecross-card-text {
      margin: 0 7px 0 13px;
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #302e2f;
    }
  }
}
