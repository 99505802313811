@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.brand-info-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .brand-info-popup-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand-info-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 720px;
    height: 80%;
    .brand-info-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .brand-info-popup-content {
    height: calc(100% - 160px);
    padding: 30px 30px 0;
    overflow: auto;
    .brand-user-wrapper {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 17px;
      margin: 0 0 16px 0;
      .brand-user-name {
        color: #9797a1;
      }
      .brand-user-id {
        color: #000000;
        margin: 0 8px 0 4px;
      }
      .brand-user-copy {
        color: #01b0f1;
      }
    }
    .card-item {
      margin-right: 45px;
      & + .card-item {
        margin-top: 13px;
      }
      .item-name {
        font-size: 13px;
        line-height: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        .item-sign {
          color: #ff5186;
          display: inline-block;
          width: 10px;
        }
      }
      .item-input-row {
        display: flex;
        justify-content: space-between;
        .item-input-wrapper {
          width: 100%;
          .item-input {
            height: 38px;
            border-radius: 5px;
          }
          & + .item-input-wrapper {
            margin-left: 13px;
          }
        }
      }
      .item-input-wrapper {
        margin: 10px 0 0;
        .item-icon {
          width: 15px;
          height: 15px;
          display: inline-block;
        }
        .item-input {
          width: 100%;
          height: 38px;
          border-radius: 5px;
        }
        .ant-btn {
          box-shadow: none;
        }
        button[ant-click-animating-without-extra-node]:after {
          border: 0 none;
          opacity: 0;
          animation: none 0 ease 0 1 normal;
        }
        .ant-btn:active {
          color: #fff;
          border-color: #f55186;
          background: #ff729c;
        }
        // .ant-btn:hover,
        // .ant-btn:focus {
        //   color: #40a9ff;
        //   border-color: #40a9ff;
        //   background: #fff;
        // }
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
          border-color: #f55186;
          border-right-width: 1px !important;
          z-index: 1;
        }
        .ant-input-affix-wrapper:hover {
          border-color: #f55186;
          border-right-width: 1px !important;
        }
        .ant-input-affix-wrapper {
          position: relative;
          display: inline-block;
          // width: 100%;
          min-width: 0;
          padding: 4px 11px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          line-height: 1.5715;
          background-color: #fff;
          background-image: none;
          border: 1px solid #e3e2e5;
          // border-radius: 6px;
          // transition: all 0.3s;
          transition: none;
          display: inline-flex;
        }
        .ant-input-affix-wrapper-disabled {
          color: rgba(0, 0, 0, 0.25);
          background-color: #f5f5f5;
          border-color: #d9d9d9;
          box-shadow: none;
          cursor: not-allowed;
          opacity: 1;
          &:hover {
            border-color: #d9d9d9;
          }
        }
        .ant-input-affix-wrapper-focused {
          border-color: #ff5186;
          box-shadow: none;
        }
        // 手机验证码
        .sms-input {
          width: calc(100% - 100px);
          height: 38px;
          border-radius: 5px 0 0 5px;
        }
        .sms-btn {
          background: #f55186;
          border-color: #ff5186;
          width: 100px;
          height: 38px;
          color: #fff;
          font-size: 14px;
          line-height: 14px;
          // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: none;
          border-radius: 0 5px 5px 0;
          &:hover {
            border-color: none;
            border-color: #ff729c;
            background: #ff729c;
          }
          .ant-btn:hover,
          .ant-btn:focus {
            color: #fff;
            border-color: #ff729c;
            background: #ff729c;
          }
        }
        // radio
        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
          border-color: #ff5186;
        }
        .ant-radio-checked .ant-radio-inner {
          border-color: #ff5186;
        }
        .ant-radio-inner::after {
          background-color: #ff5186;
          transition: none;
        }
        .ant-radio-input:focus + .ant-radio-inner {
          box-shadow: none;
        }
        .ant-radio-checked::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #ff5186;
          border-radius: 50%;
          visibility: hidden;
          -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          content: '';
        }
        // 下拉框
        .ant-select {
          width: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 5px;
          border: 1px solid #e3e2e5;
        }
        .select
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 38px;
          width: 100%;
        }
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item {
          color: #322d2c;
        }
        .select
          .ant-select-single.ant-select-show-arrow.ant-select-disabled
          .ant-select-selection-item {
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .card-check-wrapper {
        margin-top: 17px;
        .card-check {
          & + .card-check {
            margin-top: 16px;
          }
          display: flex;
          align-items: flex-end;
          .checkbox {
            height: 16px;
            width: 16px;
            height: 16px;
            margin-right: 11px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .checkbox-disabled {
            cursor: not-allowed;
          }
          .check-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 12px;
            color: #000000;
          }
        }
      }
      .card-image-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &::after {
          content: '';
          flex: auto;
        }
        .card-image-2 {
          margin-top: 16px;
          margin-right: 17px;
          width: 76px;
          height: 76px;
          border: 1px dashed rgba(167, 167, 167, 0.9);
          border-radius: 8px;
          .upload-pic-wrapper {
            position: relative;
            width: 76px;
            height: 76px;
            .upload-pic {
              position: absolute;
              top: 15px;
              border-radius: 7px;
              width: 27px;
              cursor: pointer;
              margin: 0 auto;
              left: 50%;
              transform: translateX(-50%);
            }
            .upload-pic-text {
              position: absolute;
              text-align: center;
              font-size: 9px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 13px;
              color: #6f7070;
              top: 47px;
              left: 50%;
              transform: translateX(-50%);
              width: 50px;
            }
          }
        }
        .card-image {
          margin-top: 16px;
          margin-right: 17px;
          position: relative;
          &:hover {
            .upload-close-img {
              display: block;
              position: absolute;
              top: -7px;
              right: -7px;
            }
          }
          .show-pic {
            border-radius: 7px;
            width: 100%;
            height: 100%;
          }
          //删除icon
          .upload-close-img {
            display: none;
            width: 16px;
            height: 16px;
            z-index: 5;
            cursor: pointer;
          }
        }
      }
      textarea {
        margin: 17px 0 24px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0px 2px 16px rgba(188, 188, 188, 0.16);
        outline: none;
        border: 1px solid rgba(245, 245, 245, 1);
        border-radius: 9px;
        box-sizing: border-box;
        font-size: 12px;
        padding: 16px 13px;
        width: 100%;
        min-height: 100px;
      }

      textarea:focus {
        border: 1px solid #ff5186;
        border-radius: 4px;
      }
      .card-textarea-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
        opacity: 1;
        cursor: not-allowed;
      }
    }
    .info-remark {
      margin-right: 45px;
      margin-bottom: 26px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 16px;
      color: #9797a1;
      opacity: 1;
      .info-remark-blue {
        color: #01b0f1;
      }
    }
  }

  .tag-btn {
    padding: 23px 0 24px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #e3e2e5;

    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f55186;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ffffff;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #ff729c;
        border: 1px solid #ff729c;
      }
      &:active {
        background: #ff729c;
        border: 1px solid #ff729c;
      }
    }
    .tag-btn-pending {
      cursor: not-allowed !important;
      background: #ff729c !important;
      border: 1px solid #ff729c !important;
    }
  }
}
