@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.download-pic-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.45);
}
.download-pic-main {
  width: 400px;
  padding: 24px 24px;
  background: #fff;
  border-radius: 7px;
  overflow: auto;
  //   position: relative;
  .download-pic-top {
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    align-items: center;
    &-title {
      color: #322d2c;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
    &-close {
      cursor: pointer;
      width: 10px;
      height: 10px;
    }
  }
  .download-pic-body {
    // border: 1px solid red;
    width: 200px;
    margin: 24px auto 20px;
    img {
      width: 100%;
      width: 100%;
    }
  }
  .download-pic-foot {
    // border: 1px solid red;
    // margin: 0 auto;
    .download-btn {
      background: #fff1f6;
      border-radius: 10px;
      border: 1px solid #ff5186;
      width: 90px;
      height: 38px;
      text-align: center;
      line-height: 36px;
      font-weight: 500;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 14px;
      cursor: pointer;
      margin: 0 auto;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
  }
}
