@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.Browse_main {
  box-sizing: border-box;
  /* position: absolute; */
  /* top: 14px; */
  /* left: 5%; */
  /* background: #fff; */
  width: 427px;
  margin: 0 auto;
}

.product_img_nav {
  width: 100%;
}

.product_img_banner {
  width: 100%;
}

.goods {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(183, 183, 183, 0.2);
  border-radius: 7px 7px;
  /*position: relative;*/
  /*overflow: auto;*/
  /*height: 486px;*/
  box-sizing: border-box;
}

.goods_content {
  padding: 23px 18px;
  box-sizing: border-box;
  box-shadow: 0px 5px 11px 0px rgba(183, 183, 183, 0.2);
}

.goods_name {
  font-size: 18px;
  margin-bottom: 14px;
  color: #999;
}

.goods_content .score {
  margin-bottom: 16px;
  justify-content: flex-start;
  align-items: center;
}

.goods_content .score img {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.goods_content .score .num {
  color: #fb7d4d;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  vertical-align: 0.14px;
}

.goods_content .desc {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
}

.comments {
  padding: 26px 19px;
  background: #fafafa;
  border-radius: 7px;
}

.comments .font-style {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.comments .line {
  display: inline-block;
  width: 0.28px;
  height: 14px;
  background: #fb7d4d;
  /*vertical-align: -5rpx;*/
  margin-right: 7px;
}

.comments .comments_name {
  color: #999;
  margin-top: 8px;
  font-size: 14px;
  p {
    word-wrap: break-word;
  }
}

.comments .comments_price {
  color: #999;
  margin-top: 14px;
  font-size: 18px;
}

.goods .foot {
  /*position: fixed;*/
  /*top: 414px;*/
  /*width: 27%;*/
  height: 86px;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 19px;
}

.foot .pl_btn img {
  width: 24px;
  height: 24px;
}

.pl_btn span {
  font-size: 14px;
  display: block;
  margin-top: 4px;
  color: #999;
}

.goods .foot .sumbit {
  background: #fc7c4b;
  font-weight: 500;
  color: #ffffff;
  border-radius: 28px;
  width: 324px;
  height: 50px;
  /*margin: 14px auto;*/
  font-size: 18px;
  line-height: 50px;
}

/*.goods::-webkit-scrollbar {*/
/*    width: 3px;*/
/*    height: 1px;*/
/*}*/

/*.goods::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
/*    background: rgba(0,0,0,0.1);*/

/*}*/

/*.goods::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
/*    border-radius: 10px;*/
/*    background: #EDEDED;*/
/*}*/

.view_imgs {
  max-width: 100%;
  overflow: hidden;
}

.slick-slide {
  display: inline-block;
}

.slick-dots {
  display: none !important;
}

.slick-slider button {
  display: none !important;
}
