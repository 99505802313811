@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-pannel-card-custom {
  width: 100%;
  .card-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px 14px 0;
    .title-left {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #322d2c;
      line-height: 32px;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // word-break: keep-all;
    }
  }
  .chart-operaion-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px 0 0px;
    .chart-operaion-text {
      color: #595961;
      cursor: pointer;
      &:hover {
        color: #ff5186;
      }
    }
  }
}
.custom-chart-father {
  position: relative;
  height: calc(100% - 0px);
  margin: 0;
  .chart-wrapper-2 {
    width: 100%;
    height: 100%;
  }
  .custom-echart {
    width: 100%;
    height: 360px;
    margin: 0 0 0px;
  }
  .custom-chart-foot {
    // border: 1px solid green;
    width: calc(100% - 60px);
    height: 80px;
    display: flex;
    position: absolute;
    left: 50px;
    .foot-name {
      font-size: 14px;
      .line {
        border: 2px solid orange;
        border-top-color: transparent;
        border-radius: 2px;
        height: 8px;
        margin: 0 20px;
      }
      .sign {
        width: 2px;
        height: 4px;
        background-color: orange;
        margin: 0 auto;
      }
      .content {
        color: #595961;
        margin: 3px 0 0;
      }
    }
    .foot-name-0 {
      position: absolute;
      //   width: 60%;
      text-align: center;
    }
    .foot-name-1 {
      position: absolute;
      //   width: 20%;
      //   right: 20%;
      text-align: center;
    }
    .foot-name-2 {
      position: absolute;
      //   width: 20%;
      //   right: 0;
      text-align: center;
    }
  }
}
