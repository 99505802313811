.QuestionnaireTamplate{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    position: fixed;
    top:0;
    left:0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

}
.QuestionnaireTamplate_contain{
    width: 41.111vw;
    background: #fff;
    border-radius: 0.5vw;
}
.update_Flex{
    display: flex;
    justify-content: space-between !important;
    padding-bottom: 1vw !important;
}
.update_Flex img{
    margin: 0 !important;
    width: 0.694vw !important;
    height: 0.694vw !important;
}
.tamplate_contain{
    padding: 0 1.666vw 1.388vw;
    box-sizing: border-box;
}
.tamplate_item{
    display:flex;
    justify-content: left;
    padding-bottom: 1.666vw;
    /*width: 40vw;*/
}
.tamplate_item .tamplate_left{
    width: 6vw;
    height: 2.638vw;
    line-height: 2.638vw;
    font-size: 0.972vw;
    color: #595961;
}
.tamplate_item .tamplate_right{
    width: 23.194vw;
    height: 2.638vw !important;
    /*width: 100%;*/
    box-sizing: border-box;
    padding: 0 0 0 1vw;
}
.tamplate_item .tamplate_select{
    position: relative;
    cursor: pointer;
}
.tamplate_select .cur_icon_select{
    width: 0.694vw;
    height: 0.416vw;
    position: absolute;
    top:1vw;
    right: 1vw;
}
.tamplate_select ul{
    width: 23.194vw;
    position: absolute;
    left: 0;
    top: 2.7vw;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 0px rgba(238, 238, 238, 0.5);
    border-radius: 6px;
    z-index: 12;
}
.tamplate_select ul li{
    height: 2.638vw;
    line-height: 2.638vw;
    font-size: 0.972vw;
    padding: 0 1.111vw;
    box-sizing: border-box;
    cursor: pointer;
}
.tamplate_select ul li:hover{
    background: rgba(255, 81, 134, 0.04) url("../../../../images/cur_icon_choice.png") no-repeat 95% center;
    background-size: 4%;
    color: #FF5186;
}

.tamplate_btn{
    /*margin-top: 1.666vw;*/
    display: flex;
    justify-content: flex-end;
}
.tamplate_btn div:nth-child(1){
    font-size: 0.972vw;
    width: 6.25vw;
    height: 2.638vw;
    border-radius: 0.5vw;
    border: 1px solid #E3E2E5;
    line-height:2.638vw;
    text-align: center;
    cursor: pointer;
}
.tamplate_btn div:nth-child(2){
    font-size: 0.972vw;
    width: 6.25vw;
    height: 2.638vw;
    border-radius: 0.5vw;
    border: 1px solid #FF5186;
    background: #FF5186;
    line-height:2.638vw;
    text-align: center;
    margin-left: 1.111vw;
    color: #fff;
    cursor: pointer;
}