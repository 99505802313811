@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.overall-container {
  // 每个div的title
  @mixin sub-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 22px;
    color: #302e2f;
  }
  /* 进度条区 */
  .progress-box {
    padding: 20px 20%;
    width: 100%;
    min-width: 1120px;
    background-color: #fff;
    margin-bottom: 20px;
    max-height: 300px;
    overflow: hidden;
    padding-right: calc(20% + 6px);
    &:hover{
      overflow: auto;
      padding-right: 20%;
    }
  }
  /* 顶部样式 */
  .overall-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-right {
      font-size: 16px;
      padding: 11px 14px;
      height: 44px;
      line-height: 22px;
      text-align: center;
      background: #ff5186;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 400;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 16px;
        height: 16px;
      }
    }
  }
  // 派送行
  .dispatch-row {
    display: flex;
    margin: 11px 0 0;
    min-width: 1120px;
    .dispatch-left-card {
      width: 65%;
      flex: 1 1;
      background: #fff;
      .dispatch-tag-wrapper {
        width: calc(100% - 40px);
        margin: 23px 20px;
        display: flex;
        justify-content: space-between;
        .dispatch-tag-list {
          // flex: 1;
          width: 32%;
          height: 94px;
          position: relative;
          // & + .dispatch-tag-list {
          //   margin-left: 32px;
          // }
          &:nth-child(1) {
            background: url('../../images/overall_data/overall_icon_tag1.png');
            background-size: cover;
            background-position: 80% center;
            border-radius: 10px;
          }
          &:nth-child(2) {
            background: url('../../images/overall_data/overall_icon_tag2.png');
            background-size: cover;
            background-position: 80% center;
            border-radius: 10px;
          }
          &:nth-child(3) {
            background: url('../../images/overall_data/overall_icon_tag3.png');
            background-size: cover;
            background-position: 80% center;
            border-radius: 10px;
          }
          .content {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: 20px;
            left: 10px;
            div {
              &:nth-child(1) {
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 17px;
                color: #ffffff;
                margin-bottom: 12px;
              }
              &:nth-child(2) {
                font-size: 25px;
                font-family: Bahnschrift;
                font-weight: 400;
                line-height: 25px;
                color: #ffffff;
              }
            }
          }
        }
      }
      .card-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 0 30px;
        .title-left {
          @include sub-title;
        }
      }
      .date-echart {
        height: 314px;
        padding: 0 0 10px;
      }
    }
    .dispatch-right-card {
      min-width: 27%;
      margin-left: 16px;
      background: #fff;
      border-radius: 5px;
      padding: 23px 23px;
      .card-title {
        @include sub-title;
      }
      .dispatch-intro-wrapper {
        .dispatch-intro-list {
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          padding: 30px 0;
          width: 100%;
          border-top: 1px dashed #e3e2e5;
          &:first-child {
            padding: 23px 0 30px;
            border-top: none;
          }
          div {
            margin: 9px 0 0 16px;
          }
          img {
            width: 63px;
            height: 63px;
          }
          span {
            &:nth-child(1) {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #9797a1;
              line-height: 16px;
              margin: 0 0 8px 0;
              display: block;
            }
            &:nth-child(2) {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #302e2f;
              line-height: 22px;
              display: block;
            }
          }
          & + .list {
            margin-top: 20px;
          }
        }
        .list-report {
          margin-top: 0px;
          font-size: 14px;
          padding: 15px 5px;
          height: 44px;
          line-height: 20px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .overall-data-chart-container {
    display: unset;
    min-width: 1120px;
    .overall-desire-container {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: unset;
      .panel-card-desire-single {
        flex: 1;
        height: 450px;
        max-width: 32.8%;
        & + .panel-card-desire-single {
          margin-left: 16px;
        }
      }
    }
    .overall-desire-container-2 {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: unset;
      margin-left: 0;
      justify-content: unset;
      .panel-card-desire-single {
        flex: 1;
        height: 450px;
        max-width: 32.8%;
        width: unset;
        & + .panel-card-desire-single {
          margin-left: 16px;
        }
      }
    }
    .overall-dispatch-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: unset;
      margin-left: 0;
      .panel-card-dispatch-single {
        flex: 1;
        height: 450px;
        max-width: 32.8%;
        & + .panel-card-dispatch-single {
          margin-left: 16px;
        }
        .dispatch-single-echart {
          padding: 0 30px 0;
          width: 100%;
          height: 380px;
        }
      }
    }
    .overall-dispatch-container-2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: unset;
      margin-left: 0;
      justify-content: unset;
      .panel-card-dispatch-single {
        flex: 1;
        height: 450px;
        max-width: 32.8%;
        width: unset;
        & + .panel-card-dispatch-single {
          margin-left: 20px;
        }
        .dispatch-single-echart {
          padding: 0 30px 0;
          width: 100%;
          height: 380px;
        }
      }
    }
  }
  .like-row {
    margin-top: 16px;
    background: #fff;
    border-radius: 5px;
    padding: 16px 16px 16px;
    min-width: 1120px;
  }

  .filter-chart-container {
    margin-top: 16px;
    background: #fff;
    min-width: 1120px;
    // position: relative;
    .filter-container-fixed {
      margin: 0;
      height: 98px;
      // border: 1px solid green;
      background: #fff;
      z-index: 90;
      padding: 30px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: unset;
      // min-width: fit-content;
      .download-wrapper {
        min-width: 40px;
        font-size: 16px;
        padding: 11px 5px;
        height: 44px;
        line-height: 22px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-left: 8px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .chart-container {
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      flex-wrap: wrap;
      padding: 30px 30px 0;
      .batch-download-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 15px;
        .batch-download-btn {
          font-size: 16px;
          padding: 0 20px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          border: 1px solid #ff5186;
          cursor: pointer;
          min-width: 100px;
        }
      }
      &:after {
        content: '';
        width: 33%;
      }
      .rank-content-empty {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .overall-container {
    .overall-data-chart-container {
      display: flex !important;
      .overall-desire-container {
        display: unset !important;
        width: 49.5% !important;
        .panel-card-desire-single {
          flex: none !important;
          height: 400px;
          max-width: 100% !important;
          & + .panel-card-desire-single {
            margin-left: 0 !important;
          }
        }
      }
      .overall-desire-container-2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: unset;
        margin-left: 0;
        justify-content: space-between;
        .panel-card-desire-single {
          flex: unset;
          height: 450px;
          max-width: unset;
          width: 49%;
          & + .panel-card-desire-single {
            margin-left: 16px;
          }
          &:last-child {
            margin-left: 0px;
            width: 100%;
          }
        }
      }
      .overall-dispatch-container {
        display: unset !important;
        width: 49.5% !important;
        margin-left: 16px !important;
        .panel-card-dispatch-single {
          flex: none !important;
          height: 400px;
          max-width: 100% !important;
          & + .panel-card-dispatch-single {
            margin-left: 0 !important;
          }
          .dispatch-single-echart {
            padding: 0 30px 0;
            width: 100%;
            height: 360px;
          }
        }
      }
      .overall-dispatch-container-2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: unset;
        margin-left: 0;
        justify-content: space-between;
        .panel-card-dispatch-single {
          flex: unset;
          height: 450px;
          max-width: unset;
          width: 48.9%;

          & + .panel-card-dispatch-single {
            margin-left: 20px;
          }
          &:last-child {
            margin-left: 0px;
            width: 100%;
          }
          .dispatch-single-echart {
            padding: 0 30px 0;
            width: 100%;
            height: 380px;
          }
        }
      }
    }

    .filter-chart-container {
      margin-top: 16px;
      background: #fff;
      min-width: 1120px;
      // position: relative;
      .filter-container-fixed {
        margin: 0;
        height: 98px;
        // border: 1px solid green;
        background: #fff;
        z-index: 90;
        padding: 30px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: unset;
        // min-width: fit-content;
        .download-wrapper {
          min-width: 40px;
          font-size: 16px;
          padding: 11px 5px;
          height: 44px;
          line-height: 22px;
          text-align: center;
          background: #ff5186;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 6px;
          font-family: PingFang SC;
          font-weight: 400;
          margin-left: 8px;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .chart-container {
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        flex-wrap: wrap;
        padding: 30px 30px 0;
        &:after {
          content: '';
          width: 33%;
        }
      }
    }
  }
  .chart-container {
    justify-content: space-between;
  }
}
