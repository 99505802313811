@function vw($args) {
  @return $args/1440 * 100 + vw;
}

// 弹窗
.dialog_flag {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;

  .dialog_main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    width: 400px;

    .dialog_main_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #cccccc;

      div {
        width: 20px;
        height: 20px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          float: left;
        }
      }
    }

    .dialog_main_foot {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;

      button {
        width: 100px;
        height: 40px;
        border: 1px solid #ff5186;
        background: #ff518622;
        color: #ff5186;
        font-size: 16px;
        border-radius: 5px;
        margin-left: 20px;
        cursor: pointer;

        &:last-child {
          background: #ff5186;
          color: #FFFFFF;
        }

        &:hover {
          opacity: .8;
        }
      }
    }

    .dialog_main_content {
      padding: 20px;

      .item {
        font-size: 14px;
        color: #333333;
        height: 20px;
        margin: 20px 0;

        span {
          display: inline-block;
          width: 100px;
        }

        input {
          padding: 5px;
          width: 200px;
        }
      }
    }
  }
}