@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-item{
  width: 100%;
  margin-top: 30px;
  
  .question-item-echart{
    margin-bottom: 40px;
    height: 360px;
    background-color: #fff;
    .echarts{
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.39);
      border-radius: 7px;
    }
  }
  .question-item-table {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 40PX;
    table {
      width: 100%;
    }
  }
}