@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.pannel-card-custom {
  width: 48.5%;
  position: relative;
  .card-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 15px 14px 0;
    .title-left-wrapper {
      .title-left {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #322d2c;
        line-height: 22px;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-right: 10px;
        // word-break: keep-all;
      }
      .question-hover-card {
        min-width: 200px;
        border: 1px solid #e35d86;
        position: absolute;
        top: 0px;
        background: #fff;
        z-index: 90;
        padding: 10px;
        .question-hover-card-arrow {
          width: 10px;
          height: 10px;
          border-top: 1px solid #e35d86;
          border-right: 1px solid #e35d86;
          position: absolute;
          left: 50%;
          bottom: -6px;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          background: #fff;
          z-index: 20;
        }
        .question-hover-card-name {
          font-size: 12px;
        }
        .question-hover-card-img {
          width: 100%;
          height: auto;
          margin: 10px auto 0;
          padding: 0 20px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
      }
    }
    .title-right-pic {
      display: flex;
      width: 22px;
      cursor: pointer;
      &:hover {
        content: url('../../images/overall_data/pic_enlarge_active.png');
      }
    }
  }
  .chart-operaion-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 40px 0 20px;
    .chart-change-wrapper {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      .chart-change-sign {
        margin: 0 5px;
        color: #9797a1;
      }
      .chart-change-text {
        color: #595961;
        cursor: pointer;
        &:hover {
          color: #ff5186;
        }
      }
      .chart-change-text-selected {
        color: #ff5186;
      }
    }
  }
}
.custom-chart-father {
  position: relative;
  height: calc(100% - 0px);
  margin: 0;
  .chart-wrapper-2 {
    width: 100%;
    height: 100%;
  }
  .custom-echart {
    width: 100%;
    height: 360px;
    margin: 0 0 80px;
  }
  .custom-chart-foot {
    // border: 1px solid green;
    width: calc(100% - 60px);
    height: 80px;
    display: flex;
    position: absolute;
    left: 50px;
    .foot-name {
      font-size: 14px;
      .line {
        border: 2px solid orange;
        border-top-color: transparent;
        border-radius: 2px;
        height: 8px;
        margin: 0 20px;
      }
      .sign {
        width: 2px;
        height: 4px;
        background-color: orange;
        margin: 0 auto;
      }
      .content {
        color: #595961;
        margin: 3px 0 0;
      }
    }
    .foot-name-0 {
      position: absolute;
      //   width: 60%;
      text-align: center;
    }
    .foot-name-1 {
      position: absolute;
      //   width: 20%;
      //   right: 20%;
      text-align: center;
    }
    .foot-name-2 {
      position: absolute;
      //   width: 20%;
      //   right: 0;
      text-align: center;
    }
  }
}

@media screen and (min-width: 1440px) {
  .pannel-card-custom {
    width: 33%;
  }
}
