@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.matrix-single-template-info {
  margin-top: 10px;
  min-width: 400px;
  .matrix-score-column-answer {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    color: #322d2c;
    font-size: 14px;
    margin: 20px 0 0 0px; //150+15
    width: 100%;
    .matrix-score-column-answer-left {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 120px);
      margin-left: 120px;
      .draw-slip-row {
        flex: 1;
        text-align: center;
        align-items: center;
        .edit-question-title-wrap {
          position: relative;
          :hover {
            border: 1px dashed transparent;
          }
          .edit-question-title {
            width: unset;
            word-break: break-all;
          }
        }
        .upload-question-pic {
          .show-pic-wrap {
            margin: 0 auto;
            padding-left: 13px; // 防止删除按钮
          }
          img {
            width: 24px;
            height: 20px;
            vertical-align: baseline;
          }
        }
      }
    }
  }
  .matrix-score-row-answer {
    color: #322d2c;
    .draw-slip {
      margin: 10px 0 0 0;
      display: flex;
      border: 1px solid transparent;
      align-items: center;
      width: 100%;
      .draw-slip-left {
        align-items: center;
        display: flex;
        .draw-slip-title {
          align-items: center;
          display: flex;
          width: 120px;
          .edit-question-title-wrap {
            margin: 0 5px 0 0;
            word-break: break-all;
            :hover {
              border: 1px dashed transparent;
            }
          }
          .upload-question-pic {
            .show-pic-wrap {
              margin: 0 auto;
              padding-left: 13px; // 防止删除按钮
            }
            img {
              width: 24px;
              height: 20px;
              vertical-align: baseline;
            }
          }
        }
        .draw-slip-index {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 120px);
          color: #9797a1;
          font-size: 14px;
          .grade-item {
            display: flex;
            // justify-content: flex-start;
            // align-items: center;
            width: 100%;
            img {
              width: 16px;
              height: 16px;
              display: block;
              margin: 0 auto;
            }
            span {
              color: #595961;
              font-size: 14px;
            }
          }
          .grade-item-2 {
            display: flex;
            width: 100%;
            height: 28px;
            background: rgba(255, 255, 255, 0.39);
            border: 1px solid #dbdbdb;
            margin: 0 10px;
          }
        }
      }
      .draw-slip-left-border {
        border: 1px solid transparent;
        width: 100%;
      }
    }
  }
}
