@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.blank-list-container {
  padding: 16px 20px 24px 10px;
  width: 100%;
  .search-wrapper {
    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #e3e2e5;
      &:hover {
        border-color: #ff5186;
      }
      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }
  }
  .ant-table-body {
    overflow-y: auto !important;
  }
  // .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  //   background: transparent;
  //   border-color: transparent;
  // }
  .ant-pagination-item:hover {
    border-color: #f57a9f !important;
    background: #f57a9f !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #ff5186;
  }
  .ant-table-pagination.ant-pagination {
    margin: 10px 0 0 !important;
    // width: max-content;
  }
  .ant-table-tbody > tr.ant-table-row > td:last-child span:nth-child(1) {
    color: #fff;
    cursor: pointer;
  }
}
