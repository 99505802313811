@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.nps-info-container {
  margin: 0 0 0 21px;
  .draw_slip {
    margin: 20px 0 0 12px;
    .draw_slip_top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .draw_slip_line {
        width: 446px;
        height: 10px;
        background: #f8f8f8;
        border-radius: 30px;
        position: relative;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #ff5186;
          border-radius: 30px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .draw_slip_num {
        font-size: 14px;
        color: #595961;
        margin-left: 24px;
      }
    }
    .draw_slip_index {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      width: 446px;
      color: #9797a1;
      font-size: 14px;
    }
    .draw-slip-foot {
      color: #322d2c;
      display: flex;
      width: 446px;
      justify-content: space-between;
      font-size: 14px;
      margin: 10px 0 0;
      .draw-slip-foot-name {
        max-width: 160px;
      }
    }
  }
}
