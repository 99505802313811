@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-ai-generation-container {
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 13px;
    // line-height: 38px;
  }
  .question-ai-generation {
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 2px 9px rgba(167, 167, 167, 0.08);
    opacity: 1;
    border-radius: 5px;
    margin: 13px;
    padding: 0 0 20px;

    .question-prompt-card {
      background: rgba(250, 250, 250, 1);
      border-radius: 5px;
      padding: 16px;
      // display: flex;
      line-height: 30px;
      .prompt-fill {
        margin: 0 4px 0px;
        padding: 0 2px;
        border-bottom: 1px solid #595961;
        // display: inline-block;
        min-width: 50px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        .prompt-fill-empty {
          color: #999999;
        }
        &:hover {
          color: #f55186;
          border-bottom: 1px solid #f55186;
          .prompt-fill-empty {
            color: #f55186;
          }
        }
      }
      .prompt-fill-active {
        color: #f55186;
        border-bottom: 1px solid #f55186;
        .prompt-fill-empty {
          color: #f55186;
        }
      }
      .prompt-fill-icon {
        border: 1px solid #c3c3c3;
        color: #c3c3c3;
        border-radius: 2px;
        padding: 0 2px;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        width: 15px;
        & + .prompt-fill-icon {
          margin-left: 9px;
        }
        cursor: pointer;
        &:hover {
          border: 1px solid #f55186;
          color: #f55186;
        }
      }
    }
    .question-prompt-fill-card {
      margin: 20px 0 0;
      .question-prompt-fill-wrapper {
        margin: 0 11px;
        display: flex;
        .question-prompt-fill-name {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
          margin-right: 10px;
          min-width: 30px;
          align-items: center;
          display: flex;
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          border-color: #ff5186;
          box-shadow: none;
          border-right-width: 1px !important;
          outline: 0;
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: #ff5186;
          border-right-width: 1px !important;
        }
        .ant-select-selector {
          height: 38px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: #fff;
          border: 1px solid #ebebeb;
          border-radius: 4px;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        .select {
          width: 100%;
        }
        .ant-select {
          width: 100%;
        }
        .select
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          width: 100%;
          height: 38px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 18px;
          padding-top: 3px;
        }
        .ant-select-single .ant-select-selector .ant-select-selection-item,
        .ant-select-single
          .ant-select-selector
          .ant-select-selection-placeholder {
          padding: 0;
          line-height: 38px;
          transition: all 0.3s;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
          .ant-select-selector
          input {
          cursor: auto;
          line-height: 36px;
          height: 36px;
        }
        .ant-select-multiple .ant-select-selector {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 0px 4px 1px;
        }
        .tree-select-popup {
          //操作树的选中样式
          .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
            background-color: rgba(255, 81, 134, 0.04) !important;
            color: #ff5186 !important;
          }
          .ant-select-tree
            .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
            background-color: rgba(255, 81, 134, 0.1) !important;
            color: #ff5186 !important;
          }
        }
        .edit-question-title-ai {
          border: 1px solid #ebebeb;
          border-radius: 4px;
          width: 100%;
          padding: 10px 17px;
          min-height: 38px;
          line-height: 18px;
        }
        p {
          /* 去除点击p标签后的原生黄色边框 */
          outline: none;
        }
        p:empty::before {
          content: attr(placeholder);
          color: #999999;
          font-size: 13px;
          font-family: PingFang SC;
        }
      }
      .question-prompt-fill-wrapper-multiple {
        .ant-select-selector {
          height: auto;
        }
      }
    }
    .question-prompt-operaion {
      width: 150px;
      display: flex;
      justify-content: center;
      margin: 20px auto 0;
      background: #ff5186;
      border-radius: 6px;
      border: 1px solid #ff5186;
      padding: 11px 17px;
      color: #fff;
      font-size: 13px;
      line-height: 17px;
      cursor: pointer;
      &:hover {
        background: #ff729c !important;
        border: 1px solid #ff729c;
      }
      &:active {
        background: #ef356d !important;
        border: 1px solid #ef356d;
      }
    }
    .question-prompt-operaion-loading {
      background: #ff729c;
      border: 1px solid #ff729c;
      cursor: not-allowed;
      &:active {
        background: #ff729c !important;
        border: 1px solid #ff729c;
      }
    }
  }
  .question-ai-template-panel {
    padding: 20px 40px 20px;
  }
}
