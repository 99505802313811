@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.panel-card-desire-single {
  background-color: #fff;
  border-radius: 5px;
  margin: 16px 0 0;
  box-sizing: border-box;
  // flex: 1;
  // width: 100%;
  .card-title-wrapper {
    padding: 16px 23px 10px;
    .card-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 22px;
      color: #302e2f;
    }
    .card-select-wrapper {
      display: flex;
      margin-top: 4px;
      div {
        & + div {
          margin-left: 12.34%;
        }
        .card-select-title {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 17px;
          color: #595961;
          display: inline-block;
        }
        .card-select-content {
          margin-left: 10px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 16px;
          color: #302e2f;
          display: inline-block;
        }
      }
    }
  }
  .desire-job-echart {
    padding: 0 30px 0;
    width: 100%;
    height: 340px;
  }
}
