@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.panel-card-detail-single {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  margin: 16px 0 0;
  box-sizing: border-box;
  // flex: 1;
  // width: 100%;
  //   .ant-tabs {
  //     width: 100%;
  //     height: 100%;
  //   }
  .card-title-wrapper {
    padding: 16px 23px 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 22px;
    color: #302e2f;
  }
  .dispatch-single-echart {
    // padding: 0 30px 0;
    width: 100%;
    height: 100%;
    // height: 340px;
  }
}
