@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.baseinfo-tab-container {
  // height: calc(100% - 50px);
  .ant-tabs {
    height: 100%;
    overflow: hidden;
  }
  // 表情切换
  .ant-tabs-tab {
    padding: 24px 0;
    margin: 0 64px 0 0;
    & + .ant-tabs-tab {
      margin: 0;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ff5186;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595961;
    line-height: 26px;
    &:hover {
      color: #ff5186;
    }
  }
  .ant-tabs-ink-bar {
    background: #ff5186;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}
