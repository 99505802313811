@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.sankey-container {
  // width: 100%;
  // margin-top: 55px;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;
  overflow-x: auto;

  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 13px;
  }
  .sankey-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 1200px;
    background: #fff;
    padding: 38px 38px 38px 23px;
    .sankey-main-top {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .sankey-main-top-title{
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        margin-right: 16px;
        min-width: 64px;
      }
      .sankey-main-top-select {
        margin-right: 20px;
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          width: 314px;
          height: 30px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 5px;
        }
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .select
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 20px !important;
          line-height: 30px !important;
          font-size: 14px !important;
        }
        .select
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector
          .ant-select-selection-search-input {
          height: 30px !important;
          line-height: 30px !important;
        }
      }
    }
    .sankey-container{
      width: 100%;
      height: 100%;
    }
  }
}