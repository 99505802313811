@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-info-container {
  width: calc(100% - 494px);
  min-width: 600px !important;
  box-sizing: border-box;
  .freecross-info-top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .freecross-info-title {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 17px;
      color: #000000;
      margin-right: 16px;
      min-width: 64px;
    }
    .freecross-info-select {
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 314px;
        height: 30px !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 5px;
      }
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item,
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 20px !important;
        line-height: 30px !important;
        font-size: 14px !important;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 30px !important;
        line-height: 30px !important;
      }
    }
  }
  .freecross-info-empty {
    // justify-content: center;
    // display: flex;
    margin-top: 20%;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 31px;
    color: #1f2128;
    text-align: center;
    img {
      display: block;
      width: 490px;
      margin: 20% auto 40px;
    }
  }
  .freecross-info-tables {
    min-width: 600px !important;
    box-sizing: border-box;
    .freecross-info-table {
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
      border-radius: 5px;
      padding: 32px 23px;
      .freecross-info-table-name-wrapper {
        display: flex;
        justify-content: space-between;
        .freecross-info-table-name {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 20px;
          color: #000000;
          position: relative;
          padding-left: 10px;
          z-index: 0;
          &:before {
            //用这两个伪类实现样式渲染
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 3px;
            left: 0px;
            width: 5px;
            height: 14px;
            background: rgba(245, 81, 134, 1);
            opacity: 1;
            border-radius: 2px;
            z-index: -1;
          }
        }
        .operator-wrapper {
          .operator-text {
            color: #595961;
            cursor: pointer;
            &:hover {
              color: #ff5186;
            }
          }
        }
      }
      .freecross-info-table-wrapper {
        margin-top: 30px;
        .ant-table-tbody > tr:nth-child(even) {
          background: #fff;
        }
        .ant-table-tbody > tr:nth-child(odd) {
          background: #f8f8f8;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td,
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
          padding: 12px 4px !important;
        }
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background: rgba(0, 0, 0, 0.04);
          &:last-child {
            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
