@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.incentive-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .tag-btn {
    margin: 50px 0 30px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
.incentive-popup-contain {
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  width: 580px;
}
.incentive-popup-top {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e3e2e5;
  .popup-top-title {
    display: flex;
    margin: 0 auto;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 28px;
  }
  .popup-delete-icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
.incentive-popup-input {
  margin: 40px 0 0 56px;
  display: flex;
  .custom-input-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 52px;
    color: #302e2f;
  }
  .custom-input-wrapper {
    border: 1px solid rgba(195, 195, 195, 1);
    display: flex;
    border-radius: 5px;
    margin: 0 0 0 16px;
    .input-icon-wrapper {
      width: 38px;
      height: 50px;
      background: rgba(235, 235, 235, 0.39);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .input-icon {
        width: 18px;
        height: 18px;
        font-size: 16px;
        border: 1px solid rgba(195, 195, 195, 1);
        color: rgba(195, 195, 195, 1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:first-child {
        border-right: 1px solid rgba(235, 235, 235, 0.39);
      }
      &:last-child {
        border-left: 1px solid rgba(235, 235, 235, 0.39);
      }
    }
    .custom-input {
      text-align: center;
      border: none;
      width: 158px;
    }
    &:hover {
      border: 1px solid #e35d86;
      .input-icon-wrapper {
        background: rgba(245, 81, 134, 0.04);
        .input-icon {
          border: 1px solid #e35d86;
          color: #e35d86;
        }
        &:first-child {
          border-right: 1px solid rgba(235, 235, 235, 0.39);
        }
        &:last-child {
          border-left: 1px solid rgba(235, 235, 235, 0.39);
        }
      }
    }
  }
}
