@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.home-container {
  // width: 100%;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;
  // position: absolute;
  // height: calc(100vh - 55px);
  // margin-top: 55px;
  // padding-left: 204px;
  .home {
    // height: 100%;
    min-height: -webkit-fill-available;
    min-width: 1200px;
  }
  .top-row {
    display: flex;
    margin-bottom: 16px;
    .swiper-card-wrapper {
      width: 65%;
      flex: 1;
      // border: 1px solid red;
      .swiper {
        // width: 100%;
        .swiper-slide {
          // width: 100%;
          .swiper-card {
            cursor: pointer;
            width: 100%;
            height: 336px;
            position: relative;
            .swiper-bg {
              img {
                width: 100%;
                height: 336px;
                object-fit: cover;
                display: inline-block;
              }
            }
            .swiper-content-wrapper {
              position: absolute;
              transform: translateY(-50%);
              top: 50%;
              left: 60px;
              .swiper-title {
                font-size: 31px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 44px;
                color: #ffffff;
              }
              .swiper-content {
                margin-top: 20px;
                width: 460px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 18px;
                color: #ffffff;
                margin-bottom: 25px;
              }
              .swiper-btn {
                width: 460px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 20px;
                color: #ffffff;
                cursor: pointer;
                a {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    .chart-card {
      min-width: 32.2%;
      margin-left: 16px;
      background: #fff;
      border-radius: 5px;
      padding: 16px;
      height: 336px;
      .chart-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 22px;
        color: #302e2f;
        margin-left: 8px;
      }
      .wish-chart-container {
        width: 100%;
        height: 320px;
        .ring-echart {
          width: 100%;
          height: 320px;
        }
      }
      .wish-content-wrapper {
        display: flex;
        justify-content: space-around;
        margin: -100px auto 0;
        .wish {
          display: flex;
          .wish-img {
            display: flex;
            align-items: center;
            margin-right: 12px;
            img {
              width: 55px;
              height: 55px;
            }
          }
          .wish-text-wrapper {
            .wish-title {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 16px;
              color: #9797a1;
              margin: 3px 0 8px;
            }
            .wish-date-wrapper {
              display: flex;
              .wish-date {
                font-size: 25px;
                font-family: Bahnschrift;
                font-weight: 400;
                line-height: 25px;
                color: #302e2f;
              }
              .wish-text {
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 24px;
                color: #302e2f;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
  .rank-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .rank-card {
      // flex: 1;
      width: 33%;
      background: #fff;
      border-radius: 5px;
      padding: 16px 0 12px;
      height: 391px;
      // overflow-y: scroll;
      & + .rank-card {
        margin-left: 16px;
      }
      .rank-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rank-top-title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #302e2f;
          line-height: 22px;
          margin-left: 16px;
        }
        .rank-top-select {
          margin: 0 20px 0 8px;
          position: relative;
          cursor: pointer;
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            width: 100px;
            border-radius: 6px;
            padding: 0 8px;
            border: 1px solid #e3e2e5;
            height: 30px !important;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-search {
            position: absolute;
            top: 0;
            right: 11px;
            bottom: 0;
            left: 8px;
          }
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-item,
          .select
            .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            padding-right: 20px !important;
            line-height: 30px !important;
            font-size: 14px !important;
          }
          .select
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input {
            height: 30px !important;
            line-height: 30px !important;
          }
        }
      }
      .rank-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #302e2f;
        line-height: 22px;
        margin-left: 20px;
      }
      .rank-content-wrapper {
        position: relative;
        overflow: scroll;
        height: calc(100% - 38px);
        .rank-content-row {
          display: flex;
          justify-content: space-between;
          padding: 16px 16px 15px;
          // & + .rank-content-row {
          //   border-top: 1px dashed #e3e2e5;
          // }
          .rank-content-row-left {
            display: flex;
            align-items: center;
            width: calc(100% - 96px);
            .rank-sort {
              display: flex;
              align-items: center;
              min-width: 21px;
              justify-content: center;
              img {
                height: 22px;
                display: inline-block;
              }
              span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 25px;
                color: #f55186;
              }
            }
            .rank-img {
              min-width: 45px;
              height: 45px;
              margin: 0 10px;
              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
            }
            .rank-img-product {
              min-width: 42px;
              height: 42px;
              margin: 0 8px;
              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
            }
            .rank-title-wrapper {
              // max-width: 65%;
              width: inherit;
              .rank-name {
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 25px;
                color: #595961;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .rank-test-num-wrapper {
                margin-top: 5px;
                font-size: 11px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 11px;
                color: #9797a1;
                .rank-test-num {
                  color: #f55186;
                  margin-right: 10px;
                  margin-left: 8px;
                }
              }
            }
          }
          .rank-content-row-right {
            .rank-like-rate {
              font-size: 19px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 25px;
              color: #000000;
            }
            .rank-like-rate-text {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 11px;
              color: #9797a1;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
        .rank-content-line {
          border-top: 1px dashed #e3e2e5;
          margin: 0 20px;
        }
        .rank-content-row-highlight {
          background: rgba(245, 81, 134, 0.1);
        }
      }
      .rank-content-empty {
        position: relative;
        left: 50%;
        top: 38%;
        transform: translate(-50%, -50%);
      }
    }
    .rank-talent-card {
      .rank-content-wrapper {
        height: calc(100% - 16px);
        .rank-content-row {
          .rank-content-row-left {
            width: 55%;
            .rank-img {
              min-width: 38px;
              height: 38px;
              margin: 0 5px;
              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
              .ant-image-img {
                border-radius: 50%;
              }
            }
            .rank-title-wrapper {
              .rank-name-wrapper {
                display: flex;
                .rank-gender {
                  display: flex;
                  align-items: center;
                  img {
                    height: 12px;
                    margin-left: 5px;
                  }
                }
              }
              .rank-info-wrapper {
                font-size: 11px;
                font-weight: 400;
                line-height: 11px;
                color: #9797a1;
                margin-top: 5px;
              }
            }
          }
          .rank-content-row-right {
            display: flex;
            width: 44%;
            // min-width: 115px;
            // justify-content: flex-end;
            justify-content: space-between;
            .rank-test-num-wrapper {
              .rank-like-rate {
                font-size: 19px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 25px;
                color: #fac255;
                text-align: center;
              }
              .rank-like-rate-text {
                font-size: 11px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 11px;
                color: #9797a1;
                margin-top: 2px;
              }
            }
            .rank-test-word-wrapper {
              margin-left: 5px;
              .rank-like-rate {
                font-size: 19px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 25px;
                color: #00af50;
                text-align: center;
              }
              .rank-like-rate-text {
                font-size: 11px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 11px;
                color: #9797a1;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }
  .like-row {
    margin-bottom: 16px;
    background: #fff;
    border-radius: 5px;
    padding: 16px 16px 16px;
  }
  .third-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .download-card {
      flex: 2;
      background: #fff;
      border-radius: 5px;
      height: 566px;
      .download-top-wrapper {
        padding: 16px 16px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .download-title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 800;
          line-height: 22px;
          color: #302e2f;
        }
        .download-title-right-wrapper {
          display: flex;
          .download-search-wrapper {
            .ant-input-affix-wrapper {
              border-radius: 6px;
              border: 1px solid #e3e2e5;
              height: 30px;
              &:hover {
                border-color: #ff5186;
              }
              &:focus {
                border-color: #ff5186;
                box-shadow: none;
              }
            }
            .ant-input-affix-wrapper-focused {
              border-color: #ff5186;
              box-shadow: none;
            }
          }
          .download-top-select {
            margin: 0 0 0 8px;
            position: relative;
            cursor: pointer;
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector {
              width: 100px;
              border-radius: 6px;
              padding: 0 8px;
              border: 1px solid #e3e2e5;
              height: 30px !important;
            }
            .ant-select-single
              .ant-select-selector
              .ant-select-selection-search {
              position: absolute;
              top: 0;
              right: 11px;
              bottom: 0;
              left: 8px;
            }
            .select
              .ant-select-single.ant-select-show-arrow
              .ant-select-selection-item,
            .select
              .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              padding-right: 20px !important;
              line-height: 30px !important;
              font-size: 14px !important;
            }
            .select
              .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector
              .ant-select-selection-search-input {
              height: 30px !important;
              line-height: 30px !important;
            }
          }
          .download-pagination-footer {
            padding: 4px 0 0;
            // ant-pagination
            .ant-pagination-item:hover {
              border-color: #f57a9f !important;
              background: #f57a9f !important;
            }
            .ant-pagination-jump-prev
              .ant-pagination-item-container
              .ant-pagination-item-link-icon,
            .ant-pagination-jump-next
              .ant-pagination-item-container
              .ant-pagination-item-link-icon {
              color: #ff5186;
            }
            .ant-pagination-prev:hover {
              color: #f57a9f !important;
            }
            .ant-pagination-next:hover {
              color: #f57a9f !important;
            }
            .ant-pagination-next:hover .ant-pagination-item-link {
              color: #ff5186;
            }
            .ant-pagination-prev:hover .ant-pagination-item-link {
              color: #ff5186;
            }
            .ant-pagination-disabled .ant-pagination-item-link,
            .ant-pagination-disabled:hover .ant-pagination-item-link {
              color: rgba(0, 0, 0, 0.25);
              cursor: not-allowed;
            }
          }
        }
      }

      .download-content-wrapper {
        padding: 0 16px;
        position: relative;
        overflow: scroll;
        height: calc(100% - 64px);
        .download-content-row {
          display: flex;
          justify-content: space-between;
          padding: 14px 0;
          & + .download-content-row {
            border-top: 1px dashed #e2e5fb;
          }
          .download-content-row-left {
            cursor: pointer;
            .download-name-wrapper {
              display: flex;
              color: #595961;
              font-family: PingFang SC;
              .download-name-dot {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 20px;
              }
              .download-name {
                margin-left: 10px;
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
              }
              .download-recommend {
                margin: 0 40px 0 10px;
                font-size: 8px;
                line-height: 14px;
                color: #ff5186;
                border: 1px solid #ff5186;
                padding: 3px 4px 0;
                font-weight: 400;
                min-width: fit-content;
                max-height: 20px;
              }
            }
            .download-brief {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 16px;
              color: #9797a1;
              margin: 6px 30px 0 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
          .download-content-row-right {
            min-width: 94px;
            .download-date {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 20px;
              color: #9797a1;
            }
            .download-btn {
              cursor: pointer;
              font-size: 11px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 15px;
              color: #f55186;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .third-row-right {
      flex: 1;
      margin-left: 16px;
    }
    .qrcode-card {
      background: #fff;
      border-radius: 5px;
      padding: 16px;
      height: 234px;
      overflow-y: scroll;
      min-width: 32.2%;
      .qrcode-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 22px;
        color: #302e2f;
      }
      .qrcode-content-wrapper {
        display: flex;
        width: fit-content;
        margin: 16px auto 0;
        .qrcode-wrapper {
          & + .qrcode-wrapper {
            margin-left: 20px;
          }
          .qrcode-pic {
            width: 125px;
            height: 125px;
            border: 1px solid #e2e5fb;
            border-radius: 0px;
            img {
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
          .qrcode-text {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #707070;
            margin-top: 16px;
            text-align: center;
          }
        }
      }
    }
    .update-card {
      margin-top: 16px;
      background: #fff;
      border-radius: 5px;
      padding: 5px 16px;
      .update-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .update-title-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 800;
          line-height: 22px;
          color: #302e2f;
        }
      }
      .update-content-wrapper {
        height: 111px;
        padding: 5px 0;
        .update-content-row {
          display: flex;
          justify-content: space-between;
          padding: 7px 0;
          position: relative;
          & + .update-content-row {
            border-top: 1px dashed #e2e5fb;
          }
          .update-content-row-left {
            cursor: pointer;
            .update-name-wrapper {
              display: flex;
              color: #595961;
              font-family: PingFang SC;
              .update-name {
                font-size: 13px;
                font-weight: bold;
                line-height: 20px;
              }
            }
            .update-brief {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 16px;
              color: #9797a1;
              margin: 0 30px 0 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
          .update-content-row-right {
            min-width: 94px;
            .update-date {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 20px;
              color: #9797a1;
            }
          }
          &:hover .update-dialog {
            display: block;
          }
          .update-dialog {
            display: none;
            position: absolute;
            z-index: 9999;
            width: 300px;
            height: 300px;
            background-color: #fff;
            left: 0;
            top: -320px;
            border: 1px solid #ff5186;
            border-radius: 7px;
            padding: 10px;
            box-sizing: content-box;
            overflow: hidden;
            .ifr {
              width: 900px;
              height: 900px;
              border: 0;
              transform: scale(0.33333333);
              transform-origin: 0 0;
            }
            .mosk {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              top: 0;
              z-index: 99;
            }
          }
        }
      }
      .download-pagination-footer {
        padding: 4px 0 0;
        // ant-pagination
        .ant-pagination-item:hover {
          border-color: #f57a9f !important;
          background: #f57a9f !important;
        }
        .ant-pagination-jump-prev
          .ant-pagination-item-container
          .ant-pagination-item-link-icon,
        .ant-pagination-jump-next
          .ant-pagination-item-container
          .ant-pagination-item-link-icon {
          color: #ff5186;
        }
        .ant-pagination-prev:hover {
          color: #f57a9f !important;
        }
        .ant-pagination-next:hover {
          color: #f57a9f !important;
        }
        .ant-pagination-next:hover .ant-pagination-item-link {
          color: #ff5186;
        }
        .ant-pagination-prev:hover .ant-pagination-item-link {
          color: #ff5186;
        }
        .ant-pagination-disabled .ant-pagination-item-link,
        .ant-pagination-disabled:hover .ant-pagination-item-link {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
      }
    }
    .problem-card {
      margin-top: 16px;
      background: #fff;
      border-radius: 5px;
      padding: 16px;
      height: 150px;
      overflow-y: scroll;
      .problem-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 22px;
        color: #302e2f;
      }
      .problem-content-wrapper {
        .problem-content-row {
          margin-top: 19px;
          .problem-name-wrapper {
            display: flex;
            color: #595961;
            font-family: PingFang SC;
            display: flex;
            .problem-name-icon {
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .problem-name {
              margin-left: 10px;
              font-size: 16px;
              font-weight: bold;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
