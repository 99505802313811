@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-template-card {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 20px 0;
}
.question-template-card-item-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  color: #707070;
  cursor: pointer;
  margin-bottom: 8px;
  // position: relative;
  &:hover {
    background: rgba(254, 249, 250, 1);
    .question-template-card-item-name {
      padding-left: 4px;
      color: #f55186;
    }
    .question-template-card-item-icon {
      display: inline-block;
    }
  }
  .question-template-card-item-name {
    padding-left: 22px;
    padding-right: 20px;
    width: 100%;
    height: 28px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .question-template-card-item-icon {
    display: none;
    margin-left: 4px;
    img {
      width: 14px;
      height: 10px;
      vertical-align: baseline;
    }
    .question-template-tooltip {
      border: 1px solid #e35d86;
      padding: 24px 24px 24px 30px;
      box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      // transition: all 1s ease-out;
      // width: fit-content;
      // height: fit-content;
      max-height: 500px;
      // max-width: 400px;
      position: absolute;
      right: 356px;
      background: #fff;
      overflow-x: hidden;
      max-width: 550px;
      // width: 100%;
      width: max-content;
      overflow-y: hidden;
      z-index: 10;
      .question-template-name-wrapper {
        display: flex;
        width: max-content;
        align-items: baseline;
        .question-template-name {
          font-family: PingFang SC;
          font-weight: 700;
          color: #322d2c;
          font-size: 16px;
          line-height: 20px;
          max-width: 400px;
          & + .question-template-name {
            margin: 0 16px 0 0;
          }
        }
        .question-template-pic {
          display: flex;
          img {
            width: 24px;
            height: 21px;
            display: inline-block;
          }
        }
      }
    }
    .question-template-tooltip-arrow {
      width: 10px;
      height: 10px;
      border-top: 1px solid #e35d86;
      border-right: 1px solid #e35d86;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background: #fff;
      // width: 0;
      // height: 0;
      // border-left: 10px solid skyblue;
      // border-top: 8px solid transparent;
      // border-bottom: 8px solid transparent;
      position: absolute;
      // right: 346px;
      right: 351px;
      z-index: 20;
    }
  }
}
