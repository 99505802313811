@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.xm-modal {
  .ant-modal {
    width: 400px !important;
    top: 30%;
  }
  .ant-modal-content {
    border-radius: 16px;
    box-shadow: 0 2px 8px 0 rgba(224, 224, 224, 0.5);
    .ant-modal-close {
      .ant-modal-close-x {
        display: block;
        width: 62px;
        height: 62px;
        font-size: 20px;
        font-style: normal;
        line-height: 62px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
      }
    }
  }
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
    padding: 20px 24px 0;
    border-bottom: none;
    .ant-modal-title {
      color: #322d2c;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .ant-modal-body {
    padding: 18px 24px 36px;
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
      line-height: 20px;
      text-shadow: 0 2px 8px rgba(224, 224, 224, 0.5);
    }
  }
  .ant-modal-footer {
    border-radius: 0 0 16px 16px;
    border-top: none;
    padding: 0 24px 24px;
    .ant-btn {
      padding: 9px 24px;
      height: 38px;
      border-radius: 10px;
      line-height: 14px;
      span {
        font-size: 14px;
      }
    }
    .ant-btn-default {
      color: #595961;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
      border-color: #ff5186;
      background: #ff5186;
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      text-decoration: none;
      background: #fff;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      color: #ff5186;
      border-color: #ff5186;
      background: #fff;
    }
    .ant-btn-primary {
      color: #fff;
      border-color: #ff5186;
      background: #ff5186;
      // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
      box-shadow: 0 2px 8px 0 rgba(224, 224, 224, 0.5);
    }
    .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 16px;
    }
  }
}
