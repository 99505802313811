@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.evaluation-icon-search {
  width: 13px;
  height: 13px;
}
.evaluation-title-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  .evaluation-right {
    display: flex;
    align-items: center;
    .evaluation-icon-mod,
    .evaluation-icon-list {
      margin: 0 20px 0 0;
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      img.isHide {
        display: none;
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #e3e2e5;
      &:hover {
        border-color: #ff5186;
      }
      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }
  }
}

// 评价等级
.evaluation-prev-card {
  background: rgba(250, 194, 85, 0.05);
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0 16px;
  .evaluation-prev-level {
    align-items: center;
  }
  .evaluation-prev-item {
    display: flex;
    align-items: baseline;
    .item-name {
      min-width: 84px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595961;
      line-height: 14px;
    }
    .item-content {
      display: flex;
      margin: 0 0 0 36px;
      flex-wrap: wrap;
      .ant-checkbox-wrapper {
        align-items: baseline;
        margin: 0 80px 0 0;
        min-width: 140px;
      }
      .ant-checkbox + span {
        padding-right: 8px;
        padding-left: 8px;
        // margin-top: -4px;
      }
      .content-label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        .icon-image {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin: 0 0 2px 10px;
        }
      }
      .content-label-active {
        color: #322d2c;
      }
      .content-label-inactive {
        color: #595961;
      }
      .content-label-percent {
        margin-left: 8px;
      }

      .ant-checkbox-inner {
        background-color: transparent;
        border: 1px solid #e3e2e5;
        width: 14px;
        height: 14px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ff5186;
        border-color: #ff5186;
      }
      .ant-checkbox-checked::after {
        border: 1px solid #ff5186;
      }
    }
  }
  .evaluation-prev-word {
    .item-content {
      .ant-checkbox-wrapper {
        margin: 0 30px 20px 0;
      }
    }
  }
}
.filter-container-fixed {
  background: #f5f7fc;
  min-width: 1072px;
  height: 98px;
  z-index: 90;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}
//ant-table
.evaluation-table-wrap {
  position: relative;
  margin-top: 30px;
  .evaluation-table-sum {
    position: absolute;
    bottom: 0;
    span {
      color: #ff5186;
    }
  }
  .incentive-btn {
    width: 52px;
    height: 23px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 23px;
    color: #f55186;
    background: rgba(252, 139, 176, 0.15);
    border-radius: 12px;
    padding: 3px 11px;
    cursor: pointer;
    &:hover {
      background: rgba(245, 81, 134, 1);
      color: #ffffff;
    }
  }
  .check-btn {
    cursor: pointer;
  }
  .check-btn-disabled {
    cursor: not-allowed;
  }
  .ant-table {
    font-family: PingFang SC;
    color: #322d2c;
    line-height: 20px;
    font-size: 14px;
  }
  .ant-table-thead > tr > th {
    padding: 12px 12px;
    color: #9797a1;
    background: #fff;
    font-size: 12px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: #fff;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  .ant-table-tbody > tr > td {
    position: relative;
    padding: 12px 12px;
    overflow-wrap: break-word;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background: #fff;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background: #f8f8f8;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba(0, 0, 0, 0.04);
    &:last-child {
      span {
        cursor: pointer;
      }
    }
  }
}
.evaluation-box-wrap {
  margin-top: 24px;
}
.isHide {
  display: none;
}
//box
.pagination-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination-sum {
    span {
      color: #ff5186;
    }
  }
}
// ant-pagination
.ant-table-pagination.ant-pagination {
  margin: 30px 0 0;
}
.ant-pagination-item-active {
  border-color: #ff5186;
  background: #ff5186;
  a {
    color: #fff;
  }
}
.ant-pagination-item:hover {
  border-color: #ff5186;
  background: #ff5186;
}
.ant-pagination-item:hover a {
  color: #fff;
}

//ant-table-container
// .ant-table-thead
//   > tr
//   > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
//   position: absolute;
//   top: 50%;
//   right: 0;
//   width: 1px;
//   height: 1.6em;
//   background-color: none;
//   transform: translateY(-50%);
//   transition: background-color 0.3s;
//   content: '';
// }

// .ant-table-thead > tr > th {
//   background: #fff !important;
//   font-weight: 700 !important;
//   border-bottom: 1px solid #e3e2e5 !important;
//   color: #302e2f !important;
//   padding: 12px 16px !important;
// }
//操作按钮
.evaluation-operaion {
  display: flex;
  align-items: center;
  margin: 20px 0 8px;
  justify-content: space-between;
  .evaluation-operaion-left {
    display: flex;
    .evaluation-operaion-left-1 {
      display: flex;
      align-items: center;
      .evaluation-operaion-text {
        font-size: 13px;
        font-family: PingFang SC;
        // font-weight: bold;
        line-height: 22px;
        color: #302e2f;
        margin-right: 8px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 130px;
        border-radius: 5px;
        padding: 0 16px;
        border: 1px solid #d9d9d9;
        height: 34px;
      }
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item,
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 20px;
        line-height: 34px;
        font-size: 14px;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 34px;
        line-height: 34px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        left: 16px;
      }
    }
    .evaluation-operaion-left-2 {
      display: flex;
      margin: 0 0 0 16px;
      .evaluation-operaion-button {
        background: #ff5186;
        border-radius: 6px;
        border: 1px solid #ff5186;
        width: fit-content;
        height: 34px;
        font-weight: 400;
        font-family: PingFang SC;
        color: #fff;
        font-size: 13px;
        margin: 0 16px 0 0;
        padding: 0 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #ff729c;
          border: 1px solid #ff729c;
        }
        &:active {
          background: #ef356d;
          border: 1px solid #ef356d;
        }
        img {
          height: 13px;
          display: inline-block;
          margin: 0 9px 0 0;
        }
      }
    }
  }
  .evaluation-operaion-right {
    display: flex;
    align-items: center;
    .translation-select {
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 130px;
        border-radius: 5px;
        padding: 0 16px;
        border: 1px solid #d9d9d9;
        height: 34px;
      }
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item,
      .select
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-placeholder {
        padding-right: 20px;
        line-height: 34px;
        font-size: 14px;
      }
      .select
        .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 34px;
        line-height: 34px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-search {
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        left: 16px;
      }
    }
  }
}
.table-operation {
  .translation-select {
    margin-top: 6px;
  }
  .translation-tip {
    margin-top: 6px;
  }
  .translation-select {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 130px;
      border-radius: 5px;
      padding: 0 16px;
      border: 1px solid #d9d9d9;
      height: 34px;
    }
    .select .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .select
      .ant-select-single.ant-select-show-arrow
      .ant-select-selection-placeholder {
      padding-right: 20px;
      line-height: 34px;
      font-size: 14px;
    }
    .select
      .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 34px;
      line-height: 34px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-search {
      position: absolute;
      top: 0;
      right: 11px;
      bottom: 0;
      left: 16px;
    }
  }
}
