@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.heatmap-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .heatmap-popup-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heatmap-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 720px;
    height: 80%;
    .heatmap-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .heatmap-popup-content {
    height: calc(100% - 160px);
    padding: 30px 30px 0;
    overflow: auto;
    display: flex;
    .heatmap-left-wrapper {
      width: calc(100% - 200px);
      margin: 0 20px 0 0;
      overflow: auto;
      .heatmap-left-title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 17px;
        color: #8c8c8c;
        margin: 0 0 8px 0;
      }
      .heatmap-image-container {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          display: block;
        }
        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .heatmap-right-wrapper {
      width: 200px;
      .heatmap-right-title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 17px;
        color: #302e2f;
        margin: 0 0 8px 0;
      }
      .heatmap-right-card {
        background: #fafafa;
        overflow-y: auto;
        padding: 16px 8px;
        height: 90%;
        .card-wrapper {
          display: flex;
          .card-input-wrapper {
            .card-input {
              border: 1px solid #e3e2e5;
              padding: 2px 10px;
              font-size: 14px;
              width: 128px;
              height: 28px;
              background: #ffffff;
              border-radius: 2px;
              margin: 0 6px 16px 8px;
              text-indent: 8px;
              &:focus {
                border: 1px solid #ff5186;
              }
              &:hover {
                border: 1px solid #ff5186;
              }
            }
          }
          .card-delete-icon {
            cursor: pointer;
            width: 12px;
            height: 12px;
            margin: 0 0 0 10px;
            img {
              width: 100%;
              height: 100%;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .tag-btn {
    margin: 20px 0 20px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
