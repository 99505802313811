@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.not-allowed {
  cursor: not-allowed !important;
}
.site-tree-search-value {
  color: #ff5186;
}
.add_tags {
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .tag-btn {
    margin: 20px 0 30px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
  .ant-tree .ant-tree-treenode {
    display: flex;
    align-items: center;
    padding: 0 0 15px 0;
    outline: none;
  }
  .ant-tree-checkbox {
    top: initial;
    margin: 0 15px 0;
  }
  .ant-tree-node-content-wrapper {
    font-size: 16px;
    font-family: PingFang SC;
    color: #595961;
  }
  .ant-tree-switcher .ant-tree-switcher-icon {
    display: inline-block;
    font-size: 10px;
    vertical-align: baseline;
    color: #9797a1;
  }
}
.add_tags_popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
}
.add_tags_main {
  width: 580px;
  background: #fff;
  border-radius: 7px;
  // height: 80vh;
  position: relative;
  .search-wrapper {
    margin: 30px 30px 0;
    .evaluation-icon-search {
      width: 13px;
      height: 13px;
    }
    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #e3e2e5;
      &:hover {
        border-color: #ff5186;
      }
      &:focus {
        border-color: #ff5186;
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      border-color: #ff5186;
      box-shadow: none;
    }
  }
}
.add_tags_top {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e3e2e5;
  .popup-top-title {
    display: flex;
    margin: 0 auto;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 28px;
  }
  .popup-delete-icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
.add_tags_top span {
  font-size: 16px;
  font-weight: bold;
}
.add_tags_top img {
  width: 10px;
  height: 10px;
}
.tags_item1 p {
  font-size: 20px;
  font-weight: bold;
}
.tags_item2 {
  height: 300px;
  overflow: auto;
  margin: 24px 50px 0;
}
.tags_item2 p {
  font-size: 20px;
  font-weight: bold;
}
.add_tags_items:after {
  display: block;
  content: '';
  height: 0;
  clear: both;
  visibility: hidden;
}
.add_tags_items .item {
  display: flex;
  align-items: center;
  cursor: pointer;
  float: left;
  width: 33%;
  margin-bottom: 10px;
}
.add_tags_items .item img {
  width: 20px;
  height: 20px;
}
.add_tags_items .item span {
  font-size: 14px;
  margin-left: 14px;
}

.tags_item2 .ant-tree-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.tags_item2 .ant-tree-checkbox-inner:hover {
  border-color: #ff5184;
}
.tags_item2 .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #ff5186;
  border-color: #ff5186;
}
.tags_item2 .ant-tree-checkbox-checked::after {
  border-color: #ff5184;
}
.tags_item2 .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  width: 8px;
  height: 13px;
  top: 44%;
  left: 18%;
}
.tags_item2 .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  border-color: #ff5186;
}
.tags_item2 .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #ff5186;
  width: 10px;
  height: 10px;
}
