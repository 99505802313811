@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.QuestionnaireSetting {
  height: calc(100% - 72px);
  .QuestionnaireSetting_container {
    height: calc(100% - 60px);
  }
  .QuestionnaireSetting_main {
    padding: 0 0 0 24px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
    // justify-content: space-between;
    .setting_left {
      // width: 100%;
      width: calc(100% - 322px);
      min-width: 620px;
      // height: 100%;
      overflow-y: scroll;
      margin: 31px 0 0;
      /*background: #fff;*/
      .setting_list {
        width: 695px;
        background: #fff;
        border-radius: 7px;
        padding: 20px 24px;
        box-sizing: border-box;
        margin-top: 15px;
        position: relative;
        .setting_edit_icon {
          position: absolute;
          top: 7px;
          right: -24px;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        .setting_list_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .setting-notice {
        color: #595961;
        font-size: 14px;
        margin: 40px 0;
      }
      .setting_btn {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 15px;
        div:nth-child(2) {
          background: #fff1f6;
          border-radius: 6px;
          border: 1px solid #ff5186;
          padding: 9px 17px;
          color: #ff5186;
          font-size: 14px;
          margin: 0 16px 0 0;
          cursor: pointer;
          &:hover {
            background: #fff8fb !important;
            border: 1px solid rgba(255, 81, 134, 0.6);
          }
          &:active {
            background: #ffe1ec !important;
            border: 1px solid #ff5186;
          }
        }
        div:nth-child(1) {
          background: #ff5186;
          border-radius: 6px;
          border: 1px solid #ff5186;
          padding: 9px 17px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            background: #ff729c !important;
            border: 1px solid #ff729c;
          }
          &:active {
            background: #ef356d !important;
            border: 1px solid #ef356d;
          }
        }
      }
    }
    .setting-right {
      height: 100%;
      padding-top: 31px;
      box-sizing: border-box;
      width: 386px;
      min-width: 386px;
      .setting-right-panel {
        height: 100%;
        position: relative; // 为了悬浮框
        .setting-right-panel-tab-wrapper {
          display: flex;
          width: 100%;
          height: 43px;
          border: 1px solid #EBEBEB;
          border-radius: 16px 16px 0 0;
          div:nth-child(1) {
            border-radius: 16px 0px 0px 0px;
          }
          div:nth-child(2) {
            border-left: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
          }
          div:nth-child(3) {
            border-radius: 0px 16px 0px 0px;
          }
          .setting-right-panel-tab {
            width: 50%;
            line-height: 43px;
            text-align: center;
            background: #f7f8fa;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #595961;
            cursor: pointer;
          }
          .setting-right-panel-tab-active {
            background: rgba(255, 239, 244, 1);
            color: #f55186;
            opacity: 1;
          }
          .setting-right-panel-tab-not-allowed {
            cursor: not-allowed;
          }
        }
        .setting-right-panel-content {
          height: calc(100% - 43px);
          background: #fff;
          box-shadow: 0px 2px 23px rgba(190, 190, 190, 0.16);
          border-radius: 5px;
          overflow: auto;
        }
      }
    }
  }
  .operaion-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 4px;
      border: 1px solid #ff5186;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 13px;
      margin: 0 23px 0 0;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 4px;
      border: 1px solid #e3e2e5;
      width: 94px;
      height: 34px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 13px;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
  }
}
.project_entry_noneMarginTop {
  padding-bottom: 0 !important;
}

.drag_contain {
  width: 695px;
  height: 28px;
  border: 1px dotted #555;
  margin-top: 15px;
}
.isVisibility {
  visibility: hidden;
  height: 43px;
  border: 1px dotted #555;
}
