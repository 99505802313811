@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.score-formula-contain {
  border-radius: 16px;
  background: #fff;
  overflow-y: auto;
  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 14px;
  }
  /* 顶部 */
  .score-formula-top {
    padding: 20px 24px;
    background: #fff;
    border-radius: 7px 7px 0 0;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    img {
      width: 8px;
      height: 15px;
      cursor: pointer;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      margin-left: 14px;
    }
  }
  /* 中部 */
  .score-formula-contain-main {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    background: #fff;
    padding: 20px 24px;
    .score-formula-item {
      display: flex;
      justify-content: left;
      padding-bottom: 24px;
      align-items: baseline;
      &:nth-child(2) {
        align-items: inherit;
      }
      .score-formula-item-left {
        width: 110px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #595961;
        span {
          color: #ff5186;
          margin-right: 5px;
          display: inline-block;
          width: 15px;
        }
      }

      .score-formula-item-right {
        position: relative;
        //   cursor: pointer;
        //input
        .score-formula-input {
          cursor: pointer;
          width: 378px;
          min-width: 130px;
          height: 38px !important;
          box-sizing: border-box;
          padding: 0 0 0 16px;
          border-radius: 6px;
          font-size: 14px !important;
          min-height: 32px;
        }
        //便利
        .variable-card {
          margin: 24px 0 0;
          padding: 16px;
          border: 1px solid #e3e2e5;
          border-radius: 8px;
          .variable-item {
            display: flex;
            align-items: center;
            & + .variable-item {
              margin: 10px 0 0;
            }
            .variable-item-icon {
              img {
                width: 16px;
                height: 16px;
              }
            }
            .variable-item-name {
              padding: 12px 14px;
              width: 334px;
              border: 1px solid #e3e2e5;
              border-radius: 6px;
              margin: 0 24px 0 16px;
              font-size: 14px;
            }
            .variable-item-sign {
              color: #ff5186;
              border: 1px solid #ff5186;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
            }
          }
        }
        //select
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #e3e2e5;
          border-radius: 6px;
          transition: none;
          width: 378px !important;
          height: 38px !important;
          min-height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          font-size: 14px;
        }
        .ant-select-multiple .ant-select-selection-item-content {
          font-size: 14px;
        }
      }
      .score-formula-edit {
        margin: 0 0 0 20px;
        color: #ff5186;
        cursor: pointer;
      }
    }
  }
  /* 底部 */
  .score-formula-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 24px;
    box-sizing: border-box;
    width: calc(100% - 312px);
    .score-formula-bottom-content {
      padding: 13px 24px;
      box-sizing: border-box;
      background: #ffff;
      box-shadow: 0px -4px 10px 0px rgba(230, 230, 230, 0.5);
      border-radius: 16px 16px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div:nth-child(1) {
        color: #595961;
        font-size: 18px;
        span {
          color: #ff5186;
        }
      }
      div:nth-child(2) div {
        display: inline-block;
        margin-left: 16px;
        background: #f8f8f8;
        border-radius: 7px;
        border: 1px solid #e3e2e5;
        width: 114px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        color: #9797a1;
        font-size: 14px;
        cursor: pointer;
        &:nth-child(1):hover {
          background: #fff1f6;
          border: 1px solid #ff5186;
          color: #ff5186;
        }
        &:nth-child(2):hover {
          background: #fff1f6;
          border: 1px solid #ff5186;
          color: #ff5186;
        }
        &:nth-child(3):hover {
          background: #ff5186;
          border: 1px solid #ff5186;
          color: #fff;
        }
      }
    }
  }
}
.product-entry-dropdown {
  .ant-select-item-option-content {
    flex: none !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85% !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #ff5186 !important;
    font-weight: 600 !important;
    // background-color: rgba(255, 81, 134, 0.04) !important;
    // background: rgba(255, 81, 134, 0.04) url('../../images/cur_icon_choice.png')
    //   no-repeat 92% center !important;
    // background-size: 12px 8px !important;
    background-size: 12px 8px !important;
  }
}
