@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.filter-select-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  @mixin filter-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #302e2f;
  }
  .region-wrapper {
    display: flex;
    align-items: center;
    .region-name {
      @include filter-title;
      margin-right: 5px;
    }
    .region-select {
      //   width: 200px;
      //select
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #e3e2e5;
        border-radius: 6px;
        transition: none;
        min-width: 200px !important;
        height: 38px !important;
        min-height: 32px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 14px;
      }
      .ant-select-multiple .ant-select-selection-item-content {
        font-size: 14px;
      }
    }
  }
  .gender-wrapper {
    display: flex;
    align-items: center;
    .gender-name {
      @include filter-title;
      margin-right: 5px;
    }
    .gender-select {
      //   width: 200px;
      //select
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #e3e2e5;
        border-radius: 6px;
        transition: none;
        min-width: 130px !important;
        height: 38px !important;
        min-height: 32px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 14px;
      }
      .ant-select-multiple .ant-select-selection-item-content {
        font-size: 14px;
      }
    }
  }
  .age-wrapper {
    display: flex;
    align-items: center;
    .age-name {
      @include filter-title;
      margin-right: 5px;
    }
    .age-select {
      //   width: 200px;
      //select
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #e3e2e5;
        border-radius: 6px;
        transition: none;
        min-width: 250px !important;
        height: 38px !important;
        min-height: 32px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 14px;
      }
      .ant-select-multiple .ant-select-selection-item-content {
        font-size: 14px;
      }
    }
  }
  .date-wrapper {
    display: flex;
    align-items: center;
    .date-name {
      min-width: 40px;
      @include filter-title;
      margin-right: 5px;
    }
    .date-select {
      .range-picker {
        border: 1px solid #e3e2e5;
        border-radius: 5px;
        width: 250px;
        height: 38px;
      }
    }
  }
  .operate-wrapper {
    display: flex;
    .search-wrapper {
      min-width: 40px;
      font-size: 16px;
      padding: 11px 5px;
      height: 44px;
      line-height: 22px;
      text-align: center;
      background: #ff5186;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 400;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .clear-wrapper {
      min-width: 40px;
      font-size: 16px;
      padding: 11px 5px;
      height: 44px;
      line-height: 22px;
      text-align: center;
      background: #ff5186;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 400;
      cursor: pointer;
      margin-left: 8px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #ff5186;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
@media screen and (max-width: 1440px) {
  .filter-select-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    @mixin filter-title {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #302e2f;
    }
    .region-wrapper {
      display: flex;
      align-items: center;
      .region-name {
        @include filter-title;
        margin-right: 5px;
      }
      .region-select {
        //   width: 200px;
        //select
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #e3e2e5;
          border-radius: 6px;
          transition: none;
          min-width: 246px !important;
          height: 38px !important;
          min-height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          font-size: 14px;
        }
        .ant-select-multiple .ant-select-selection-item-content {
          font-size: 14px;
        }
      }
    }
    .gender-wrapper {
      display: flex;
      align-items: center;
      .gender-name {
        @include filter-title;
        margin-right: 5px;
      }
      .gender-select {
        //   width: 200px;
        //select
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #e3e2e5;
          border-radius: 6px;
          transition: none;
          min-width: 100px !important;
          height: 38px !important;
          min-height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          font-size: 14px;
        }
        .ant-select-multiple .ant-select-selection-item-content {
          font-size: 14px;
        }
      }
    }
    .age-wrapper {
      display: flex;
      align-items: center;
      .age-name {
        @include filter-title;
        margin-right: 5px;
      }
      .age-select {
        //   width: 200px;
        //select
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #e3e2e5;
          border-radius: 6px;
          transition: none;
          min-width: 195px !important;
          height: 38px !important;
          min-height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          font-size: 14px;
        }
        .ant-select-multiple .ant-select-selection-item-content {
          font-size: 14px;
        }
      }
    }
    .date-wrapper {
      display: flex;
      align-items: center;
      .date-name {
        min-width: 40px;
        @include filter-title;
        margin-right: 5px;
      }
      .date-select {
        .range-picker {
          border: 1px solid #e3e2e5;
          border-radius: 5px;
          width: 250px;
          height: 38px;
        }
      }
    }
    .operate-wrapper {
      display: flex;
      .search-wrapper {
        min-width: 40px;
        font-size: 16px;
        padding: 11px 5px;
        height: 44px;
        line-height: 22px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .clear-wrapper {
        min-width: 40px;
        font-size: 16px;
        padding: 11px 5px;
        height: 44px;
        line-height: 22px;
        text-align: center;
        background: #ff5186;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 6px;
        font-family: PingFang SC;
        font-weight: 400;
        cursor: pointer;
        margin-left: 8px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
