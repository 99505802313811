@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.progress-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 99;
    div {
      width: 46px;
      height: 46px;
      margin-bottom: 27px;
      border-radius: 999px;
      background-color: #FAC255;
      cursor: pointer;
      img {
        width: 70%;
        height: 70%;
        margin: 15%;
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;
    }
    &.h{
      div {
        background-color: #00AF50;
      }
      p {
        color: #00AF50;
      }
    }
    &.n{
      div {
        background-color: #715bec;
      }
      p {
        color: #715bec;
      }
    }
  }
  &.line::after {
    content: '';
    width: calc(100% - 40px);
    height: 0;
    border: 1px dashed #ccc;
    position: absolute;
    left: 20px;
    top: 22px;
  }
  .bar-item-type1 {
    width: 100%;
    cursor: pointer;
    position: relative;
    .bar-content {
      width: calc(100% - 36px);
      height: 36px;
      background-color: #FAC255;
      text-align: center;
      line-height: 36px;
      color: #fff;
      font-size: 16px;
      margin: 0 auto;
    }
    &:first-child::before {
      content: '';
      width: 18px;
      height: 36px;
      background-color: #FAC255;
      border-radius: 18px 0 0 18px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 36px;
      border-left: #FAC255 solid 18px;
      border-top: #fff solid 18px;
      border-bottom: #fff solid 18px;
    }
    &:last-child::after {
      content: '';
      width: 18px;
      height: 36px;
      background-color: #FAC255;
      border-radius: 0 18px 18px 0;
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
    }
    &:nth-child(n+2)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 36px;
      border-left: #fff solid 18px;
      border-top: #FAC255 solid 18px;
      border-bottom: #FAC255 solid 18px;
    }
    &.h {
      .bar-content {
        background-color: #72C789;
      }
    }
    &.h:first-child::before {
      background-color: #72C789;
    }
    &.h::after {
      border-left: #72C789 solid 18px;
    }
    &.h:last-child::after {
      background-color: #72C789;
    }
    &.h:nth-child(n+2)::before {
      border-top: #72C789 solid 18px;
      border-bottom: #72C789 solid 18px;
    }
    &.n {
      .bar-content {
        background-color: #715bec;
      }
    }
    &.n:first-child::before {
      background-color: #715bec;
    }
    &.n::after {
      border-left: #715bec solid 18px;
    }
    &.n:last-child::after {
      background-color: #715bec;
    }
    &.n:nth-child(n+2)::before {
      border-top: #715bec solid 18px;
      border-bottom: #715bec solid 18px;
    }
  }
}
.mb10{
  margin-bottom: 10px;
}