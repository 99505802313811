@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.product-freecross-container {
  // width: 100%;
  // margin-top: 55px;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;
  .product-freecross-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    // height: 100%;
    min-width: 1200px;
    background: #fff;
    padding: 38px 38px 38px 23px;
    margin: 11px 0 0;
    min-height: calc(100% - 55px);
  }
}
