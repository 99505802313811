@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.freecross-container {
  // width: 100%;
  // margin-top: 55px;
  // padding: 16px;
  // background: #f5f7fc;
  // box-sizing: border-box;
  // overflow-y: scroll;
  overflow-x: auto;

  ::placeholder {
    // 放这里有效
    color: #9797a1;
    font-size: 13px;
  }
  input {
    border-radius: 4px;
  }
  .freecross-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    // height: 100%;
    min-width: 1200px;
    background: #fff;
    padding: 38px 38px 38px 23px;
    min-height: 100%;
  }
}
